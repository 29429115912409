import {
  getAllOrders as allOrdersApi,
  getOneOrder as getOneOrderApi,
  getPage,
  getOrderStats as orderStatsApi,
  getApprovedOrders as approvedOrdersApi,
  getPendingOrders as pendingOrdersApi,
  getSellerPendingOrders,
  getCanceledOrders as canceledOrdersApi,
  getNewOrders as newOrdersApi,
  markOrderSent as orderSentApi,
  declineOrder as declineOrderApi,
  getFlaggedOrders as flaggedOrdersApi,
} from "../../api/ordersApi";

import {
  GET_APPROVED_ORDERS,
  GET_CANCELED_ORDERS,
  GET_ALL_ORDERS,
  GET_ONE_ORDER,
  GET_ORDER_FAILED,
  GET_SELLER_PENDING_ORDERS,
  GET_ORDER_STATS,
} from "./type";

export const fetchOrderStats = (token) => {
  return async (dispatch) => {
    try {
      const res = await orderStatsApi(token);
      dispatch({
        type: GET_ORDER_STATS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const fetchAllOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await allOrdersApi(token);
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchSellerPendingOrders = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await getSellerPendingOrders(token, sellerId);
      if (res.status === "ok") {
        dispatch({
          type: GET_SELLER_PENDING_ORDERS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchOrder = (token, id) => {
  return async (dispatch) => {
    try {
      const res = await getOneOrderApi(token, id);
      if (res.status === "ok") {
        dispatch({
          type: GET_ONE_ORDER,
          payload: res.data,
          error: false,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchApprovedOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await approvedOrdersApi(token);
      dispatch({
        type: GET_APPROVED_ORDERS,
        payload: res.data,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchPendingOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await pendingOrdersApi(token);
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchNewOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await newOrdersApi(token);
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchFlaggedOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await flaggedOrdersApi(token);
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchNextOrders = (token, url) => {
  return async (dispatch) => {
    try {
      const res = await getPage(token, url);
      if (res.status === "ok") {
        dispatch({
          type: GET_ALL_ORDERS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchCanceledOrders = (token) => {
  return async (dispatch) => {
    try {
      const res = await canceledOrdersApi(token);
      dispatch({
        type: GET_CANCELED_ORDERS,
        payload: res.data,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDER_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const markOrderSent = (token, saleNo) => {
  return async (dispatch) => {
    try {
      const res = await orderSentApi(token, saleNo);
      if (res.status === "ok") {
        dispatch(fetchAllOrders(token));
      }
      return res;
    } catch (err) {
      return err.message;
    }
  };
};
export const declineOrder = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await declineOrderApi(token, values);
      if (res.status === "ok") {
        dispatch(fetchAllOrders(token));
      }
      return res;
    } catch (err) {
      return err.message;
    }
  };
};
