/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
import { useLocation } from "react-router-dom";
import {
  AutoComplete,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Radio,
  message,
  Tooltip,
  Checkbox,
  Popconfirm,
  Table,
  DatePicker,
  Space,
} from "antd";
import {
  InfoCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import sizes from "./assets/sizes";
import colors from "./assets/colors";
// import { useNavigate } from "react-router-dom";
// import { RouterPrompt } from "./routerPrompt";
import PreviewLisitingModal from "./PreviewLisitingModal";
import occasion from "./assets/occassions";
import { kidsOccasion } from "../../assets/occassions";
import weights from  "../../assets/weights"

const { Option } = Select;

const ListAnItem = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();

  const { brand, sellerId } = location.state;

  if (brand) form.setFieldsValue({ brandName: brand });

  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const imgRef4 = useRef(null);

  const [brandInfo, setBrandInfo] = useState({});
  const [submit, setSubmit] = useState(false);
  const [result, setResult] = useState([]);
  const [type, setType] = useState("");
  const [isDiscount, setDiscount] = useState(false);
  const [variant, setVariant] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [categories, setCategories] = useState({});
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [kidsAgeRange, setKidsAgeRange] = useState([]);
  const [kidsCategory, setKidsCategory] = useState([]);
  const [kidsSubCategory, setKidsSubCategory] = useState([]);
  const [kidsOccasions, setKidsOccasions] = useState([]);
  const [itemCat, setItemCat] = useState("");
  const [itemGroup, setItemGroup] = useState([]);
  const [itemFit, setItemFit] = useState([]);
  const [itemGender, setItemGender] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);
  const [productPreview, setProductPreview] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  // const [selfDeliveryInfo, setSelfDeliveryInfo] = useState("");
  // const [showDialog, setShowDialog] = useState(true);

  const { info } = useSelector((state) => state.user);
  const { allBrands: brands } = useSelector((state) => state.brands);

  const { createProduct, fetchBrands } = bindActionCreators(
    brandActionCreators,
    dispatch
  );
  // const { fetchCurrency } = bindActionCreators(currencyActionCreators, dispatch)

  const pickCurrency = () => {
    let currency = "USD";
    if (brandInfo?.store_country === "Nigeria") currency = "NGN";
    if (brandInfo?.store_country === "USA") currency = "USD";
    if (brandInfo?.store_country === "UK") currency = "EUR";
    return currency;
  };

  const onChange = (name) => {
    const brand = brands?.filter((brand) => brand.store_name === name);
    setBrandInfo(...brand);
  };

  const handleSearch = (value) => {
    let res = [];
    if (!value) {
      res = [];
    } else {
      res = brands?.filter((brand) =>
        brand.store_name.toLowerCase().includes(value)
      );
    }
    setResult(res);
  };

  const addColor = (color) => {
    const currentColorList = selectedColors;
    if (selectedColors.length < 3) {
      const thisColorExists = currentColorList.find((e) => e.hex === color.hex);
      if (!thisColorExists) setSelectedColors(selectedColors.concat(color));
    }
  };

  const subtractColor = (color) => {
    const currentColorList = selectedColors;
    const newColorList = currentColorList.filter((e) => e.hex !== color.hex);

    setSelectedColors(newColorList);
  };

  const changeSize = (size) => {
    const sizeList = selectedSizes;

    if (size === "One Size") {
      setSelectedSizes(["One Size"]);
      return;
    }
    const hasSizeAlready = sizeList.includes(size);
    if (!hasSizeAlready) {
      const newSizeList = sizeList.filter((e) => e !== "One Size");
      setSelectedSizes(newSizeList.concat(size));
    }
    if (hasSizeAlready) setSelectedSizes(sizeList.filter((e) => e !== size));
  };

  const generateTable = () => {
    const data = [];
    if (selectedColors.length > 0 && selectedSizes.length > 0) {
      selectedColors.map((color) => {
        selectedSizes.map((size) => {
          data.push({
            key: data.length,
            itemName: form.getFieldValue("name"),
            colorObj: color,
            color: color.name,
            size,
          });
          return true;
        });
        return true;
      });
    }
    setVariant(data);
  };

  const confirm = (id) => {
    const data = variant.filter((item) => item.key !== id);
    setVariant(data);
  };

  const addQuantity = (item, val) => {
    item.quantity = val;
  };

  const uploadImg = async ({ file, onSuccess, onError }, ref) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "product-images");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    if (result.status === 200) {
      const url = result.data.secure_url;
      setImgUrl([...imgUrl, url]);
      file.status = "success";
      ref.current.style.background = `url(${result.data.secure_url})`;
      ref.current.style.backgroundSize = "cover";
      ref.current.firstChild.style.display = "none";
      ref.current.lastChild.style.display = "none";
      onSuccess("ok");
    } else onError("error");
  };

  const onFinish = async (fieldsValue) => {
    setSubmit(true);
    const data = {
      ...form.getFieldsValue(),
      discount_end_date: fieldsValue["discount_end_date"]?.format("YYYY-MM-DD"),
      discount_start_date:
        fieldsValue["discount_start_date"]?.format("YYYY-MM-DD"),
      // product_weight: itemWeight,
    };
    data.image1 = imgUrl[0];
    data.image2 = imgUrl[1];
    data.image3 = imgUrl[2];
    data.image4 = imgUrl[3];
    data.variety = variant;
    data.sellerId = brandInfo.seller_id;
    data.currency = pickCurrency();

    if (data && imgUrl.length > 0) {
      const res = await createProduct(data, info.token);
      if (res.status === "ok") {
        message.success(res.message);
        form.resetFields();
      }
      if (res.status === "not ok") message.error(res.message);
    }
  };

  // const onSaveForLater = async () => {
  //   setShowDialog(false);
  //   const { name } = form.getFieldValue();
  //   const data = {
  //     ...form.getFieldsValue(),
  //     weight: itemWeight,
  //     discount_end_date: undefined,
  //     discount_start_date: undefined,
  //     image1: imgUrl[0],
  //     image2: imgUrl[1],
  //     image3: imgUrl[2],
  //     image4: imgUrl[3],
  //     variety: variant,
  //     currency: pickCurrency(),
  //   };

  //   if (name === undefined) {
  //     return message.error("Please enter at least item title");
  //   }

  //   const res = await createDraft(data, info.token);

  //   if (res.status === "ok") {
  //     message.success(res.message);
  //     form.resetFields();
  //     navigate("/saved-listings");
  //   }
  // };

  const onPreviewLisitng = () => {
    const { discount_end_date, discount_start_date } = form.getFieldValue();
    const { price } = form.getFieldValue();
    const previewObject = {
      ...form.getFieldsValue(),
      discount_end_date: discount_end_date?.format("YYYY-MM-DD"),
      discount_start_date: discount_start_date?.format("YYYY-MM-DD"),
      // weight: itemWeight,
      price: Number(price),
      colors: selectedColors,
      sizes: selectedSizes,
      images: imgUrl,
      variant: variant,
      currency: pickCurrency(),
    };
    setProductPreview(previewObject);

    setPreviewModalVisible(true);
  };

  const columns = [
    {
      title: "Item name",
      dataIndex: "itemName",
      key: "name",
      width: 300,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{ color: "white", backgroundColor: "black" }}
        >
          {record.size}
        </div>
      ),
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "110px", height: "45px" }}
        >
          <div
            style={{ background: record.colorObj.hex }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record.colorObj.name}</div>
        </div>
      ),
      width: 250,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "16px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            placeholder="Enter quantity"
            className="input"
            type="number"
            min={1}
            onChange={(e) => addQuantity(record, e.target.value)}
            style={{ height: "45px" }}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      render: (id) => (
        <Popconfirm
          title="Deletions cannot be undone, new sizes will need to be added if you change your mind. Confirm delete?"
          onConfirm={() => confirm(id)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <DeleteFilled
            className="table-quantity-delete-icon"
            style={{ color: "red" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const getCategories = async () => {
    const result = await axios.get("https://losode.dev/api/v2/all/categories");
    setCategories(result.data.data);
  };

  const loadSelectedCategory = (gender) => {
    if (gender === "1") gender = "Men";
    if (gender === "2") gender = "Women";
    if (gender === "3") gender = "Kids";
    setCategory(categories[gender].categories);
    selectGroup(gender);
    setItemGender(gender);
  };
  const loadSubCategory = (id) => {
    const subCategory = category.find((sub) => sub.id === id);
    setSubCategory(subCategory);
    selectItemFit(subCategory.category);
  };

  const loadSelectedKidsGender = (cat) => {
    setKidsAgeRange(category.find((item) => item.category === cat).subs);
  };

  const loadSelectedKidCategory = (range) => {
    const category = kidsAgeRange.find((item) => item.category === range).subs;
    setKidsCategory(category);
    setKidsOccasions(kidsOccasion(range));
  };

  const loadSelectedKidSubCategory = (cat) => {
    const subCategory = kidsCategory.find((item) => item.category === cat).subs;
    setKidsSubCategory(subCategory);
  };

  const selectGroup = (gender) => {
    if (gender === "Men") setItemGroup(["Regular", "Plus Size", "Tall"]);
    if (gender === "Women")
      setItemGroup(["Regular", "Plus Size", "Tall", "Petite", "Maternity"]);
  };
  const selectItemFit = (cat) => {
    if (cat === "Clothing")
      setItemFit(["Loose Fit", "Regular Fit", "Tight Fit "]);
    if (cat === "Footwear")
      setItemFit(["Wide Fit", "Regular Fit", "Slim Fit "]);
  };

  const loadItemSizes = (group) => {
    setItemSizes(sizes(itemGender, itemCat, group, subCategory?.category));
  };

  useEffect(() => {
    if (subCategory.category === "Fabrics") loadItemSizes();
  }, [subCategory?.category]);

  useEffect(() => {
    getCategories();
    fetchBrands(info.token);
  }, []);

  useEffect(() => {
    if (sellerId) {
      setBrandInfo(
        brands.find((brand) => parseInt(brand.seller_id) === sellerId)
      );
    }
  }, [sellerId]);

  useEffect(() => {
    generateTable();
  }, [selectedColors, selectedSizes]);

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const isTabletOrMobile = false;

  return (
    <div style={{ background: "#F9F9F9" }}>
      {/* <RouterPrompt
        when={1}
        message="You have unsaved changes. Are you sure you want to leave this page?"
        title="Are you sure you want to leave?"
        cancelText="Confirm"
        okText="Cancel"
        canShowDialogLeavingPage={showDialog}
      /> */}
      <div className="list-navbar">
        <img
          src="/images/losode-no-tagline.png"
          alt="logo"
          style={{ width: !isTabletOrMobile ? "120px" : "95px" }}
        />
        <div
          style={{ cursor: "pointer" }}
          // onClick={() => navigate("/account")}
        >
          Your Admin Dashboard
        </div>
      </div>
      <div className="account-container">
        {/* <Button onClick={() => navigate("/")}>Home</Button> */}
        <Form
          form={form}
          name="listing"
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
        >
          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: !isTabletOrMobile ? "24px" : "16px",
            }}
          >
            <h1 className="list-item-welcome-txt-title">List A New Item</h1>
            <p className="list-item-welcome-txt-more">
              Add new items to your store. If you are a first time seller on
              Losode; we have left instructions and additional information in
              each section of the form below. It is important to us that you
              have a seamless experience as you create your store. If you need
              help, send an email to our Seller team -{" "}
              <a href="mailto:sellers@losode.com">sellers@losode.com</a>
            </p>
            <Col span={24}>
              <Form.Item name="brandName" label="Brand Name">
                <AutoComplete
                  className="autocomplete item-title-specific"
                  onChange={onChange}
                  onSearch={handleSearch}
                  placeholder="Enter your businnes or brand name"
                >
                  {result.map((brand, idx) => (
                    <Option key={idx} value={brand.store_name}>
                      {brand.store_name}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Item title"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  className="input item-title-specific"
                  placeholder="E.G. Sequined Drawstring Silk Dress"
                  suffix={
                    <Tooltip title="Ensure your title is descriptive and add your brand name at the end. If your item has multiple colours do not use colour in your title e.g., ‘Black sequined dress by Jane Bloggs’ or ‘Sequined drawstring silk dress by Joe Blogs’ ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="product_type"
                label="Ready to wear or Made to order?"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setType(e.target.value)}>
                  <Radio value="ready to wear">Ready to wear</Radio>
                  <Radio value="bespoke">Made to order</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {type === "bespoke" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Space>
                  <Form.Item
                    name="bespoke_duration"
                    label="How long will it take for the item to be made? "
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      style={{
                        width: "100%",
                        marginRight: "16px",
                        alignItems: "center",
                      }}
                      className="input"
                      placeholder="e.g. 5"
                      suffix={
                        <Tooltip title="Please note that customers are not usually prepared to wait longer than 10 days for items to be made">
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                              fontSize: "20px",
                              marginRight: "20px",
                            }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <span
                    style={{
                      fontSize: "18px",
                      display: !isTabletOrMobile ? "block" : "none",
                    }}
                  >
                    days
                  </span>
                </Space>
              </Col>
            )}
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="product_return_type"
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                    }}
                  >
                    <div>What is your returns policy?</div>
                    <Tooltip title="As much as we do not anticipate a high number of issues. It is important to give your buyer a level of comfort. Damaged items will be returned ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Checkbox.Group>
                  <Checkbox value="Returns">Refunds</Checkbox>
                  {/* <Checkbox value="Amendments">Amendments</Checkbox> */}
                  <Checkbox value="Exchanges">Exchanges</Checkbox>
                </Checkbox.Group>
                {/* <div style={{ marginTop: "8px" }}>
                  Please select at least one option
                </div> */}
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="main_catid"
                label="Who is the item made for?"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Select
                  className="select"
                  onChange={loadSelectedCategory}
                  placeholder="Select an option"
                >
                  <Option value="2">Women</Option>
                  <Option value="1">Men</Option>
                  <Option value="3">Kids</Option>
                </Select>
              </Form.Item>
            </Col>

            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_gender"
                  label="Tell us the gender of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidsGender}
                    placeholder="Select an option"
                  >
                    {category.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_age"
                  label="Tell us the age range of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidCategory}
                    placeholder="Select an option"
                  >
                    {kidsAgeRange.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_cat"
                  label="What is the category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_sub_cat"
                  label="What is the sub-category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    // onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsSubCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="sub_catid"
                  label={
                    <div style={{display:'flex', alignItems:'center'}}>
                      <span style={{marginRight:'16px'}}>What is the category?</span>
                      {subCategory.category === "Skin and Beauty" &&
                        <Tooltip title="Note: You have told us that the best category for your item is ‘Skin and Beauty’, we have assumed that size and colour are of no relevance to you. Please add the item volume to your title e.g., ‘Shea butter – 500ml’ or ‘Lip colour – rose pink’" defaultOpen>
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                              fontSize: "20px",
                            }}
                          />
                        </Tooltip>
                      }
                      {subCategory.category === "Grooming" &&
                        <Tooltip title="Note: You have told us that the best category for your item is ‘Grooming’, we have assumed that size and colour are of no relevance to you. Please add the item volume to your title e.g., ‘Shea butter – 500ml’ or ‘Lip colour – rose pink’" defaultOpen>
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                              fontSize: "20px",
                            }}
                          />
                        </Tooltip>
                      }
                    </div>
                  }
                  
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    onChange={loadSubCategory}
                  >
                    {category.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="product_catid"
                  label="What is the sub-category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    onSelect={(val, p) => setItemCat(p.children)}
                  >
                    {subCategory.subs?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender === "Kids" && subCategory.category !== "Skin and Beauty" && subCategory.category !== "Grooming" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is it best suited for?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {kidsOccasions?.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory.category !== "Skin and Beauty" && subCategory.category !== "Grooming" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is it best suited for?"
                  rules={[
                    {
                      required: (subCategory.category ==="Accessories" || subCategory.category ==="Footwear" ) ? false: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {occasion.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory.category !== "Skin and Beauty" && subCategory.category !== "Grooming" && (
              <>
                {(subCategory?.category === "Clothing" ||
                  subCategory?.category === "Footwear") && (
                  <>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="size_and_fit"
                        label="What is the size fit?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                        >
                          {itemFit.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="group"
                        label="What is the body fit?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                          onChange={loadItemSizes}
                        >
                          {itemGroup.map((group, idx) => (
                            <Option key={idx} value={group}>
                              {group}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col span={24}>
              <Form.Item
                name="description"
                label="What is the description of the item?"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="Provide a comprehensive description of the item you are listing. Include details such as size, color, material, style, and any unique characteristics."
                />
              </Form.Item>
            </Col>

            <Col span={24}>
            <Form.Item
                name="material_info"
                label={(subCategory.category === "Skin and Beauty" || subCategory.category === "Grooming") ? "How should buyers use the item?" : "How should buyers care for the item?"}
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="e.g., Add useful information on material composition and how to clean the item. e.g., 100% cotton, hand wash only.. "
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="price"
                label="How much is your item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  type="number"
                  className="input"
                  placeholder="Enter price"
                  suffix={
                    <Tooltip title="Prices must be set in your local currency. If you want to set prices in a different currency, change your base location in Store settings">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            {(subCategory.category === "Skin and Beauty" ||
              subCategory.category === "Grooming" ||
              subCategory.category === "Accessories" ||
              subCategory.category === "Bags") && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="product_quantity"
                  label="How many of these items do you currently have in stock?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="input"
                    placeholder="Enter quantity"
                  />
                </Form.Item>
              </Col>
            )}

            {(subCategory.category === "Clothing" ||
              subCategory.category === "Footwear" ||
              subCategory?.category === "Fabrics") && (
              <>
                <Col span={24}>
                  <Form.Item label="Select the colour of your item and select two other colours this item is available in.">
                    {selectedColors.length > 0 && (
                      <div className="selected-colors-text-container">
                        <div className="selected-colors-text-container-title">
                          Create a new listing if you have more than three (3)
                          colours
                        </div>
                        <div className="selected-colors-container">
                          {selectedColors.map((color, index) => {
                            return (
                              <div className="selected-color-container" key={index}>
                                <div
                                  className="color-check-container"
                                  style={{
                                    borderColor:
                                      color.hex !== "#000000"
                                        ? color.hex
                                        : "white",
                                    backgroundColor: "black",
                                  }}
                                >
                                  <CheckCircleFilled
                                    style={{
                                      color:
                                        color.hex !== "#000000"
                                          ? color.hex
                                          : "#383838",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div className="color-name">{color.name}</div>
                                <CloseCircleOutlined
                                  onClick={() => subtractColor(color)}
                                  style={{ fontSize: "16px" }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="all-colors-container">
                      {colors.map((color, index) => {
                        return (
                          <div
                            onClick={() => addColor(color)}
                            className="color-container"
                            key={index}
                          >
                            <div
                              style={{ background: color.hex }}
                              className="color-hex-box"
                            ></div>
                            <div className="color-name">{color.name}</div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="color-container-extra">
                      Note: You have told us that the best category for your
                      item is ‘beauty’, we have assumed that size and colour are
                      of no relevance to you. Please add the item volume to your
                      title e.g., ‘Shea butter by Losode – 500ml’ or Lip colour
                      by ‘Losode – rose pink’
                    </div> */}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <div>
                        Please select all sizes your item is available in. You
                        can select multiple sizes
                      </div>
                    }
                  >
                    <div className="sizes-section-container">
                      <div className="sizes-container">
                        {itemSizes?.map((size, index) => {
                          return (
                            <div
                              key={index}
                              className="size-contain"
                              onClick={() => changeSize(size.letter)}
                              style={{
                                borderColor: selectedSizes.includes(size.letter)
                                  ? "black"
                                  : "#D4D4D4",
                                borderWidth: selectedSizes.includes(size.letter)
                                  ? "2px"
                                  : "1px",
                              }}
                            >
                              <div
                                className="size-letter"
                                style={{
                                  backgroundColor: selectedSizes.includes(
                                    size.letter
                                  )
                                    ? "black"
                                    : "white",
                                  color: selectedSizes.includes(size.letter)
                                    ? "white"
                                    : "black",
                                }}
                              >
                                {size.letter}
                              </div>
                              {/* <div
                                className="size-number"
                                style={{
                                  backgroundColor: selectedSizes.includes(
                                    size.letter
                                  )
                                    ? "black"
                                    : "white",
                                  color: selectedSizes.includes(size.letter)
                                    ? "white"
                                    : "black",
                                }}
                              >
                                {size.number}
                              </div> */}
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className="one-size-container">
                        <div
                          className="one-size"
                          onClick={() => changeSize("One Size")}
                          style={{
                            backgroundColor: selectedSizes.includes("One Size")
                              ? "black"
                              : "white",
                            color: selectedSizes.includes("One Size")
                              ? "white"
                              : "black",
                          }}
                        >
                          One Size
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="one-size-extra">
                      View our standard{" "}
                      <strong style={{ textDecoration: "underline" }}>
                        size guide
                      </strong>
                      . If you have your own size guide, add it in store
                      settings
                    </div> */}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24}>
              <Form.Item label="Upload Item Image(s)">
                <div className="product-image-section">
                  <ImgCrop aspect={11 / 16}>
                    <Upload
                      customRequest={(file) => uploadImg(file, imgRef1)}
                      showUploadList={false}
                    >
                      <div className="product-big-image" ref={imgRef1}>
                        <PlusCircleOutlined className="product-image-upload-icon-big" />

                        <div className="product-image-upload-text-big">
                          Add Main Image
                        </div>
                      </div>
                    </Upload>
                  </ImgCrop>

                  <div className="product-small-images-container">
                    <ImgCrop aspect={11 / 16}>
                      <Upload
                        customRequest={(file) => uploadImg(file, imgRef2)}
                        showUploadList={false}
                      >
                        <div className="product-small-image" ref={imgRef2}>
                          <PlusCircleOutlined className="product-image-upload-icon-small" />
                          <div className="product-image-upload-text-small">
                            Back View
                          </div>
                        </div>
                      </Upload>
                    </ImgCrop>
                    <ImgCrop aspect={11 / 16}>
                      <Upload
                        customRequest={(file) => uploadImg(file, imgRef3)}
                        showUploadList={false}
                      >
                        <div className="product-small-image" ref={imgRef3}>
                          <PlusCircleOutlined className="product-image-upload-icon-small" />
                          <div className="product-image-upload-text-small">
                            Side View
                          </div>
                        </div>
                      </Upload>
                    </ImgCrop>
                    <ImgCrop aspect={11 / 16}>
                      <Upload
                        customRequest={(file) => uploadImg(file, imgRef4)}
                        showUploadList={false}
                      >
                        <div className="product-small-image" ref={imgRef4}>
                          <PlusCircleOutlined className="product-image-upload-icon-small" />
                          <div className="product-image-upload-text-small">
                            Item Detail
                          </div>
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>
                </div>
                <div className="product-image-section-extra">
                  Use clear images taken with good lighting, you can add up to
                  four (4), we suggest that you add at least two (2) images to
                  make customers more comfortable with the item
                </div>
                {submit && imgUrl.length === 0 && (
                  <p className="error_msg">Item images is required</p>
                )}
              </Form.Item>
            </Col>

            {variant.length > 0 && (
              <>
                <Col span={24}>
                  <Form.Item label="Based on what you have told us these are available options for your items, now tell us the quantity of each">
                    <Table
                      dataSource={variant}
                      columns={columns}
                      pagination={false}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={24}>
              <Form.Item
                name="has_discount"
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div>Would you like to add a discount to your item? </div>
                    <Tooltip title="Giving your buyers a discount when you first open your store is a good idea and can drive a high volume of sales quickly ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "16px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setDiscount(e.target.value)}
                  value={isDiscount}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {isDiscount && (
              <>
                <Col span={!isTabletOrMobile ? 6 : 24}>
                  <Form.Item
                    name="discount_percent"
                    label="Enter discount percentage"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "11px",
                      }}
                    >
                      <Input
                        type="number"
                        className="input"
                        style={{ width: "40%" }}
                      />
                      <div style={{ fontSize: "20px" }}>%</div>
                    </div>
                    {/* <div
                      className="product-image-section-extra"
                      style={{ marginTop: "16px" }}
                    >
                      Field should be numeric only
                    </div> */}
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_start_date"
                    label="When do you want the discount to start?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Select start date"
                    />
                  </Form.Item>
                  <Form.Item
                    name="discount_start_type"
                    style={{ marginTop: "-16px" }}
                  >
                    <Checkbox value="immediately">
                      <span style={{ marginRight: "4px" }}>
                        Start immediately{" "}
                      </span>
                      <Tooltip title="The discount you have applied will start as soon as your listing is live">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            fontSize: "20px",
                            marginRight: "20px",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_end_date"
                    label="When do you want the discount to end?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Select end date"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {/* </div> */}
          </Row>
          <Row className="form-row" gutter={[48, 12]}>
            <div
              style={{
                padding: !isTabletOrMobile ? "24px 24px 64px" : "16px",
                background: "white",
                width: "100%",
              }}
            >
              <Col span={24}>
                <div className="delivery-title">Delivery</div>
                <div className="delivery-box">
                  <div className="delivery-notification">
                    <InfoCircleOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        fontSize: "16px",
                        marginRight: "20px",
                      }}
                    />
                    We work with third-party delivery providers to handle your
                    delivery
                  </div>

                  {/* <Form.Item
                    name="delivery_type"
                    label={
                      <div>
                        <strong>How will this item be delivered?</strong>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                    style={{
                      width: !isTabletOrMobile ? "60%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    
                    <Radio.Group
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                      }}
                      onChange={(e) => setSelfDeliveryInfo(e.target.value)}
                    >
                      <Radio value="self">Handle delivery yourself?</Radio>
                      <Radio value="losode">Losode handles delivery?</Radio>
                      <Radio value="pay">
                        You pay for delivery (free to the buyer)?
                      </Radio>
                    </Radio.Group>
                  </Form.Item> */}
                  <div
                    style={{
                      width: !isTabletOrMobile ? "40%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="product_weight"
                        label={
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <strong>Tell us the weight of your item</strong>
                            <Tooltip title="Ensure you have entered the correct weight to avoid additional charges">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "16px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select occasion-select-box"
                          placeholder="Select an option"
                          style={{ width: "80%" }}
                        >
                          {weights(subCategory.category, subCategory.slug)?.map(
                            (item) => (
                              <Option key={item.id} value={item.KG}>
                                {item.KG}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <span> Kg </span>
                    </div>

                    <div className="weight-extra-text">
                      Please provide accurate weights for your item to avoid
                      losing a sale. Delivery fees are automatically calculated
                      based on the total weight of all items in a buyer’s
                      basket. Inaccurate weights may result in high delivery
                      estimates and may cause buyers to abandon their basket.
                    </div>
                  </div>
                </div>
              </Col>
            </div>
            <div
              style={{
                padding: !isTabletOrMobile ? "0" : "16px",
                width: "100%",
                display: !isTabletOrMobile ? "flex" : "block",
              }}
            >
              <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="list-item-welcome-btn-submit"
                    htmlType="submit"
                    icon={<ArrowRightOutlined />}
                  >
                    Submit item for listing
                  </Button>
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="list-item-welcome-btn-preview"
                    onClick={() => onPreviewLisitng()}
                    icon={<ArrowRightOutlined />}
                  >
                    Preview
                  </Button>
                </Form.Item>
              </Col>
              {/* <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="list-item-welcome-btn-later"
                    // htmlType="submit"
                    icon={<ArrowRightOutlined />}
                    // onClick={() => onSaveForLater()}
                  >
                    Save for later
                  </Button>
                </Form.Item>
              </Col> */}
            </div>
            <Col span={24}>
            <div className="list-item-submit-terms">
              By submitting this listing, you agree to the {' '}
              <a href="https://www.losode.com/sell-terms" style={{fontWeight:'bold', textDecoration:'underline'}}>Losode Seller Agreement</a> {' '}
              and the {' '}
              <a href="https://www.losode.com/policy" style={{fontWeight:'bold', textDecoration:'underline'}}>Losode Privacy Policy</a>. {' '}
              You assume full responsibility for the item you have listed and the content of your listing.
              </div>
            </Col>
          </Row>
        </Form>

        {/* <HelpFeedback /> */}
      </div>

      <PreviewLisitingModal
        product={productPreview}
        modalVisible={previewModalVisible}
        setModalVisible={setPreviewModalVisible}
      />

      <style jsx="true">{`
        .ant-modal-footer > button:nth-child(2) {
          background: black;
          color: white;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .ant-modal-footer > button:nth-child(1) {
          background: white;
          color: black;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .list-navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-family: "DomaineSansText-Light";
          padding: 20px 40px;
          background: white;
        }
        .all-colors-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px;
          justify-content: flex-start;
          height: 135px;
          font-family: "DomaineSansText-Light";
          overflow: auto;
        }
        .selected-colors-text-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px 15px;
          border-bottom: 0;
          font-family: "DomaineSansText-Light";
        }
        .selected-colors-container {
          display: flex;
          gap: 16px;
          font-family: "DomaineSansText-Light";
        }
        .color-container {
          padding: 8px;
          border: 1px solid black;
          width: 120px;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          white-space: nowrap;
          cursor: pointer;
        }
        .selected-color-container {
          padding: 8px;
          border: 1px solid black;
          width: 145px;
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: center;
          white-space: nowrap;
          background: black;
          color: white;
        }
        .color-hex-box {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        .color-check-container {
          border: 1px solid;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          text-align: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .color-container-extra {
          font-size: 13px;
          margin-top: 8px;
        }

        .sizes-section-container {
          display: flex;
          gap: 11px;
          width: 70%;
        }
        .sizes-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
        }
        .size-contain {
          border: 1px solid black;
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 11px;
          align-items: center;
          cursor: pointer;
        }
        .one-size {
          border: 1px solid black;
          width: 90px;
          text-align: center;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .size-letter,
        .size-number {
          border: 1px solid black;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
        .one-size-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .one-size-extra {
          font-size: 13px;
          margin-top: 11px;
        }

        .product-image-section {
          display: flex;
          gap: 16px;
          padding: 16px;
          border: 1px solid black;
          width: fit-content;
        }
        .product-big-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;
          width: 300px;
          height: 300px;
        }
        .product-small-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;

          width: 92px;
          height: 92px;
          text-align: center;
        }
        .product-small-images-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: space-between;
        }
        .product-image-upload-icon-big {
          font-size: 30px;
          margin-bottom: 16px;
        }
        .product-image-upload-icon-small {
          font-size: 15px;
          margin-bottom: 8px;
        }
        .product-image-upload-text-small {
          font-size: 10px;
        }
        .product-image-section-extra {
          font-size: 13px;
          margin-top: 8px;
        }
        .table-quantity-container {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 200px;
        }
        .table-quantity-delete-icon {
          cursor: pointer;
        }

        textarea.ant-input {
          padding-top: 16px;
          padding-left: 16px;
        }

        .weight-extra-text {
          background: black;
          color: white;
          padding: 11px;
          margin-top: 30px;
        }
        .weight-number-box {
          display: flex;
          gap: 20px;
          align-items: center;
          border: 1px solid black;
          width: fit-content;
          padding: 11px;
        }
        .weight-number-section {
          display: flex;
          gap: 11px;
          align-items: center;
        }
        .delivery-box {
          display: flex;
          border: 1px solid rgb(111 111 111 / 29%);
          padding: 25px;
          justify-content: space-between;
          gap: 70px;
        }
        .delivery-notification {
          width: 40%;
          display: flex;
          align-items: center;
          height: 10%;
        }
        .delivery-title {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: bold;
        }
        .list-item-welcome-btn-preview,
        .list-item-welcome-btn-later {
          background: white;
          font-size: 18px;
          height: 56px;
          width: 274px;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .list-item-welcome-btn-preview,
        .list-item-welcome-btn-later,
        .list-item-welcome-btn-submit {
          margin-top: 32px;
        }
        .error_msg {
          color: #e15554 !important;
          font-size: 0.75rem !important;
          font-weight: 300 !important;
          font-style: normal;
        }

        .account-container {
          font-family: DomaineSansText-Light;
          padding: 3% 10%;
        }
        .list-item-welcome-txt-title {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          padding: 0 24px;
        }
        .list-item-welcome-txt-more {
          font: normal normal 13px DomaineSansText-Light;
          color: black;
          padding: 0 24px;
          margin-bottom: 24px;
        }

        .list-item-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .list-item-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: black;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 56px;
          border: 1px solid black;
          padding: 4px 0px 4px 14px;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
          .ant-select-selector
          input {
          height: 56px;
        }
        .autocomplete.ant-select-show-search.ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          padding: 14px;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker,
        .ant-select-selector {
          height: 56px !important;
          width: 100%;
          border: 1px solid black;
        }
        .ant-picker-input > input,
        .ant-checkbox-group {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-radio-group {
          display: flex;
          align-items: center;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }

        .ant-radio-wrapper {
          margin-right: 40px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid black;
          height: 56px;
          padding: 0px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 14px;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #800000;
          color: #fff;
          height: 56px;
          width: 151px;
        }
        .list-item-welcome-btn-submit {
          font-size: 18px;
          height: 56px;
          background: black;
          width: 274px;
          color: white;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }
        .ant-popover-inner-content {
          font-family: "DomaineSansText-Light";
        }
        .item-title-specific input.ant-input {
          text-transform: capitalize;
        }

        @media (max-width: 640px) {
          .account-container {
            padding: 0;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
            line-height: 1.2;
          }
          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .list-navbar {
            font-size: 16px;
            padding: 16px;
          }
          .input,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker,
          .ant-select-selector {
            height: 48px !important;
            margin-top: 0 !important;
            font-size: 13px !important;
          }
          .ant-select,
          .ant-input-affix-wrapper > input.ant-input,
          span.ant-radio + * {
            font-size: 13px !important;
          }
          .ant-form-item-explain-error {
            margin-top: 8px;
            font-size: 12px;
            margin-bottom: 8px;
          }
          .ant-form-vertical .ant-form-item-label > label,
          .ant-form label,
          .ant-input:placeholder-shown,
          .ant-picker-input > input:placeholder-shown {
            font-size: 13px !important;
          }
          .product-image-section {
            flex-direction: column;
          }
          .product-image-section .ant-upload,
          .product-image-section span,
          .product-big-image {
            width: 100%;
          }
          .product-small-images-container {
            flex-direction: row;
          }
          .delivery-box {
            gap: 24px;
            flex-direction: column;
            padding: 0;
            border: 0;
          }
          .delivery-notification {
            width: auto;
          }
          .delivery-title,
          .list-item-welcome-txt-title {
            font-size: 18px;
          }
          .weight-extra-text {
            font-size: 13px;
          }
          .list-item-welcome-btn-submit,
          .list-item-welcome-btn-preview,
          .list-item-welcome-btn-later {
            width: 100%;
            justify-content: center;
            gap: 16px;
            font-size: 16px;
          }
          .list-item-welcome-btn-preview,
          .list-item-welcome-btn-later,
          .list-item-welcome-btn-submit {
            margin-top: 0px;
          }
          .list-item-submit-terms {
            padding: 0 16px;
            font-size: 13px;
            margin-bottom: 24px;
          }
          .ant-select-arrow {
            top: 50%;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            line-height: 1.6;
          }
          .color-container {
            font-size: 13px;
            width: 48%;
          }
          .selected-color-container {
            width: 47%;
            font-size: 13px;
            height: fit-content;
          }
          .selected-colors-container {
            gap: 11px;
            flex-wrap: wrap;
          }
          .selected-colors-text-container-title {
            font-size: 13px;
          }
          .size-contain {
            width: 31%;
          }
          .size-letter,
          .size-number {
            font-size: 13px;
            height: fit-content;
            padding: 6px;
          }
          .sizes-section-container {
            width: 100%;
          }
          .table-quantity-container {
            width: auto;
          }
          .occasion-select-box .ant-select-selector {
            height: fit-content !important;
            padding: 4px;
          }
        }
      `}</style>
    </div>
  );
};

export default ListAnItem;
