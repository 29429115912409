import { useEffect, useRef } from "react";
import {
  SearchOutlined,
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Select, message } from "antd";
// import { useNavigate } from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { useSelector, useDispatch } from "react-redux";
import ListingView from "../seller/ListingView";
import { brandActionCreators } from "../../redux/seller";
import Dashboard from "./Dashboard";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const ManageListings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const topPageRef = useRef(null);

  const type = window.location.pathname.split("/")[2];

  const { info } = useSelector((state) => state.user);
  const { allListings } = useSelector((state) => state.brands);

  const listings = allListings?.data;
  const meta = allListings?.meta_data;

  const {
    fetchListings,
    fetchNewListings,
    fetchNextListings,
    fetchProduct,
    deleteProduct,
  } = bindActionCreators(brandActionCreators, dispatch);

  const scrollToTop = () =>
    topPageRef.current.scrollIntoView({ behavior: "smooth" });

  let currKey;

  if (type === "all") currKey = 4;
  if (type === "new") currKey = 5;

  const fetchListingType = () => {
    if (type === "all") return fetchListings(info.token);
    if (type === "new") return fetchNewListings(info.token);
  };

  const editItem = async (id) => {
    const res = await fetchProduct(info.token, id);
    if (res.status === "ok") {
      history.push("/edit-listing", { state: "listing" });
    }
  };

  const deleteItem = async (id) => {
    const res = await deleteProduct(info.token, [id], type);
    if (res.status === "ok") {
      message.success(res.message);
    }
  };

  useEffect(() => {
    fetchListingType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
      currentPageKey={currKey}
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              <div
                style={{
                  width: "50%",
                  height: "48px",
                  margin: "24px auto 40px",
                }}
              >
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="Enter Order ID Or Brand Name"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="dashboard-body-content-header">
                <div className="dashboard-body-content-header-container">
                  <div
                    className="dashboard-body-content-header-title"
                    // onClick={() => {
                    //   setPhoneVerifyModalVisible(true);
                    // }}
                    ref={topPageRef}
                  >
                    Manage Listings
                  </div>
                  <div className="dashboard-body-content-header-subtitle">
                    View and edit your listed items here
                  </div>
                </div>

                <Button
                  icon={<PlusOutlined style={{ fontSize: "16px" }} />}
                  className="dashboard-body-quick-action-button"
                  onClick={() =>
                    history.push("/add-product", {
                      brand: "",
                      sellerId: "",
                    })
                  }
                >
                  List A New Item
                </Button>
              </div>
              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div className="dashboard-body-alert-box-container">
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        Anya Wu has 10 new listed items awaiting approval. Click
                        To Approve Now
                      </div>
                    </div>
                    {/* <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        Elizabetha has 15 new listed items awaiting approval.
                        Click To Approve Now
                      </div>
                    </div> */}
                  </div>
                  <div className="dashboard-body-orders-section">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <div
                        className="dashboard-body-orders-title"
                        style={{ lineHeight: "1", marginBottom: "0" }}
                      >
                        {type} Listings
                      </div>
                      <div>
                        <div>
                          <Select
                            placeholder="Filter"
                            className="manage-orders-body-sidebar-content-select"
                          >
                            <Select.Option value="activated">
                              Activated
                            </Select.Option>
                            <Select.Option value="rejected">
                              Rejected
                            </Select.Option>
                            <Select.Option value="pending">
                              Pending
                            </Select.Option>
                            <Select.Option value="deactivated">
                              Deactivated
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
{/* 
                    <div
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <div>
                        <Select
                          placeholder="Sort"
                          className="manage-orders-body-sidebar-content-select"
                        >
                          <Select.Option value="latest">Recent</Select.Option>
                          <Select.Option value="price">Price</Select.Option>
                          <Select.Option value="alphabetical">
                            A-Z
                          </Select.Option>
                        </Select>
                      </div>
                    </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {listings?.length > 0 ? (
                        <>
                          {listings.map((listing) => (
                            <ListingView
                              editItem={() => editItem(listing.product_id)}
                              deleteItem={() => deleteItem(listing.product_id)}
                              key={listing.product_id}
                              product={listing}
                              productTag={type === "new" ? "new" : ""}
                            />
                          ))}
                          <div className="rows-box">
                            <p
                              className="txt-next"
                              style={{
                                color:
                                  meta?.current_page !== 1
                                    ? "black"
                                    : "#d9d9d9",
                                justifyContent: "flex-start",
                              }}
                            >
                              <LeftOutlined
                                onClick={() => {
                                  fetchNextListings(
                                    info.token,
                                    meta?.prev_page_url
                                  );
                                  scrollToTop();
                                }}
                              />
                              <span
                                onClick={() => {
                                  fetchNextListings(
                                    info.token,
                                    meta?.prev_page_url
                                  );
                                  scrollToTop();
                                }}
                              >
                                Previous
                              </span>
                            </p>

                            <p className="num-text">
                              Page {meta?.current_page} of{" "}
                              {Math.ceil(meta?.total / meta?.per_page)}
                            </p>

                            <p
                              className="txt-next"
                              style={{
                                color:
                                  meta?.current_page !==
                                  Math.ceil(meta?.total / meta?.per_page)
                                    ? "black"
                                    : "#d9d9d9",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                onClick={() => {
                                  fetchNextListings(
                                    info.token,
                                    meta?.next_page_url
                                  );
                                  scrollToTop();
                                }}
                              >
                                Next
                              </span>
                              <RightOutlined
                                onClick={() => {
                                  fetchNextListings(
                                    info.token,
                                    meta?.next_page_url
                                  );
                                  scrollToTop();
                                }}
                              />
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>No Listings AVailable</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}
        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
            padding-bottom: 40px;
            margin-top: 16px;
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 30px;
            width: 100%;
            margin-bottom: 50px;
            margin-top: 24px;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .rows-box {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            width: 100%;
          }
          .num-text {
            color: #000000;
            flex-basis: 33%;
            text-align: center;
          }
          .txt-next {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 24px;
            flex-basis: 33%;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 10px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 240px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: 32px !important;
            font-size: 14px !important;
            padding: 0 16px !important;
            width: 100px !important;
            border-color: black !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-sidebar-content-select.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 2.2;
          }
          >>>>>>>8e5093e18f5fa6e75fee5863ec044c904589ec5
            .manage-listing-all-content {
            position: relative;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
            padding-bottom: 40px;
            margin-top: 16px;
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 30px;
            width: 100%;
            margin-bottom: 50px;
            margin-top: 24px;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 10px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
            text-transform: capitalize;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 240px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: 48px !important;
            font-size: 14px !important;
            padding: 0 16px !important;
            width: 150px !important;
            border-color: black !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-sidebar-content-select.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 3;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ManageListings;
