import { FETCH_BUYER_DETAILS } from "./types";

const initialState = {
  customer: {},
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUYER_DETAILS: {
      return { ...state, customer: action.payload.data };
    }

    default:
      return state;
  }
};

export default customerReducer;
