const sizes = (gender, cat, group, category) => {
  console.log(category);
  if (category === "Fabrics") {
    return [
      { id: 1, letter: "3 Yards" },
      { id: 2, letter: "5 Yards" },
      { id: 3, letter: "6 Yards" },
      { id: 4, letter: "9 Yards" },
      { id: 5, letter: "15 Yards" },
      { id: 6, letter: "20 Yards" },
      { id: 7, letter: "25 Yards" },
    ];
  }
  if (gender === "Women") {
    if (group === "Plus Size") {
      return [
        { id: 1, letter: "UK 18-3XL" },
        { id: 2, letter: "UK 20-4XL" },
        { id: 3, letter: "UK 22-5XL" },
        { id: 4, letter: "UK 24-6XL" },
        { id: 5, letter: "UK 26-7XL" },
        { id: 6, letter: "UK 28-8XL" },
      ];
    }
    return [
      { id: 1, letter: "UK 4-XS" },
      { id: 2, letter: "UK 6-S" },
      { id: 3, letter: "UK 8-M" },
      { id: 4, letter: "UK 10-M" },
      { id: 5, letter: "UK 12-L" },
      { id: 6, letter: "UK 14-XL" },
      { id: 7, letter: "UK 16-2XL" },
    ];
  }
  if (gender === "Men") {
    if (cat === "Loafers") {
      return [
        { id: 1, letter: "Uk 5" },
        { id: 2, letter: "UK 6" },
        { id: 3, letter: "UK 7" },
        { id: 4, letter: "UK 7.5" },
        { id: 5, letter: "UK 8" },
        { id: 6, letter: "UK 8.5" },
        { id: 7, letter: "UK 9" },
        { id: 8, letter: "UK 9.5" },
        { id: 8, letter: "UK 10" },
        { id: 8, letter: "UK 10.5" },
        { id: 8, letter: "UK 11" },
        { id: 8, letter: "UK 12" },
        { id: 8, letter: "UK 13" },
      ];
    }
    if (cat === "Shirts & T-Shirts") {
      if (group === "Plus Size") {
        return [
          { id: 1, letter: "2XL - Chest - 49-52" },
          { id: 2, letter: "3XL - Chest - 54-56" },
        ];
      }
      return [
        { id: 1, letter: "S - Chest - 35-37" },
        { id: 2, letter: "M - Chest - 38-40" },
        { id: 3, letter: "L - Chest - 42-45" },
        { id: 4, letter: "XL - Chest - 46-48" },
      ];
    }
    if (cat === "Jeans" || cat === "Trousers") {
      if (group === "Regular") {
        return [
          { id: 1, letter: "W26-L30" },
          { id: 2, letter: "W28-L30" },
          { id: 3, letter: "W28-L32" },
          { id: 4, letter: "W32-L30" },
          { id: 5, letter: "W32-L32" },
          { id: 6, letter: "W34-L32" },
          { id: 7, letter: "W36-L30" },
          { id: 8, letter: "W36-L34" },
          { id: 8, letter: "W38-L30" },
          { id: 8, letter: "W38-L32" },
        ];
      }
      if (group === "Tall") {
        return [
          { id: 1, letter: "W28-L30" },
          { id: 2, letter: "W28-L32" },
          { id: 3, letter: "W30-L30" },
          { id: 4, letter: "W30-L32" },
          { id: 5, letter: "W31-L30" },
          { id: 6, letter: "W32-L30" },
          { id: 7, letter: "W32-L32" },
          { id: 8, letter: "W32-L34" },
          { id: 9, letter: "W33-L30" },
          { id: 10, letter: "W33-L32" },
          { id: 11, letter: "W34-L30" },
          { id: 12, letter: "W34-L32" },
          { id: 13, letter: "W34-L34" },
          { id: 14, letter: "W34-L36" },
          { id: 15, letter: "W34-L38" },
          { id: 16, letter: "W36-L32" },
          { id: 17, letter: "W36-L34" },
          { id: 18, letter: "W36-L36" },
          { id: 19, letter: "W36-L38" },
          { id: 20, letter: "W38-L30" },
          { id: 21, letter: "W38-L32" },
          { id: 22, letter: "W38-L34" },
          { id: 23, letter: "W38-L36" },
          { id: 24, letter: "W38-L38" },
          { id: 25, letter: "W40-L30" },
          { id: 26, letter: "W40-L32" },
          { id: 27, letter: "W40-L34" },
          { id: 28, letter: "W42-L30" },
          { id: 29, letter: "W42-L32" },
          { id: 30, letter: "W44-L30" },
          { id: 31, letter: "W44-L32" },
          { id: 32, letter: "W44-L34" },
          { id: 33, letter: "W46-L30" },
          { id: 34, letter: "W46-L32" },
          { id: 35, letter: "W46-L34" },
          { id: 36, letter: "W48-L30" },
          { id: 37, letter: "W48-L32" },
          { id: 38, letter: "W48-L34" },
        ];
      }
    }
    return [
      { id: 1, letter: "Uk 5" },
      { id: 2, letter: "UK 6" },
      { id: 3, letter: "UK 7" },
      { id: 4, letter: "UK 7.5" },
      { id: 5, letter: "UK 8" },
      { id: 6, letter: "UK 8.5" },
      { id: 7, letter: "UK 9" },
      { id: 8, letter: "UK 9.5" },
      { id: 8, letter: "UK 10" },
      { id: 8, letter: "UK 10.5" },
      { id: 8, letter: "UK 11" },
      { id: 8, letter: "UK 12" },
      { id: 8, letter: "UK 13" },
    ];
  }
};

export default sizes;
