import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import brandsReducer from "./seller/seller.reducer";
import ordersReducer from "./orders/orders.reducer";
import currencyReducer from "./currency/currency.reducer";
import logisticsReducer from "./logistics/logistics.reducer";
import customersReducer from "./customer/customer.reducer";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["cart"],
};
const rootReducer = combineReducers({
  user: userReducer,
  brands: brandsReducer,
  orders: ordersReducer,
  currency: currencyReducer,
  logistics: logisticsReducer,
  customers: customersReducer,
});
export default persistReducer(persistConfig, rootReducer);
