import { FETCH_PRICING_SUCEESS, FETCH_PRICING_FAIL, FETCH_MENU_SUCCESS, FETCH_MENU_FAIL } from "./types";

const initialState = {
  pricingData: {},
  pricingError: {},
  logisticsMenuData:{},
  logisticsMenuError:{}
};

const logisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICING_SUCEESS: {
      return { ...state, pricingData: action.payload.data };
    }
    case FETCH_PRICING_FAIL: {
      return { ...state, pricingError: action.payload };
    }
    case FETCH_MENU_SUCCESS: {
      return { ...state, logisticsMenuData: action.payload.data };
    }
    case FETCH_MENU_FAIL: {
      return { ...state, logisticsMenuError: action.payload };
    }

    default:
      return state;
  }
};

export default logisticsReducer;
