// import { useEffect, useState } from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SupplierLayout from "./supplier/assets/LogisticsLayout";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { logisticsActionCreators } from "../../redux/logistics";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const { Search } = Input;

const LogisticInfo = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams()

  const { info } = useSelector((state) => state.user);
  const { pricingData } = useSelector((state) => state.logistics);

  const { fetchPrices, fetchLogisticsMenu } = bindActionCreators(
    logisticsActionCreators,
    dispatch
  );

  const [dhl, setDhl] = useState([])
  const [gwx, setGwx] = useState([])

  useEffect(()=>{
    const dhlItems=[]
    const gwxItems=[]
    for(let i=0; i<pricingData?.pricing?.length ;i++){
      if (pricingData?.pricing[i].delivery_provider==="DHL"){
        dhlItems.push(pricingData?.pricing[i])
      }
      if (pricingData?.pricing[i].delivery_provider==="GWX"){
        gwxItems.push(pricingData?.pricing[i])
      }
    }
    setDhl(dhlItems)
    setGwx(gwxItems)
  },[pricingData])

  const columns = [
    {
      title: "Delivery",
      dataIndex: "delivery_type",
      key: "delivery_type",
    },
    {
      title: "Weight",
      dataIndex: "weight_kg",
      key: "weight_kg",
    },
    {
      title: "Rate",
      // dataIndex: "delivery_type",
      key: "rate",
    },
    {
      title: "Uplift",
      dataIndex: "percentage_uplift",
      key: "percentage_uplift",
    },
    {
      title: "Price",
      dataIndex: "shipping_price",
      key: "price",
    },
    {
      title: "Lead Time",
      dataIndex: "lead_time",
      key: "lead_time",
    },
    {
      title: "Cut-off Time",
      dataIndex: "cutoff_time",
      key: "lead_time",
    },
    
  ];

  useEffect(() => {
    fetchLogisticsMenu(info?.token)
    fetchPrices(info?.token, slug)
    
    // console.log(currencyArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SupplierLayout
      headerTitle="The Logistics PORTAL"
      // headerSubtitle="Get insights on all finance metrics on Losode"
      dashboardBgColor="#fff0"
    >
      <div
        className="manage-payment-container"
        style={{ marginBottom: "32px" }}
      >
        <div>
          <h2 className="txt-title" style={{marginTop:'16px'}}>Nigeria</h2>
          <div style={{marginTop:'-28px'}}>
          Select country to see logistics information
          </div>
          <div
            style={{
              width: "64%",
              height: "48px",
              margin: "16px 0 24px",
            }}
          >
            <Search
              className="search"
              allowClear
              enterButton="Search"
              // onSearch={onSearch}
              placeholder="Select destination country"
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'16px 40px', marginBottom:'40px', background:'black', color:'white'}}>
        <div style={{fontSize:'20px', lineHeight:'1', fontFamily:'DomaineSansText-Light'}}>United Kingdom</div>
          <Button
          className="brands-add-btn"
          style={{background:'white', color:'black'}}
          icon={
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 3.95898V15.0423" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.95898 9.5H15.0423" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          }
          
        >
          Add Supplier
        </Button> 
      </div>
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div className="manage-payment-body-content-section">
                  <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'16px', marginBottom:'40px', borderBottom:'1px solid grey'}}>
                    <div style={{fontSize:'20px', lineHeight:'1'}}>DHL</div>
                      <Button
                      className="brands-add-btn"
                      icon={
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 14.666H14.875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.6875 2.97916C11.9693 2.69737 12.3515 2.53906 12.75 2.53906C12.9473 2.53906 13.1427 2.57793 13.325 2.65344C13.5073 2.72895 13.673 2.83963 13.8125 2.97916C13.952 3.11869 14.0627 3.28434 14.1382 3.46664C14.2137 3.64895 14.2526 3.84434 14.2526 4.04166C14.2526 4.23899 14.2137 4.43438 14.1382 4.61669C14.0627 4.79899 13.952 4.96463 13.8125 5.10416L4.95833 13.9583L2.125 14.6667L2.83333 11.8333L11.6875 2.97916Z" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      }
                      
                    >
                      Edit Supplier
                    </Button> 
                  </div>
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={dhl}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="manage-payment-container" style={{marginTop:'16px'}}>
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div className="manage-payment-body-content-section">
                  <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'16px', marginBottom:'40px', borderBottom:'1px solid grey'}}>
                    <div style={{fontSize:'20px', lineHeight:'1'}}>GWX</div>
                      <Button
                      className="brands-add-btn"
                      icon={
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 14.666H14.875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.6875 2.97916C11.9693 2.69737 12.3515 2.53906 12.75 2.53906C12.9473 2.53906 13.1427 2.57793 13.325 2.65344C13.5073 2.72895 13.673 2.83963 13.8125 2.97916C13.952 3.11869 14.0627 3.28434 14.1382 3.46664C14.2137 3.64895 14.2526 3.84434 14.2526 4.04166C14.2526 4.23899 14.2137 4.43438 14.1382 4.61669C14.0627 4.79899 13.952 4.96463 13.8125 5.10416L4.95833 13.9583L2.125 14.6667L2.83333 11.8333L11.6875 2.97916Z" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      }
                      
                    >
                      Edit Supplier
                    </Button> 
                  </div>
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={gwx}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            padding: 24px 40px;
            background: white;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .ant-table-thead .ant-table-cell {
            background-color: #000;
            color: #fff;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .txt-title {
            line-height: normal;
            margin-bottom: 32px;
            font-size: 20px;
            font-family: "DomaineSansText-Regular";
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 18px;
            font-weight: bold;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 14px;
            width: 100%;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 200px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }

          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }
          .brands-add-btn {
            height: 48px;
            background: black;
            color: white;
            padding: 0 24px;
            font-family: "DomaineSansText-Light";
            text-transform: capitalize;
            display: flex;
            align-items: center;
            gap: 12px;
          }

          @media (max-width: 640px) {
            .manage-payment-coverall-container {
              margin-left: 0;
            }
            .manage-payment-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .manage-payment-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .manage-payment-body-content-header-container {
              display: flex;
              align-items: center;
            }

            .manage-payment-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 180px;
            }

            .manage-payment-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .manage-payment-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .manage-payment-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }

            .manage-payment-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
              margin: 0;
            }
            .manage-payment-body-sidebar {
              width: 100%;
              margin-top: 0;
              margin-bottom: 24px;
              padding: 0 16px;
              border: 0;
              margin-right: 0;
              display: flex;
              justify-content: flex-end;
            }
            .manage-payment-body-sidebar-content {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .manage-payment-body-sidebar-content-title {
              font-weight: normal;
              font-size: 12px;
              margin-bottom: 0px;
            }
            .manage-payment-body-sidebar-content-select {
              width: auto;
            }
            .manage-payment-body-sidebar-content-select .ant-select-selector {
              margin: 0 !important;
              height: auto !important;
              font-size: 12px !important;
              padding: 0 16px !important;
            }
            .manage-payment-body-sidebar-content-select .ant-select-arrow {
              top: 50%;
              height: auto;
              width: auto;
            }
            .manage-payment-body-content {
              width: 100%;
              padding: 0 16px;
            }
            .manage-payment-body-content-top-actions {
              gap: 16px;
              margin-top: 16px;
              display: none;
            }
            .manage-payment-payment-summary-section {
              width: 100%;
              flex-direction: column;
              gap: 16px;
            }
            .payment-summary-grey-box {
              padding: 16px;
              font-size: 12px;
            }
            .payment-summary-pink-box {
              padding: 16px;
              width: auto;
              font-size: 12px;
            }
            .payment-summary-big-number {
              font-size: 14px;
            }
            .payment-summary-pink-box-button {
              font-size: 12px;
              padding: 16px;
              line-height: 1;
            }

            .manage-payment-body-content-section-subtitle {
              font-size: 14px;
            }
            .payment-summary-underline-link {
              padding-bottom: 0px;
            }
            .manage-payment-transaction-table .ant-table {
              font-size: 12px;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
          }
        `}</style>
      </div>
    </SupplierLayout>
  );
};

export default LogisticInfo;
