import React from "react";
import Dashboard from "../seller-mgmt/Dashboard";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";

import ListingView from "../seller/ListingView";

import { message } from "antd";

import { useHistory } from "react-router-dom";

const ApproveSellerListings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { brandProducts: listings, brand } = useSelector(
    (state) => state.brands
  );
  const { info } = useSelector((state) => state.user);

  const { fetchProduct, deleteProduct } = bindActionCreators(
    brandActionCreators,
    dispatch
  );

  const pendingItems = listings?.data?.filter(
    (item) => item?.status === "Pending Approval"
  );

  const editItem = async (id) => {
    const res = await fetchProduct(info.token, id);
    if (res.status === "ok") {
      history.push("/edit-listing", { state: "listing" });
    }
  };

  const deleteItem = async (itemId) => {
    const res = await deleteProduct(info.token, [itemId], "", brand.sellerId);
    if (res.status === "ok") {
      message.success(res.message);
    }
  };

  return (
    <Dashboard
      headerTitle="The Brand Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="dashboard-container">
        <div
          style={{ marginBottom: "16px", cursor: "pointer" }}
          onClick={() => history.goBack()}
        >
          <svg
            width="28"
            height="10"
            viewBox="0 0 28 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
              fill="black"
            />
          </svg>

          <span
            style={{
              fontFamily: "DomaineSansText-Light",
              marginLeft: "8px",
            }}
          >
            Seller Information
          </span>
        </div>

        <div className="manage-listings-title">Approve Listings</div>

        <div className="dashboard-body-main">
          <div className="manage-listings-container">
            {pendingItems?.length > 0 ? (
              pendingItems?.map((listing) => (
                <ListingView
                  editItem={() => editItem(listing.product_id)}
                  deleteItem={() => deleteItem(listing.product_id)}
                  key={listing.product_id}
                  product={listing}
                  // productTag={"new"}
                />
              ))
            ) : (
              <div className="no-listings-to-show">
                <div>
                  <div>Seller currently has 0 pending listings</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
            min-height: 100vh;
            width: 100%;
          }

          .dashboard-body-main {
            padding: 30px 20px;
            background: #fff;
          }

          .manage-listings-title {
            background: #fff;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 30px;
            padding: 20px;
          }

          .manage-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ApproveSellerListings;
