import { FETCH_CURRENCY_SUCEESS, FETCH_CURRENCY_FAIL, FETCH_RATES_SUCEESS, FETCH_RATES_FAIL } from "./types";

const initialState = {
  data: {},
  error: {},
  rates:{}
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_SUCEESS: {
      return { ...state, data: action.payload.data };
    }
    case FETCH_CURRENCY_FAIL: {
      return { ...state, error: action.payload };
    }
    case FETCH_RATES_SUCEESS: {
      return { ...state, rates: action.payload.data };
    }
    case FETCH_RATES_FAIL: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default currencyReducer;
