import { useEffect, useRef, useState } from "react";
import { Button, Tabs, Input } from "antd";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { brandActionCreators } from "../../../redux/seller";
import { SearchOutlined } from "@ant-design/icons";
import SupplierLayout from "./assets/SupplierLayout";

const { TabPane } = Tabs;
const { Search } = Input;

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const AllSuppliers = () => {
  const [sortBrands, setSortedBrands] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const { fetchBrands, searchBrand } = bindActionCreators(
    brandActionCreators,
    dispatch
  );

  const { info } = useSelector((state) => state.user);
  const {
    allBrands: brands,
    searchResult,
    showResult,
  } = useSelector((state) => state.brands);

  const shopBrand = (brand) => {
    history.push(`/supplier/${brand.seller_id}/${brand.slug}`);
  };

  const brandDiv = (letter) => {
    const items = [];
    for (let [key, value] of Object.entries(sortBrands)) {
      if (key === letter) {
        items.push(
          <>
            {value.map((brand) => (
              <div
                className="brands-div"
                onClick={() => shopBrand(brand)}
                key={brand?.slug}
              >
                <p className="txt-designer-name">{brand?.store_name}</p>
              </div>
            ))}
          </>
        );
      }
    }
    return items;
  };

  const scrollToRef = (ref) => {
    return window.scrollTo({ top: ref?.offsetTop - 90, behavior: "smooth" });
  };
  const onSearch = async (value) => {
    searchBrand(info.token, { keyword: value });
  };

  const itemsRef = useRef([]);

  useEffect(() => {
    fetchBrands(info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sortedBrands = [];
    let firstSort = brands?.sort(function (a, b) {
      return a.store_name[0].localeCompare(b.store_name[0]);
    });
    for (let i = 0; i < firstSort?.length; i++) {
      let c = firstSort[i].store_name[0].toUpperCase();
      if (sortedBrands[c] && sortedBrands[c]?.length >= 0)
        sortedBrands[c].push(firstSort[i]);
      else {
        sortedBrands[c] = [];
        sortedBrands[c].push(firstSort[i]);
      }
    }

    setSortedBrands(sortedBrands);
  }, [brands]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, alphabet.length);
  }, []);

  // get other stores not in alphabet  
  const [otherBrands, setOtherBrands] = useState([])

  useEffect(()=>{
    const otherStores=[]
    for (let [key, value] of Object.entries(sortBrands)){
      if (!alphabet.includes(key)){
        otherStores.push(...value)
      }
    }
    setOtherBrands(otherStores)
  },[sortBrands])

  return (
    <SupplierLayout
      headerTitle="The FINANCE PORTal"
      headerSubtitle="Get insights on all finance metrics on Losode"
      currentPageKey="1"
    >
      <div className="brand-manage-container">
        <div>
          <div
            style={{ width: "64%", height: "48px", margin: "16px auto 24px" }}
          >
            <Search
              className="search"
              allowClear
              enterButton="Search"
              onSearch={onSearch}
              placeholder="Enter Supplier Name"
              prefix={<SearchOutlined />}
            />
            {showResult ? (
              searchResult.length > 0 ? (
                searchResult.map((brand) => (
                  <p
                    className="txt-search"
                    onClick={() =>
                      history.replace(`/${brand.slug}/info`, brand)
                    }
                  >
                    {brand.store_name}
                  </p>
                ))
              ) : (
                <p className="txt-search">No results found</p>
              )
            ) : (
              ""
            )}
          </div>
          <div className="top-container">
            <h2 className="txt-title">All Suppliers</h2>
            <Button
              className="brands-add-btn"
              onClick={() => history.push("/add-seller")}
            >
              Add A New Supplier
            </Button>
          </div>
          <div
            className="tabs-container"
            // onScroll={onScroll} ref={scrollRef}
          >
            <Tabs
              defaultActiveKey="0"
              className="tabs"
              tabBarGutter={8.5}
              onTabClick={(key) => scrollToRef(itemsRef.current[key])}
            >
              {alphabet.map((letter, index) => (
                <TabPane className="tab" tab={letter} key={index}></TabPane>
              ))}
              <TabPane className="tab" tab={"#"} key={"#"}></TabPane>
            </Tabs>
            <div className="all-letters-section-container">
              {alphabet.map((letter, index) =>
                sortBrands[letter] ? (
                  <div
                    className="letter-section"
                    key={letter}
                    ref={(el) => (itemsRef.current[index] = el)}
                  >
                    <h2 className="letter">{letter}</h2>
                    <div className="content">{brandDiv(letter)}</div>
                  </div>
                ) : (
                  ""
                )
              )}
              <div
                className="letter-section"
                key={"#"}
                ref={(el) => (itemsRef.current["#"] = el)}
              >
                <h2 className="letter">#</h2>
                <div className="content">
                  {otherBrands.map((brand) => (
                    <div
                      className="brands-div"
                      onClick={() => shopBrand(brand)}
                      key={brand?.slug}
                    >
                      <p className="txt-designer-name">{brand?.store_name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .brand-manage-container {
            font-family: "DomaineSansText-Light";
            padding: 24px 40px;
            background: white;
          }
          .top-container {
            display: flex;
            justify-content: space-between;
            margin: 24px 0 16px;
            align-items: center;
          }
          .txt-title {
            line-height: normal;
            margin-bottom: 60px;
            font-size: 24px;
            font-family: "DomaineSansText-Regular";
          }
          .searchBox {
            width: 200px;
            border: 0px;
            height: 48px;
          }
          .searchBox.ant-input-affix-wrapper > input.ant-input {
            color: #000;
          }
          .all-letters-section-container {
            background: white;
            padding: 16px;
          }
          .tabs-container {
            overflow: scroll;
            margin-bottom: 90px;
          }
          .tabs-container::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
          .tabs {
          }
          .ant-tabs-tab-btn {
            font-size: 16px;
            font-weight: 300;
          }
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #000;
            font-weight: 500;
          }
          .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
          .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
            background: #000;
            width: 50px;
            height: 5px;
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
            width: 99%;
            justify-content: space-between;
          }
          .ant-tabs-top > .ant-tabs-nav::before {
            border-bottom: 1px solid #000;
            left: -40px;
          }
          .letter-section {
            display: flex;
            gap: 40px;
            border-bottom: 1px solid #d9d9d9;
            margin: 10px 0;
            padding: 16px 0 40px;
          }
          .letter {
            margin: 0;
            font-size: 85px;
            font-family: "DomaineSansText-Regular";
            line-height: 57px;
            width: 88px;
            display: flex;
            justify-content: center;
          }
          .content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: flex-start;
          }
          .brands-div {
            cursor: pointer;
            font: normal normal 18px/23px DomaineSansText-Regular;
            display: flex;
            align-items: center;
            width: 32%;
            gap: 8px;
          }
          .brands-div:hover {
            color: #800000;
          }
          .txt-designer-name {
            margin: 0;
            font-size: 16px;
            font-family: "DomaineSansText-Light";
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
            height: auto;
            background: white;
          }
          .ant-tabs-top > .ant-tabs-nav::before {
            border: 0;
          }
          html {
            scroll-behavior: smooth;
          }

          .brands-add-btn {
            height: 48px;
            background: black;
            color: white;
            padding: 0 24px;
            font-family: "DomaineSansText-Light";
            text-transform: capitalize;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .txt-search {
            box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);
            color: #000;
            cursor: pointer;
            font: normal normal 14px/146.16% DomaineSansText-Regular;
            margin: 0px;
            margin-bottom: 10px;
            padding: 10px;
            transition: box-shadow 0.5s;
          }
          .txt-search:hover {
            box-shadow: 0px 3px 10px 5px rgb(0 0 0 / 0.2);
          }
          @media (max-width: 640px) {
            .tabs-container {
              display: block !important;
            }
            .letter-section {
              flex-direction: column;
              margin: 0;
              padding: 16px;
              gap: 8px;
              border: 0;
            }
            .letter {
              font-size: 45px;
              justify-content: flex-start;
            }
            .content {
              margin-bottom: 0 !important;
            }
            .txt-designer-name {
              font-size: 14px;
            }
            .brands-div {
              padding-bottom: 12px;
              padding-top: 12px;
              border-bottom: 1px solid #d9d9d9;
              width: 100% !important;
              flex-direction: row-reverse;
              justify-content: space-between;
            }
            .top-container {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 24px;
            }
            .searchBox {
              width: 90%;
              border: 1px solid #d9d9d9;
              margin: 24px auto 0;
              width: 90%;
              height: 40px;
            }
            .txt-title {
              border-bottom: 1px solid #d9d9d9;
              width: 100%;
              padding-bottom: 16px;
            }
          }
        `}</style>
      </div>
    </SupplierLayout>
  );
};

export default AllSuppliers;
