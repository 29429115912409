import { useEffect, useState } from "react";
import { Button } from "antd";
// import { useNavigate } from "react-router-dom";
// import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
// import OrderView from "./OrderView";
// import { useHistory } from "react-router-dom";
import { getLatestOrders } from "../../api/ordersApi";
import OrderView from "./assets/OrderViewBuyer";
import BuyerLayout from "./assets/BuyerLayout";
import BlockBuyer from "./assets/modals/BlockBuyer";
import { getBuyerStats } from "../../api/buyersApi";
import { months } from "../../assets/constants";
import FindBuyer from "./assets/modals/FindBuyer";

const BuyerDashboard = () => {
  const [blockBuyerModalVisible, setBlockBuyerModalVisible] = useState(false);
  const [findBuyerModalVisible, setFindBuyerModalVisible] = useState(false);

  const [newOrders, setNewOrders] = useState([]);
  const [stats, setStats] = useState({});

  const { buyer, order, flagged_order } = stats || {};

  const currentMonth = months[new Date().getMonth()];

  const { info } = useSelector((state) => state.user);

  const getNewOrders = async () =>
    setNewOrders(await getLatestOrders(info.token));

  const getStats = async () => {
    const res = await getBuyerStats(info.token);
    setStats(res?.dashboard_stat?.numbers);
  };

  useEffect(() => {
    getNewOrders();
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BuyerLayout
      headerTitle="The Customer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      currentPageKey="1"
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              <div className="dashboard-body-content-header">
                <div className="dashboard-body-content-header-container">
                  <div className="dashboard-body-content-header-title">
                    My Dashboard
                  </div>
                </div>
              </div>
              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div
                    className="dashboard-body-bold-numbers-container-contain"
                    style={{
                      marginBottom: "50px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginTop: "24px",
                    }}
                  >
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {buyer?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW CUSTOMERS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {buyer?.all}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          CUSTOMERS ON LOSODE
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {buyer?.monthGrowthRate}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div>
                    </div>

                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW ORDERS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.all}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          ORDERS ON LOSODE
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.monthgrowthRate}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {flagged_order?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          FLAGGED ORDERS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {flagged_order?.all}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          FLAGGED ORDERS ON LOSODE
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-body-quick-action-container">
                    <div style={{ padding: "16px", background: "white" }}>
                      <div className="dashboard-body-quick-action-title">
                        Quick Actions
                      </div>
                      <div className="dashboard-body-quick-action-button-container">
                        <Button
                          className="dashboard-body-quick-action-button"
                          onClick={() => setFindBuyerModalVisible(true)}
                        >
                          Find Customer
                        </Button>
                        {/* <Button className="dashboard-body-quick-action-button">
                          Buy For Customer
                        </Button> */}
                        <Button className="dashboard-body-quick-action-button">
                          Find Order
                        </Button>
                        {/* <Button
                          className="dashboard-body-quick-action-button"
                          onClick={() => setBlockBuyerModalVisible(true)}
                        >
                          Ban Customer
                        </Button> */}
                        <Button
                          className="dashboard-body-quick-action-button"
                          // onClick={() => navigate("/list-item")}
                        >
                          Flagged Orders
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-body-alert-box-container">
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {stats?.flaggedOrders} Flagged Orders.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          //   onClick={() => history.push("/activate-store")}
                        >
                          Click To View
                        </span>
                      </div>
                    </div>
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {stats?.newBoughtItem} New Items Bought In 30 Days.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          //   onClick={() => history.push("/manage-listings/new")}
                        >
                          Click To View New Orders
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-body-orders-section">
                    <div className="dashboard-body-orders-title">
                      500,000 New Orders
                    </div>
                    {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {newOrders?.length > 0 ? (
                        newOrders.map((order) => (
                          <OrderView
                            key={order.sale_id}
                            product={order}
                            type="Dashboard"
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>There are currently no new orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section">
                    <div className="dashboard-body-orders-title">
                      Flagged Orders
                    </div>
                    {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {newOrders?.length > 0 ? (
                        newOrders.map((order) => (
                          <OrderView
                            key={order.sale_id}
                            product={order}
                            type="Dashboard"
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>There are currently no new orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section">
                    <div className="dashboard-body-orders-title">
                      Return Requests
                    </div>
                    {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {newOrders?.length > 0 ? (
                        newOrders.map((order) => (
                          <OrderView
                            key={order.sale_id}
                            product={order}
                            type="Dashboard"
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>There are currently no new orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BlockBuyer
          blockBuyerModalVisible={blockBuyerModalVisible}
          setBlockBuyerModalVisible={setBlockBuyerModalVisible}
        />

        <FindBuyer
          findBuyerModalVisible={findBuyerModalVisible}
          setFindBuyerModalVisible={setFindBuyerModalVisible}
        />

        {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}

        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
            margin-top: 16px;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }

          .dashboard-body-content-header,
          .dashboard-body-bold-numbers-container-contain,
          .dashboard-body-quick-action-container,
          .dashboard-body-orders-section {
            padding: 24px;
            background: white;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 16px;
            width: 100%;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
            text-transform: uppercase;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 15px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
          }
          .dashboard-body-alert-box-action {
            cursor: pointer;
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 23%;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }
          .dashboard-body-quick-action-container {
            padding: 16px;
            background: #f7f7f7;
            margin-bottom: 50px;
          }

          @media (max-width: 640px) {
            .dashboard-coverall-container {
              margin-left: 0;
            }
            .dashboard-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }

            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }
            .dashboard-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .dashboard-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .dashboard-body-content-header-title {
              font-size: 18px;
            }
            .dashboard-body-quick-action-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              margin-top: auto;
            }
            .dashboard-body-bold-numbers-item {
              width: auto;
              justify-content: center;
              padding: 16px;
              text-align: center;
            }

            .dashboard-body-alert-box {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-community-container {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-events-subtitle {
              margin-bottom: 16px;
              width: 100%;
              font-size: 14px;
            }
            .dashboard-body-orders-section {
              margin-bottom: 24px;
            }
            .dashboard-partners-image {
              width: auto;
              height: 120px;
            }
            .dashboard-body-partners-images-container {
              gap: 8px;
            }
            .dashboard-store-link-section {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-events-image {
              width: auto;
              height: 300px;
            }
            .process-icon-container {
              padding: 8px;
              border-radius: 50%;
              border: 1px solid #800000;
              width: 32px;
              height: 32px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .process-icon-div-mobile {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 24px;
              margin: 16px 0;
            }
            .process-icon {
              color: #800000;
            }
            .dashboard-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .dashboard-body-bold-numbers-item-earned {
              width: 100%;
            }
            .dashboard-body-bold-numbers-item-listed,
            .dashboard-body-bold-numbers-item-sold {
              flex: 2;
            }
          }
        `}</style>
      </div>
    </BuyerLayout>
  );
};

export default BuyerDashboard;
