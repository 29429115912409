import { getAllPrices as getAllPricesApi, getMenu as getMenuApi } from "../../api/logisticsAPI";

import { FETCH_PRICING_SUCEESS, FETCH_PRICING_FAIL, FETCH_MENU_SUCCESS, FETCH_MENU_FAIL } from "./types";

export const fetchPrices = (token, id) => {
  return async (dispatch) => {
    try {
      const pricing = await getAllPricesApi(token, id);
      dispatch({
        type: FETCH_PRICING_SUCEESS,
        payload: pricing,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRICING_FAIL,
        payload: err,
      });
    }
  };
};

export const fetchLogisticsMenu = (token) => {
  return async (dispatch) => {
    try {
      const logisticsMenu = await getMenuApi(token);
      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: logisticsMenu,
      });
    } catch (err) {
      dispatch({
        type: FETCH_MENU_FAIL,
        payload: err,
      });
    }
  };
};


