import { useState } from "react";
import { Button, Row, Col, Form, Input, Select } from "antd";
import SupplierLayout from "./assets/SupplierLayout";
import { useEffect } from "react";

const { Option } = Select;

const AddSupplier = () => {
  const [form] = Form.useForm();
  const [bankForm] = Form.useForm();

  const [goNextForm, setGoNextForm] = useState(false);
  const [countryValue, setCountry] = useState("Nigeria");

  useEffect(() => {
    setGoNextForm(false);
  }, []);
  return (
    <>
      <SupplierLayout
        headerTitle="The FINANCE PORTAL"
        headerSubtitle="Get insights on all finance metrics on Losode"
        dashboardBgColor="#fff0"
      >
        <div
          className="manage-payment-container"
          style={{ marginBottom: "32px" }}
        >
          <div>
            <h2 className="txt-title">Add New Supplier</h2>
          </div>
        </div>
        {!goNextForm ? (
          <div
            className="manage-payment-container"
            style={{ marginBottom: "32px" }}
          >
            <div className="manage-listing-all-content">
              <div className="manage-payment-coverall-container">
                <div className="manage-payment-body-content-header">
                  <div className="manage-payment-body-content-header-container">
                    <h2 className="txt-title">Supplier Information</h2>
                  </div>
                  <span className="line-span" />
                </div>
                <div className="manage-payment-body-container">
                  <div className="manage-payment-body-content">
                    <Form
                      className="form"
                      form={form}
                      layout="vertical"
                      name="register"
                      //   onFinish={onSubmit}
                      requiredMark={false}
                      scrollToFirstError
                    >
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item
                            name="fname"
                            label="Company Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter company name!",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter company name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="lname"
                            label="Contact Person"
                            rules={[
                              {
                                required: true,
                                message: "Please enter contact person!",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter contact person"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item name="dob" label="Supplier Id">
                            <Input
                              className="input"
                              disabled
                              placeholder="#213456433"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="email"
                            label="Email Address"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please enter email address",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter email address"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item
                            name="email"
                            label="Phone Number"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please enter phone number",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter phone number"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: "Please enter supplier address",
                              },
                              {
                                max:45,
                                message:"Please limit address to 45 characters or less"
                              }
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter Address"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Please select country!",
                              },
                            ]}
                          >
                            <Select
                              className="select"
                              style={{ width: "100%" }}
                              placeholder="Select Country"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => setCountry(value)}
                            >
                              <Option value="Nigeria">Nigeria</Option>
                              <Option value="Ghana">Ghana</Option>
                              <Option value="USA">USA</Option>
                              <Option value="UK">UK</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="state"
                            label="State"
                            rules={[
                              {
                                required: true,
                                message: "Please enter state!",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter State"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item
                            name="tin"
                            label="Tax Identification Number (TIN)"
                            rules={[
                              {
                                required: true,
                                message: "Please enter supplier TIN",
                              },
                            ]}
                          >
                            <Input className="input" placeholder="Enter TIN" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="rc_number"
                            label="RC Number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter RC Number!",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter RC Number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={80}>
                        <Col span={12}>
                          <Form.Item
                            name="currency"
                            label="Currency"
                            rules={[
                              {
                                required: true,
                                message: "Please select currency!",
                              },
                            ]}
                          >
                            <Select
                              className="select"
                              style={{ width: "100%" }}
                              placeholder="Select Currency"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="naira">Naira</Option>
                              <Option value="dollar">Dollar</Option>
                              <Option value="pounds">Pounds</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label>
                            <Button
                              className="btn-continue"
                              type="primary"
                              htmlType="submit"
                            >
                              Continue
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="manage-payment-container">
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <h2 className="txt-title">Payment Information</h2>
              </div>
              <span className="line-span" />
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <Form
                  form={bankForm}
                  name="bankForm"
                  // onFinish={onSubmitBankDetails}
                  scrollToFirstError
                  layout="vertical"
                  requiredMark={false}
                >
                  {countryValue === "Nigeria" ? (
                    <Row className="form-row" gutter={[48, 12]}>
                      <Col span={12}>
                        <Form.Item
                          name="bankName"
                          label="Bank Name"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Select
                            className="select"
                            placeholder="Select your Bank"
                          >
                            {/* {banks?.map((bank, idx) => (
                        <Option value={bank.name} key={idx}>
                          {bank.name}
                        </Option>
                      ))} */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="accountName"
                          label="Account Name "
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your account name"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="accountNumber"
                          label="Account Number"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your account number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label>
                          <Button
                            className="btn-continue"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="form-row" gutter={[48, 12]}>
                      <Col span={12}>
                        <Form.Item
                          name="bankName"
                          label="Bank Name"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your bank name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="accountName"
                          label="Account Name "
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your account name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="sortCode"
                          label="Sort Code"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your sort code"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="accountNumber"
                          label="Account Number"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field!",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your account number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item style={{ float: "right", width: "47.5%" }}>
                          <Button
                            className="btn-continue"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form>
              </div>
            </div>
          </div>
        )}
      </SupplierLayout>
      <style jsx="true">{`
.manage-payment-container {
  font-family: DomaineSansText-Light;
  color: black;
  padding: 24px 40px;
  background: white;
  width: 90%;
}
.manage-payment-coverall-container {
}
.manage-listing-all-content {
  position: relative;
}

.txt-title {
  line-height: normal;
  margin: 0 auto;
  font-size: 20px;
  font-family: "DomaineSansText-Regular";
}

.line-span {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
  margin-top: 18px;
  margin-bottom:40px ;
}

.manage-payment-body-container {
      padding:20px;
}

.manage-payment-body-content-header-title {
  font-size: 18px;
  font-weight: bold;
}
.input,
.ant-picker {
border: 1px solid #707070;
height: 48px;
width: 100%;
}
.input:hover,
.ant-picker:hover {
border: 1px solid #707070;
}
.ant-input-affix-wrapper > input.ant-input {
color: #000;
}
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
border: 1px solid #707070;
height: 48px;
padding: 0px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow
.ant-select-selection-placeholder {
display: flex;
padding: 10px;
}
.ant-select-selector:hover {
border: 1px solid #707070;
}
.ant-input:placeholder-shown {
font: normal 700 13px/146.16% DomaineSansText-Light;
color: #677189;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.btn-continue {
background: #000000;
border: 1px solid #000;
color: #ffffff;
font-size: 18px;
height: 48px;
width: 100%;
}
.btn-continue:hover {
background: #fff;
border: 1px solid #000;
color: #000;
}

}
`}</style>
    </>
  );
};

export default AddSupplier;
