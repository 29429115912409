import axios from "axios";

export const fetchCurrency = async () => {
  try {
    const result = await axios.get("country/currencies");
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchRates = async () => {
  try {
    const result = await axios.get("new/currency/rates");
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
