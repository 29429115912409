import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { brandActionCreators } from "../../redux/seller";
import { bindActionCreators } from "redux";
import { Button, Form, Input, Row, Col, Select, message } from "antd";
import axios from "axios";
import getLatLng from "../../utils/getLatLng";
import Dashboard from "./Dashboard";

const { Option } = Select;

const AddBrand = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);

  const { createStore } = bindActionCreators(brandActionCreators, dispatch);

  const user = useSelector((state) => state.user);
  const seller = useSelector((state) => state.brands.seller);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name);
    uploadImage(fileUploaded);
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };

  const onFinish = async () => {
    const { storeAddress, storeCity, storeCountry } = form.getFieldsValue();
    const values = {
      address: storeAddress,
      state: storeCity,
      country: storeCountry,
    };
    const latLng = await getLatLng(values);
    const latitude = latLng.split(",")[0];
    const longitude = latLng.split(",")[1];
    const data = {
      ...form.getFieldsValue(),
      logoUrl: imageUrl,
      latitude,
      longitude,
      seller_id: seller.seller_id,
      from_admin: 1,
    };

    const res = await createStore(user.info.token, data);
    form.resetFields();
    setFileName("");
    if (res.status === "ok") {
      message.success(res.message);
      setTimeout(() => {
        history.push("/brands");
      }, 2000);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      logoUrl: fileName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return (
    <Dashboard>
      <div className="new-seller-container">
        <div className="div-back" onClick={() => history.push("/brands")}>
          <img src="/images/arrow-back.svg" alt="back" />
          <span className="txt-back">Back to Brands</span>
        </div>
        <h2 className="page-title">Add Brand</h2>
        <p className="txt-desc">Add a new brand to losode</p>
        <Form
          className="form"
          form={form}
          layout="vertical"
          name="register"
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
        >
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="storeName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the business name!",
                  },
                ]}
              >
                <Input className="input" placeholder="Business Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="logoUrl"
                rules={[
                  {
                    required: true,
                    message: "Select company logo",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Add your business logo"
                  onChange={uploadImage}
                  suffix={
                    <>
                      <Button className="btn-upload" onClick={handleClick}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "end",
                          }}
                        >
                          <img alt="upload" src="/images/upload.png" />
                          <span style={{ fontSize: 18 }}>Browse</span>
                        </div>
                      </Button>
                      <input
                        accept="image/*"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number!",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Phone Number"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please enter email address",
                  },
                ]}
              >
                <Input className="input" placeholder="Email Address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item name="regNumber">
                <Input
                  className="input"
                  placeholder="Business Registration Number (optional)"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="storeCountry"
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select
                  className="select"
                  placeholder="Business Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Nigeria">Nigeria</Option>
                  <Option value="USA">USA</Option>
                  <Option value="UK">UK</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="storeAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter business address!",
                  },
                  {
                    max:45,
                    message:"Please limit your address to 45 characters or less"
                  }
                ]}
              >
                <Input className="input" placeholder="Business Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Please enter nearest landmark!",
                  },
                ]}
              >
                <Input className="input" placeholder="Nearest Landmark" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your store description",
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 6, maxRows: 8 }}
                className="input"
                placeholder="Store Description"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button className="btn-submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <style jsx="true">{`
        .div-back {
          cursor: pointer;
        }
        .txt-back {
          font: normal normal 16px/19px DomaineSansText-Regular;
          color: #545f7d;
          margin-left: 14.41px;
        }
        .page-title {
          font: normal bold 24px/28px DomaineSansText-Regular;
          color: #800000;
          margin-top: 26px;
        }
        .txt-desc {
          font: normal normal 14px/18px DomaineSansText-Regular;
          color: #727376;
        }
        .form {
          margin-top: 28.52px;
        }
        .txt-add {
          font: normal normal 30px/39px DomaineSansText-Light;
          margin: 0px;
        }
        .txt-desc {
          color: #707070;
          font: normal normal 18px/23px DomaineSansText-Light;
          margin: 0px;
        }
        .input,
        .ant-picker {
          border: 1px solid #707070;
          height: 63px;
          padding: 4px 0px 4px 11px;
          width: 100%;
        }
        .input:hover,
        .ant-picker:hover {
          border: 1px solid #707070;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          height: 63px;
          padding: 0px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 18px;
        }
        .ant-select-selector:hover {
          border: 1px solid #707070;
        }
        .ant-input:placeholder-shown {
          font: normal 700 13px/146.16% DomaineSansText-Light;
          color: #677189;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .btn-upload,
        .btn-upload:hover {
          background: #800000;
          color: #fff;
          font: normal normal 18px/23px DomaineSansText-Light;
          height: 61px;
          width: 160px;
        }

        .btn-submit {
          background: #800000;
          border: 1px solid #000;
          color: #ffffff;
          float: right;
          font-size: 18px;
          height: 63px;
          width: 165px;
        }
        .btn-submit:hover {
          background: #fff;
          border: 1px solid #000;
          color: #000;
        }
      `}</style>
    </Dashboard>
  );
};

export default AddBrand;
