import {
  GET_BRANDS,
  GET_BRAND,
  UPDATE_STORE_DETAILS,
  ACTIVATE_BRAND,
  DEACTIVATE_BRAND,
  UPDATE_STORE_FAILED,
  CREATE_PRODUCT_FAILED,
  ACTIVATE_BRAND_FAILED,
  DEACTIVATE_BRAND_FAILED,
  GET_BRAND_PRODUCTS,
  GET_APPROVED_PRODUCTS,
  GET_NEW_PRODUCTS,
  GET_BRAND_PRODUCTS_FAILED,
  GET_APPROVED_PRODUCTS_FAILED,
  GET_NEW_PRODUCTS_FAILED,
  GET_PRODUCT,
  GET_PRODUCT_FAILED,
  REGISTER_SELLER_SUCCESS,
  REGISTER_SELLER_FAIL,
  SEARCH_BRAND,
  SEARCH_BRAND_DONE,
  GET_BRAND_DETAILS,
  GET_BANKS,
  GET_CATEGORIES,
  GET_ALL_PRODUCTS,
  GET_PENDING_BRANDS,
  GET_BLOCKED_BRANDS,
  GET_ACTIVATED_BRANDS,
  FETCH_BRAND_APPLICATIONS,
  FETCH_BRAND_APPLICATIONS_FAILED,
  FETCH_BRAND_APPLICATION_DETAILS,
  FETCH_BRAND_APPLICATIONS_DETAILS_FAILED,
  UPDATE_NEW_SELLER_INFO_FAILED,
  GET_PHOTOSHOOTS,
  GET_PHOTOSHOOT_DETAILS,
} from "./type";

const initialState = {
  error: false,
  message: "",
  allBrands: [],
  activatedBrands: [],
  pendingBrands: [],
  blockedBrands: [],
  allListings: [],
  banks: [],
  brand: {},
  categories: [],
  brandDetails: {},
  brandProducts: [],
  brandApprovedProducts: [],
  brandNewProducts: [],
  brandSponsoredItems: [],
  product: {},
  seller: {},
  showResult: false,
  searchResult: {},
  brandApplications: [],
  brandApplicationDetails: [],
  photoshoots: [],
  photoshootDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SELLER_SUCCESS: {
      return { ...state, error: action.error, seller: action.payload };
    }
    case REGISTER_SELLER_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.payload,
      };
    }
    case GET_BRANDS: {
      return { ...state, allBrands: action.payload };
    }
    case GET_PENDING_BRANDS: {
      return { ...state, pendingBrands: action.payload };
    }
    case GET_ACTIVATED_BRANDS: {
      return { ...state, activatedBrands: action.payload };
    }
    case GET_BLOCKED_BRANDS: {
      return { ...state, blockedBrands: action.payload };
    }
    case GET_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    case GET_ALL_PRODUCTS: {
      return { ...state, allListings: action.payload };
    }
    case GET_BRAND: {
      return { ...state, brand: action.payload };
    }
    case GET_BANKS: {
      return { ...state, banks: action.payload };
    }
    case GET_BRAND_DETAILS: {
      return { ...state, brandDetails: action.payload };
    }
    case SEARCH_BRAND: {
      return { ...state, searchResult: action.payload, showResult: true };
    }
    case SEARCH_BRAND_DONE: {
      return { ...state, showResult: false };
    }
    case GET_BRAND_PRODUCTS: {
      return { ...state, error: action.error, brandProducts: action.payload };
    }
    case GET_APPROVED_PRODUCTS: {
      return {
        ...state,
        error: action.error,
        brandApprovedProducts: action.payload,
      };
    }
    case GET_NEW_PRODUCTS: {
      return {
        ...state,
        error: action.error,
        brandNewProducts: action.payload,
      };
    }
    case GET_PRODUCT: {
      return {
        ...state,
        error: action.error,
        product: action.payload,
      };
    }
    case UPDATE_STORE_DETAILS: {
      return { ...state, error: action.error, brand: action.payload };
    }
    case ACTIVATE_BRAND: {
      return { ...state, error: action.error, message: action.payload };
    }
    case DEACTIVATE_BRAND: {
      return { ...state, error: action.error, message: action.payload };
    }
    case UPDATE_STORE_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case CREATE_PRODUCT_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case ACTIVATE_BRAND_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case DEACTIVATE_BRAND_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case GET_BRAND_PRODUCTS_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case GET_APPROVED_PRODUCTS_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case GET_NEW_PRODUCTS_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case GET_PRODUCT_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case FETCH_BRAND_APPLICATIONS: {
      return { ...state, brandApplications: action.payload };
    }
    case FETCH_BRAND_APPLICATIONS_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case FETCH_BRAND_APPLICATION_DETAILS: {
      return { ...state, brandApplicationDetails: action.payload };
    }
    case FETCH_BRAND_APPLICATIONS_DETAILS_FAILED: {
      return { ...state, error: action.error, message: action.payload };
    }
    case UPDATE_NEW_SELLER_INFO_FAILED:
      return { ...state, error: action.error, message: action.payload };
    case GET_PHOTOSHOOTS:
      return { ...state, error: action.error, photoshoots: action.payload };
    case GET_PHOTOSHOOT_DETAILS:
      return { ...state, error: action.error, photoshootDetails: action.payload };
    default:
      return state;
  }
};

export default userReducer;
