import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
import { Button, Dropdown, Menu, Pagination, Table } from "antd";
import { useHistory } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import Dashboard from "./Dashboard";
import { isEmptyObject } from "../../utils/isEmptyObject";

const Products = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { fetchBrandProducts } = bindActionCreators(
    brandActionCreators,
    dispatch
  );
  const user = useSelector((state) => state.user);
  const { brand, brandProducts: products } = useSelector(
    (state) => state.brands
  );

  const productColumn = [
    {
      ellipsis: true,
      title: "PRODUCT NAME",
      dataIndex: "name",
      width: "30%",
    },
    {
      align: "center",
      title: "QUANTITY",
      dataIndex: "quantity",
      width: "20%",
    },
    {
      align: "center",
      title: "DATE AND TIME",
      dataIndex: "created_at",
      width: "20%",
    },
    {
      align: "center",
      title: "PRODUCT ID",
      dataIndex: "id",
      width: "20%",
    },
    {
      align: "center",
      title: "ACTIONS",
      width: "20%",
      render: (id) => {
        return <p>Delete...</p>;
      },
    },
  ];

  const handleMenuClick = (e) => {};

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">100</Menu.Item>
      <Menu.Item key="2">200</Menu.Item>
      <Menu.Item key="3">300</Menu.Item>
      <Menu.Item key="4">400</Menu.Item>
      <Menu.Item key="5">500</Menu.Item>
    </Menu>
  );

  const onChange = (page) => {
    // fetchBrandsPaginated(user.info.token, page);
  };

  useEffect(() => {
    fetchBrandProducts(user.info.token, brand.seller_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard>
      <div className="products-container">
        <div className="div-back" onClick={() => history.goBack()}>
          <img src="/images/arrow-back.svg" alt="back" />
          <span className="txt-back">Back to Sellers Information</span>
        </div>
        <h2 className="page-title">Products</h2>
        <div className="box">
          <Table
            className="table"
            columns={productColumn}
            dataSource={products.data}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`/products/${record.slug}`, record);
                },
              };
            }}
            size="medium"
            style={{ cursor: "pointer" }}
            pagination={false}
          />
        </div>
        {!isEmptyObject(products) && (
          <div className="pagination-container">
            <div className="rows-box">
              <p className="num-text">
                Showing{" "}
                <Dropdown overlay={menu}>
                  <Button className="btn-num">
                    {products.meta_data.per_page} <DownOutlined />
                  </Button>
                </Dropdown>{" "}
                out of {products.meta_data.total}
              </p>
            </div>
            <Pagination
              onChange={onChange}
              showSizeChanger={false}
              pageSize={products.meta_data.per_page}
              total={products.meta_data.total}
            />
          </div>
        )}
      </div>
      <style jsx="true">{`
        .div-back {
          cursor: pointer;
        }
        .txt-back {
          font: normal normal 16px/19px DomaineSansText-Regular;
          color: #545f7d;
          margin-left: 14.41px;
        }
        .page-title {
          font: normal bold 24px/28px DomaineSansText-Regular;
          color: #800000;
          margin-top: 26px;
        }
        .ant-table-tbody > tr > td {
          font-family: DomaineSansText-Regular;
        }
        .box {
          background: #ffffff;
          border: 1px solid rgba(33, 63, 125, 0.06);
          box-sizing: border-box;
          box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          margin-top: 42px;
          padding: 40px 30px;
        }
        .pagination-container {
          display: flex;
          justify-content: space-between;
          margin-top: 52px;
        }
        .num-text {
          font: normal normal 14px/16px DomaineSansText-Regular;
          color: #000000;
        }
        .btn-num {
          background: rgba(0, 0, 0, 0.1);
          height: 30px;
          width: 80px;
        }
      `}</style>
    </Dashboard>
  );
};

export default Products;
