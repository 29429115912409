import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";
import { ShoppingFilled, LogoutOutlined } from "@ant-design/icons";
//import { createFromIconfontCN } from "@ant-design/icons";

import { logout } from "../../redux/user/user.actions";
import Header from "../../components/Header";

// const IconFont = createFromIconfontCN({
//   scriptUrl: ["//at.alicdn.com/t/font_2591839_3mfv4po1652.js"],
// });

const { Content, Sider } = Layout;

const Dashboard = ({ logout, children }) => {
  const history = useHistory();
  return (
    <div>
      <Layout>
        <Sider className="sider">
          <div className="logo-div">
            <img
              className="logo"
              src="/images/logo-white.png"
              alt="logo"
              onClick={() => history.push("/")}
            />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            {/* <Menu.Item
              className="menu-item"
              key="1"
              icon={<AppstoreFilled style={{ fontSize: 18 }} />}
            >
              Dashboard
            </Menu.Item> */}
            <Menu.Item
              className="menu-item"
              key="1"
              icon={<ShoppingFilled style={{ fontSize: 18 }} />}
            >
              Manage Brands
            </Menu.Item>

            <Menu.Item
              className="menu-item"
              key="12"
              icon={<LogoutOutlined style={{ fontSize: 20 }} />}
              onClick={logout}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 250 }}>
          <Header top={20} />
          <Content>
            <div className="site-layout-background" style={{ padding: 50 }}>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>

      <style jsx="true">{`
        .sider {
          background: #000;
          overflow: auto;
          height: 100vh;
          position: fixed;
          max-width: 300px !important;
          width: 255px !important;
          left: 0;
        }
        .menu-item {
          color: #fff !important;
          font-size: 15px !important;
        }
        .ant-menu-dark.ant-menu-inline .ant-menu-item,
        .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
          height: 56px;
        }
        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
          background: #000;
          color: #fff;
        }
        .ant-menu.ant-menu-dark .sub-item {
          background: #252525;
          color: #fff;
          margin: 1px;
        }
        .ant-menu-dark .ant-menu-item,
        .ant-menu-dark .ant-menu-item-group-title,
        .ant-menu-dark .ant-menu-item > a,
        .ant-menu-dark .ant-menu-item > span > a {
          font: normal normal 15px DomaineSansText-Regular;
          letter-spacing: 0.2px;
        }
        .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
          .ant-menu-item-selected {
          background: #ececee;
          color: #000;
          height: 56px;
          padding: 16px;
        }
        .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
        .ant-menu-dark .ant-menu-item-selected .anticon + span {
          color: #000;
          font: normal normal 15px DomaineSansText-Regular;
          letter-spacing: 0.2px;
        }
        .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
        .ant-menu-dark .ant-menu-item-selected .anticon {
          color: #000 !important;
        }
        .ant-menu-dark.ant-menu-inline .ant-menu-item,
        .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
          padding: 16px;
        }
        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow {
          top: 70%;
        }
        .logo-div {
          margin: 35px;
        }
        .logo {
          cursor: pointer;
          width: 150px;
        }
        .site-layout .site-layout-background {
          background: #fff;
        }
      `}</style>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
