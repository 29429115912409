import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import { logout } from "../../../../redux/user/user.actions";
import SupplierSideNav from "./LogisticsSideNav";
import HeaderComponent from "../../../../components/Header";
const { Content } = Layout;

const LogisticsLayout = ({
  currentPageKey,
  children,
  headerTitle,
  headerSubtitle,
  dashboardBgColor,
  noSideNav,
}) => {
  // const history = useHistory();

  return (
    <div className="seller-layout-dashboard">
      <HeaderComponent headerTitle={headerTitle} headerSubtitle={headerSubtitle} />
      <Layout>
        <div style={{ display: noSideNav ? "none" : "block" }}>
          <SupplierSideNav currentPageKey={currentPageKey} />
        </div>

        <Layout
          className="site-layout"
          style={{ marginLeft: noSideNav ? "0px" : "200px", marginTop: "60px" }}
        >
          {/* <Header search={true} right={50} top={20} /> */}

          <Content>
            <div
              className="site-layout-background"
              // style={{ padding: "24px 40px" }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>

      <style jsx="true">{`
        .logo-div {
          margin: 35px;
        }
        .logo {
          width: 150px;
        }
        .site-layout .site-layout-background {
          background: ${dashboardBgColor || "#fff"}; 
          min-height: calc(100vh - 66px);
        }
        .seller-layout-dashboard .ant-layout-header {
          line-height: normal;
        }
        .ant-layout-content{
          margin:0 24px 24px
        }
        .ant-layout{
          background:#f7f7f7
        }
      `}</style>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, matchDispatchToProps)(LogisticsLayout);
