import { useEffect} from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { brandActionCreators } from "../../redux/seller";
import Dashboard from "../seller-mgmt/Dashboard";


const WaitlistSellerInfo = () => {
  const history = useHistory();

  const { id } = useParams();


  const dispatch = useDispatch();

  const { fetchBrandApplicationDetails} =
    bindActionCreators(brandActionCreators, dispatch);
  const { info } = useSelector((state) => state.user);
  const { brandApplicationDetails } = useSelector((state) => state.brands);

  useEffect(() => {
    fetchBrandApplicationDetails(info?.token, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);



  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div style={{ padding: "36px 0px 8px" }}>
                  <div
                    style={{
                      marginBottom: "16px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => history.goBack()}
                  >
                    <svg
                      width="28"
                      height="10"
                      viewBox="0 0 28 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                        fill="black"
                      />
                    </svg>

                    <span
                      style={{
                        fontFamily: "DomaineSansText-Light",
                        marginLeft: "8px",
                      }}
                    >
                      Back
                    </span>
                  </div>
                </div>
                <div
                  className="manage-payment-body-content-section"
                  style={{ position: "relative" }}
                >
                  <div className="preview-main-content">
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "DomaineSansText-Regular",
                        textTransform: "capitalize",
                      }}
                    >
                      Applicant's Information
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        First Name
                      </div>
                      <div className="preview-main-content-row-data">
                        {brandApplicationDetails?.firstname}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Last Name
                      </div>
                      <div className="preview-main-content-row-data">
                        {brandApplicationDetails?.lastname}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Email Address
                      </div>
                      <div className="preview-main-content-row-data">
                        {brandApplicationDetails?.brand_email}
                      </div>
                    </div>

                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Phone Number
                      </div>
                      <div className="preview-main-content-row-data">
                        {brandApplicationDetails?.brand_phone_dial}
                        {brandApplicationDetails?.brand_phone}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Brand Categories
                      </div>
                      <div className="preview-main-content-row-data">
                        {brandApplicationDetails?.brand_what_i_sell}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: -32px;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-adspace {
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            margin-bottom: 40px;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            background: white;
            padding: 24px;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-family: "DomaineSansText-Regular";
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .confirm-listing-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 180px;
            margin-left: 20px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          . .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }

          .manage-payment-body-content-section {
            // margin-top: 24px;
            background: white;
            padding: 24px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            background-size: cover;
            border-radius: 50%;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }

          .images-section {
            display: flex;
            gap: 16px;
          }
          .images-thumbnail-container {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .images-thumbnail-container div {
            width: 140px;
            height: 140px;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .image-large {
            width: 250px;
            height: 250px;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
          }
          .txt-product-status {
            text-transform: capitalize;
          }
          .product-image-tag-label {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            background: #0000004d;
            font-size: 28px;
            color: white;
          }
          .product-main-container {
            display: flex;
            gap: 16px;
          }
          .product-content-section {
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
          .product-color-section {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .product-color-section-box {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
          .product-designer {
            font-family: "DomaineSansText-Regular";
            font-size: 18px;
            font-weight: bold;
          }
          .product-extra-container {
            display: flex;
            margin-top: 40px;
            border-top: 1px solid #d4d4d4;
            padding-top: 40px;
            gap: 4px;
          }
          .product-extra {
            width: 24%;
          }
          .product-extra-divider {
            border-left: 1px solid #d4d4d4;
            margin: 0 8px;
          }
          .product-extra-title {
            font-size: 16px;
            font-family: "DomaineSansText-Regular";
            margin-bottom: 8px;
          }
          .product-extra-details {
            font-size: 12px;
            margin-bottom: 8px;
          }
          .store-preview-status {
            position: absolute;
            right: 24px;
            top: 40px;
            font-size: 16px;
            font-family: "DomaineSansText-Light";
            padding: 16px 32px;
            border: 0;
          }
          .preview-main-content-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #0000007a;
            padding-bottom: 16px;
            align-items: center;
          }
          .preview-main-content {
            margin: 16px 0 0;
          }
          .preview-main-content-row {
            padding: 14px 0;
            display: grid;
            grid-template-columns: 30% 1fr;
            grid-gap: 24px;
          }
          .preview-main-content-row-data {
            font-family: "DomaineSansText-Regular";
            width: 90%;
          }
          .preview-main-content-row-key {
            color: #6f6f6f;
          }

          .txt-type {
            text-transform: capitalize;
          }
          .preview-button-black {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 30%;
          }
          .preview-button-white {
            height: 48px;
            color: black;
            background: white;
            font-size: 16px;
            width: 30%;
          }
          .preview-button-red {
            height: 48px;
            color: white;
            background: #800000;
            font-size: 16px;
            width: 30%;
          }

          .color-container {
            padding: 8px;
            border: 1px solid black;
            width: 120px;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            white-space: nowrap;
            margin: 0 auto;
          }
          .color-hex-box {
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          .table-quantity-container .ant-input[disabled] {
            color: #000;
            background-color: #fff;
          }

          .rejectListingModal {
            font-family: "DomaineSansText-Light";
          }
          .rejectListingModal.ant-modal-body {
            padding: 40px 40px 60px;
            color: black;
          }
          .reject-btn-save {
            background: #000000;
            border-color: #000;
            color: #fff;
            height: 48px;
            width: 100%;
          }
          .reject-btn-cancel {
            color: #000000;
            border-color: #000;
            background: #fff;
            height: 48px;
            width: 100%;
          }
          .input {
            height: 48px;
          }
          .ant-form-item-explain-error {
            margin-top: 4px;
          }

          .confirmModal .ant-modal-body {
            background: black;
            color: white;
            font-family: "DomaineSansText-Light";
            text-align: center;
            padding: 60px 40px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default WaitlistSellerInfo;
