import axios from "axios";

export const getOrderStats = async (token) => {
  try {
    const result = await axios.get("admin/order/count", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllOrders = async (token) => {
  try {
    const result = await axios.get("admin/all/orders", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPage = async (token, url) => {
  try {
    const result = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getLatestOrders = async (token) => {
  try {
    const result = await axios.get("admin/new/orders", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getOneOrder = async (token, saleId) => {
  try {
    const result = await axios.get(`admin/order/info/${saleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPendingOrders = async (token) => {
  const result = await axios.get("admin/pending/orders", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const getFlaggedOrders = async (token) => {
  const result = await axios.get("admin/flagged/orders", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const getNewOrders = async (token) => {
  const result = await axios.get("admin/new/orders", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const getSellerPendingOrders = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/pending/order/${sellerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getApprovedOrders = async (token) => {
  const result = await axios.get("admin/approved/order", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const getCanceledOrders = async (token) => {
  const result = await axios.get("admin/canceled/order", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const markOrderSent = async (token, value) => {
  const result = await axios.post("admin/mark/order/sent", value, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const declineOrder = async (token, values) => {
  try {
    const result = await axios.post("admin/mark/order/rejected", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
