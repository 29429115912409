const weights = (cat, gender) => {
    if (cat === "Clothing") {
      return [
        { id: 1, KG: 0.1 },
        { id: 2, KG: 0.15 },
        { id: 3, KG: 0.2 },
        { id: 4, KG: 0.3 },
        { id: 5, KG: 0.4 },
        { id: 6, KG: 0.5 },
        { id: 7, KG: 0.6 },
        { id: 8, KG: 0.7 },
        { id: 9, KG: 0.8 },
        { id: 10, KG: 0.9 },
        { id: 11, KG: 1 },
        { id: 12, KG: 1.5 },
        { id: 13, KG: 2 },
        { id: 14, KG: 2.5 },
        { id: 15, KG: 3 },
        { id: 16, KG: 3.5 },
        { id: 17, KG: 4 },
      ];
    }
  
    if (cat === "Accessories") {
      return [
        { id: 1, KG: 0.001 },
        { id: 2, KG: 0.002 },
        { id: 3, KG: 0.003 },
        { id: 4, KG: 0.004 },
        { id: 5, KG: 0.005 },
        { id: 6, KG: 0.006 },
        { id: 7, KG: 0.007 },
        { id: 8, KG: 0.008 },
        { id: 9, KG: 0.009 },
        { id: 10, KG: 0.1 },
        { id: 11, KG: 0.2 },
        { id: 12, KG: 0.3 },
        { id: 13, KG: 0.4 },
        { id: 14, KG: 0.5 },
        { id: 15, KG: 0.6 },
        { id: 16, KG: 0.7 },
        { id: 17, KG: 0.8 },
        { id: 18, KG: 0.9 },
        { id: 19, KG: 1 },
      ];
    }
  
    if (cat === "Footwear" && gender === "women-footwear") {
      return [
        { id: 1, KG: 0.05 },
        { id: 2, KG: 0.25 },
        { id: 3, KG: 0.35 },
        { id: 4, KG: 0.45 },
        { id: 5, KG: 0.55 },
        { id: 6, KG: 0.65 },
        { id: 7, KG: 0.75 },
        { id: 8, KG: 0.85 },
        { id: 9, KG: 0.95 },
        { id: 10, KG: 1 },
        { id: 11, KG: 1.5 },
      ];
    }
  
    if (cat === "Footwear" && gender === "men-footwear") {
      return [
        { id: 1, KG: 0.05 },
        { id: 2, KG: 0.55 },
        { id: 3, KG: 0.6 },
        { id: 4, KG: 0.65 },
        { id: 5, KG: 0.7 },
        { id: 6, KG: 0.75 },
        { id: 7, KG: 0.8 },
        { id: 8, KG: 0.85 },
        { id: 9, KG: 0.9 },
        { id: 10, KG: 0.95 },
        { id: 11, KG: 1 },
        { id: 12, KG: 1.5 },
        { id: 13, KG: 2 },
      ];
    }
  
    if (cat === "Bags") {
      return [
        { id: 1, KG: 0.25 },
        { id: 2, KG: 0.5 },
        { id: 3, KG: 1 },
        { id: 4, KG: 1.25 },
        { id: 5, KG: 1.5 },
        { id: 6, KG: 2 },
        { id: 7, KG: 2.25 },
        { id: 8, KG: 2.5 },
        { id: 9, KG: 3 },
      ];
    }
  
    if (cat === "Skin and Beauty" || cat === "Grooming") {
      return [
        { id: 1, KG: 0.005 },
        { id: 2, KG: 0.25 },
        { id: 3, KG: 0.5 },
        { id: 4, KG: 1 },
        { id: 5, KG: 1.5 },
        { id: 6, KG: 2 },
      ];
    }
  
    if (cat === "Fabrics") {
      return [
        { id: 1, KG: 0.5 },
        { id: 2, KG: 1 },
        { id: 3, KG: 1.5 },
        { id: 4, KG: 2 },
        { id: 5, KG: 2.5 },
        { id: 6, KG: 3 },
        { id: 7, KG: 3.5 },
        { id: 8, KG: 4 },
      ];
    }
  };
  
  export default weights;
  