export const countryInfo = [
  {
    name: "United States of America",
    code: "USA",
    flag: "/images/icon-usa.png",
  },
  { name: "United Kingdom", code: "UK", flag: "/images/icon-gbp.png" },
  { name: "Nigeria", code: "Nigeria", flag: "/images/icon-nga.png" },
  { name: "Ghana", code: "Ghana", flag: "/images/icon-gh.png" },
  { name: "South Africa", code: "South Africa", flag: "/images/icon-sf.png" },
];

export const countries = [
  {
    code: "NGN",
    name: "Nigeria (NGN)",
    icon: "/images/icon-nga.png",
    country: "Nigeria",
    dial: "+234",
  },
  // {
  //   code: "USD",
  //   name: "American/USD",
  //   icon: "/images/icon-usa.png",
  //   country: "United States of America",
  //   dial: "+1",
  // },
  // {
  //   code: "GBP",
  //   name: "United Kingdom/GBP",
  //   icon: "/images/icon-gbp.png",
  //   country: "United Kingdom",
  //   dial: "+44",
  // },
  // {
  //   code: "EUR",
  //   name: "Europe/Euro",
  //   icon: "/images/icon-eur.png",
  //   country: "Scotland",
  // },
];

export const itemWeight = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
