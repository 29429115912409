import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, user, type, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!user.isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // check if route is restricted by role

      switch (type) {
        case "ALL":
          return <Component {...props} />;

        case "SUPER-ADMIN":
          if (user.info.role === 1) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;
        case "SELLER-TEAM":
          if (user.info.role === 1 || user.info.role === 5) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;
        case "CUSTOMER-TEAM":
          if (
            user.info.role === 1 ||
            user.info.role === 6 ||
            user.info.role === 5
          ) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;
        case "FINANCE-TEAM":
          if (user.info.role === 1 || user.info.role === 8) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;

        case "MANAGEMENT":
          if (user.info.role === 1 || user.info.role === 6) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;
        case "LOGISTICS":
          if (user.info.role === 1 || user.info.role === 7) {
            return <Component {...props} />;
          }
          return <Redirect to="/unauthorized" />;

        default:
          return;
      }
    }}
  />
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
