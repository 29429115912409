import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ordersActionCreators } from "../../../redux/orders";
import { Avatar, Button, Table, Tag, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import Dashboard from "../Dashboard";

const PendingOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const { pending } = useSelector((state) => state.orders);
  //   const currency = useSelector((state) => state.currency[0]);
  //   const { acceptOrder, declineOrder } = bindActionCreators(
  //     storeActionCreators,
  //     dispatch
  //   );
  const { fetchNewOrders } = bindActionCreators(ordersActionCreators, dispatch);

  const columns = [
    {
      align: "center",
      title: "USER INFO",
      dataIndex: "users",
      key: "user",
      render: (user) => (
        <div className="user-info-div">
          <Avatar size={30}>{user.buyer.firstName.charAt(0)}</Avatar>
          <div>
            <h3 className="user-info-name">
              {user.buyer.firstName} {user.buyer.lastName}
            </h3>
            <h4 className="user-info-email">{user.buyer.email}</h4>
          </div>
        </div>
      ),
    },
    {
      align: "center",
      dataIndex: "product",
      title: "PRODUCT INFORMATION",
      key: "product",
      render: (product) => (
        <div className="user-info-div">
          <img className="item-img" alt="product" src={product.productImage} />
          <div className="product-info-div">
            <h3 className="user-info-name">{product.productName}</h3>
            <Tag className="size-tag" color="#FAD3C7">
              Size: {product.productSize}
            </Tag>
          </div>
        </div>
      ),
    },

    {
      title: "COLOR & QUANTITY",
      align: "center",
      dataIndex: "product",
      key: "size",
      render: (product) => (
        <div className="product-color-container">
          <div className="product-color-wrapper">
            <div
              className="color-box"
              style={{ backgroundColor: `${product.productColor}` }}
            />
          </div>
          <h4 className="txt-product-quantity">{`${product.totalQty}pcs`}</h4>
        </div>
      ),
    },

    {
      align: "center",
      title: "PRICE (#)",
      dataIndex: "product",
      key: "price",

      render: (product) => <p className="price">{product.totalAmount}</p>,
    },

    {
      align: "center",
      title: "Action",
      dataIndex: "saleNumber",
      render: (product) => (
        <div style={{ width: 70 }}>
          <Popover
            content={
              <div className="btn-container">
                <Button
                  icon={
                    <img
                      className="btn-icon"
                      alt="accept"
                      src="/images/tick-sqr.svg"
                    />
                  }
                  className="btn-order-option"
                  type="ghost"
                >
                  Accept
                </Button>
                <Button
                  icon={
                    <img
                      className="btn-icon"
                      alt="decline"
                      src="/images/close-sqr.svg"
                    />
                  }
                  className="btn-order-option"
                  type="ghost"
                >
                  Decline
                </Button>
              </div>
            }
          >
            <MoreOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
          </Popover>
        </div>
      ),
    },
  ];

  //   const completeOrder = async (saleNo) => {
  //     const res = await acceptOrder(info.token, saleNo);
  //     if (res.status === "ok") message.success(res.message);
  //     if (res.status === "not ok") message.success(res.message);
  //   };
  //   const cancelOrder = async (saleNo) => {
  //     const res = await declineOrder(info.token, saleNo);
  //     if (res.status === "ok") message.success(res.message);
  //     if (res.status === "not ok") message.success(res.message);
  //   };

  useEffect(() => {
    fetchNewOrders(user.info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dashboard>
        <h1 className="txt-title">Pending Orders</h1>
        <>
          <Table
            className="table"
            dataSource={pending}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`/orders/${record.product.slug}`, record);
                },
              };
            }}
            pagination={false}
            style={{ cursor: "pointer" }}
          />
          {/* <Button className="btn-more">Load More</Button> */}
        </>
      </Dashboard>

      <style jsx="true">{`
        .txt-title {
          font: normal 500 30px/35px DomaineSansText-Light;
          color: #000000;
        }
        .mobile-txt-title {
          font-size: 20px;
          color: #000000;
          font-family: DomaineSansText-Regular;
          text-align: center;
          margin: 20px 0;
        }
        .table {
          margin-top: 56px;
        }

        .table .ant-table-thead > tr > th {
          border: 20px solid white;
          font: normal normal 13px/19px DomaineSansText-Light;
          color: #677189;
          background: #f9f9f9;
          border-radius: 28px;
          padding: 10px;
        }
        .user-info-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .user-info-name {
          text-align: left;
          font: normal bold 14px/18px DomaineSansText-Light;
        }
        .user-info-email {
          font: normal normal 10px/13px DomaineSansText-Light;
          color: #6f6f6f;
        }
        .product-info-div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .size-tag {
          color: #000;
          font: normal normal 9px/13px DomaineSansText-Light;
          padding: 4px 11px;
        }
        .item-img {
          height: 50px;
        }
        .product-color-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .product-color-wrapper {
          padding: 4px;
          border: 1px solid #ccc;
          height: 28px;
          width: 28px;
          display: inline-block;
        }
        .color-box {
          width: 18px;
          height: 18px;
          border: 1px solid #ccc;
        }
        .txt-product-quantity {
          font: normal bold 14px/18px DomaineSansText-Light;
          margin-top: 5px;
        }
        .price {
          margin: 0;
        }
        .btn-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 115px;
          height: 88px;
        }
        .btn-order-option {
          border: 0px;
          color: #677189;
          font: normal bold 14px/146.16% DomaineSansText-Light;
        }
        .btn-icon {
          margin-right: 15px;
        }
        .btn-more {
          background: #000000;
          color: #fff;
          width: 195px;
          height: 63px;
          left: 40%;
          right: 50%;
          margin-top: 45px;
        }
      `}</style>
    </div>
  );
};

export default PendingOrders;
