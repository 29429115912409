import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AutoComplete,
  Avatar,
  Button,
  Input,
  Form,
  Radio,
  Col,
  Popconfirm,
  Select,
  Tabs,
  Row,
  Tooltip,
  Checkbox,
  Switch,
  DatePicker,
  message,
  // Collapse,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
// import SellerSideNav from "./SellerSideNav";

import axios from "axios";
import Dashboard from "../seller-mgmt/Dashboard";
import { brandActionCreators } from "../../redux/seller";
import getLatLng from "../../utils/getLatLng";

const { TabPane } = Tabs;
const { Option } = Select;

// const { Panel } = Collapse;

const StoreSettings = () => {
  const dispatch = useDispatch();
  const { brandDetails, brand } = useSelector((state) => state.brands);
  const [countryValue, setCountryValue] = useState("");

  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [options, setOptions] = useState([]);
  const [nigeriaData, setNigeriaData] = useState([]);
  const [lagosData, setLagosData] = useState([]);
  const [ghanaData, setGhanaData] = useState([]);
  const [UKData, setUKData] = useState([]);
  const [sfData, setSfData] = useState([]);
  const [USAData, setUSAData] = useState([]);
  const [nigeriaStateValue, setNigeriaStateValue] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [sizeGuide, setSizeGuide] = useState("");
  const [isVacation, setIsVacation] = useState(false);
  const [reason, setReason] = useState("");
  const [showError, setShowError] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  const [showBlockError, setShowBlockError] = useState(false);
  const [isDiscount, setDiscount] = useState(false);

  // const {
  //   details,
  //   sizeGuide: guide,
  //   returnType,
  //   deliveryOption,
  //   vacationMode,
  // } = useSelector((state) => state.store);

  const { info } = useSelector((state) => state.user);

  const {
    addSizeGuide,
    delSizeGuide,
    updateBrandReturns,
    updateBrandDelivery,
    resetBrandPassword,
    activateVacationMode,
    deactivateVacationMode,
    blockBrand,
    unblockBrand,
    updateStoreDetails,
  } = bindActionCreators(brandActionCreators, dispatch);

  const isTabletOrMobile = false;

  const [form] = Form.useForm();
  const [returnsForm] = Form.useForm();

  const hiddenFileInput = useRef(null);
  const hiddenSizeInput = useRef(null);

  // const returns = { returnType };

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop - 100, behavior: "smooth" });

  const aboutBusinessRef = useRef(null);
  const passwordRef = useRef(null);
  const currencyRef = useRef(null);
  const storeLinkRef = useRef(null);
  const deliveryRef = useRef(null);
  const sizeGuideRef = useRef(null);
  const returnsRef = useRef(null);
  const vacationRef = useRef(null);
  const discountRef = useRef(null);

  const changeTab = (key) => {
    switch (key) {
      case "1":
        scrollToRef(aboutBusinessRef);
        break;
      case "8":
        scrollToRef(passwordRef);
        break;
      case "2":
        scrollToRef(currencyRef);
        break;
      case "3":
        scrollToRef(storeLinkRef);
        break;
      case "4":
        scrollToRef(deliveryRef);
        break;
      case "5":
        scrollToRef(sizeGuideRef);
        break;
      case "6":
        scrollToRef(returnsRef);
        break;
      case "7":
        scrollToRef(vacationRef);
        break;
      case "9":
        scrollToRef(discountRef);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleSizeClick = () => {
    hiddenSizeInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name);
    uploadImage(fileUploaded);
  };

  const handleSizeChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setSizeGuide(fileUploaded.name);
    uploadSizeGuide(fileUploaded);
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };
  const uploadSizeGuide = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "guides");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    if (result.status === 200) {
      const res = await addSizeGuide(info.token, {
        guideName: file.name,
        sizeGuideUrl: result.data.secure_url,
        sellerId: brand.sellerId.toString(),
      });

      if (res.status === "ok") {
        message.success(res.message);
      }
      if (res.status === "not ok") {
        message.success(res.message);
      }
      return result.data.secure_url;
    }
  };

  const deleteSizeGuide = async () => {
    const res = await delSizeGuide(info.token, brand.sellerId);
    if (res.status === "ok") {
      message.success(res.message);
      setSizeGuide("");
    }
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(brandDetails.storeLink);
      message.success("Your Store link has been copied to clipboard");
    } catch (err) {
      message.error("Failed to copy: ", err.message);
    }
  };

  const resetPassword = async () => {
    const res = await resetBrandPassword(info.token, brand.sellerId);
    if (res.status === "ok") message.success(res.message);
    if (res.status === "not ok") message.error(res.message);
  };

  const onChangeReturns = async () => {
    const values = returnsForm.getFieldsValue();
    values.sellerId = brand.sellerId.toString();
    const res = await updateBrandReturns(info.token, values);
    if (res.status === "ok") {
      message.success(res.message);
      returnsForm.resetFields();
    }
    if (res.status === "not ok") message.error(res.message);
  };

  const onChangeDelivery = async (value) => {
    const res = await updateBrandDelivery(info.token, {
      delivery: value,
      sellerId: brand.sellerId.toString(),
    });
    if (res.status === "ok") message.success(res.message);
    if (res.status === "not ok") message.error(res.message);
  };

  const onSelectVacationReason = (val) => {
    setShowError(false);
    setReason(val);
  };

  const onVacationModeChange = async (val) => {
    if (val && reason === "") {
      setIsVacation(false);
      return setShowError(true);
    }
    if (val) {
      const res = await activateVacationMode(info.token, {
        reason,
        sellerId: brand.sellerId.toString(),
      });
      if (res.status === "ok") return message.success(res.message);
    }
    if (!val) {
      const res = await deactivateVacationMode(info.token, brand.sellerId);
      if (res.status === "ok") return message.success(res.message);
    }
  };

  const onSelectBlockReason = (val) => {
    setShowBlockError(false);
    setBlockReason(val);
  };

  const onBlockChange = async (val) => {
    if (val && blockReason === "") {
      return setShowBlockError(true);
    }
    if (val) {
      const res = await blockBrand(info.token, {
        blockReason,
        sellerId: brand.sellerId.toString(),
      });
      if (res.status === "ok") return message.success(res.message);
    }
    if (!val) {
      const res = await unblockBrand(info.token, brand.sellerId);
      if (res.status === "ok") return message.success(res.message);
    }
  };

  const onFinish = async () => {
    const { storeCountry, storeState, storeAddress } = form.getFieldsValue();
    const values = {
      country: storeCountry,
      state: storeState,
      address: storeAddress,
    };
    const latLng = await getLatLng(values);
    const latitude = latLng.split(",")[0];
    const longitude = latLng.split(",")[1];

    const data = {
      ...form.getFieldsValue(),
      ...values,
      brandLogo: imageUrl ? imageUrl : brandDetails.logoUrl,
      latitude,
      longitude,
      sellerId: brand.sellerId.toString(),
    };
    const res = await updateStoreDetails(info.token, data);
    if (res.status === "ok") return message.success(res.message);
    if (res.status === "not ok") return message.error(res.message);
  };

  useEffect(() => {
    setSizeGuide(brandDetails?.size_guide_name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetails?.size_guide_name]);

  useEffect(() => {
    setIsVacation(brandDetails?.vacationMode);
  }, [brandDetails?.vacationMode]);

  useEffect(() => {
    setCountryValue(brandDetails?.storeCountry);
  }, [brandDetails?.storeCountry]);

  const setArea = (data) => {
    if (brandDetails.area && brandDetails.storeState === "Lagos") {
      setNigeriaStateValue("Lagos");
      const area = data[0].areas?.find(
        (area) => area.id === Number.parseInt(brandDetails.area)
      );
      form.setFieldsValue({ area: area?.name });
    } else if (brandDetails.area && brandDetails.storeState !== "Lagos") {
      form.setFieldsValue({ area: brandDetails.area });
    }
  };

  const fetchCities = async (city_text) => {
    let country_code;
    if (brandDetails?.storeCountry === "Nigeria") country_code = "NG";
    if (brandDetails?.storeCountry === "Ghana") country_code = "GH";
    if (brandDetails?.storeCountry === "South Africa") country_code = "ZA";
    if (brandDetails?.storeCountry === "USA") country_code = "US";
    const timestamp = Math.floor(Date.now() / 1000).toString();

    if (city_text.length >= 3) {
      const res = await axios.post(`https://losode.dev/api/v2/city`, {
        city_text,
        country_code,
        timestamp,
      });
      if (res.data.status === "ok") {
        const data = [];
        res.data.data?.map((city) => data.push({ value: city?.cityName }));
        setOptions(data);
      }
    }
  };

  useEffect(() => {
    const fetchAreas = async () => {
      const result = await axios.get(`utility/countries`);
      if (result.data.state === "ok") {
        const ng = result.data.data.filter(
          (country) => country.name === "Nigeria"
        );
        setNigeriaData(ng);

        const gh = result.data.data.filter(
          (country) => country.name === "Ghana"
        );
        setGhanaData(gh);
        const sf = result.data.data.filter(
          (country) => country.name === "South Africa"
        );
        setSfData(sf);

        const uk = result.data.data.filter(
          (country) => country.name === "United Kingdom"
        );
        setUKData(uk);

        const us = result.data.data.filter(
          (country) => country.name === "United States of America"
        );
        setUSAData(us);

        if (ng) {
          const lg = ng[0].states.filter((data) => data.state_name === "Lagos");
          setArea(lg);
          setLagosData(lg);
        }
      }
    };
    fetchAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    returnsForm.setFieldsValue({ returnType: brandDetails?.returnType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetails?.returnType]);

  useEffect(() => {
    setDeliveryType(brandDetails?.deliveryOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetails?.deliveryOption]);

  useEffect(() => {
    form.setFieldsValue({
      brandLogo: fileName,
      logoUrl: imageUrl,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
      noSideNav
    >
      <div className="store-settings-container">
        {!isTabletOrMobile && (
          <div className="manage-listing-all-content">
            {/* {!isTabletOrMobile && <SellerSideNav currentPageKey="10" />} */}
            <div
              style={{
                background: "#F7F7F7",
                padding: "24px 0",
              }}
            >
              <div className="store-settings-body-content-header">
                <div className="store-settings-body-content-header-container">
                  <div className="store-settings-body-content-header-title">
                    Store Settings
                  </div>
                  <div className="store-settings-body-content-header-subtitle">
                    In your Store settings, you can edit or change your store
                    details and manage general characteristics for your Store.
                    Remember to join the Losode Seller Community{" "}
                    <a
                      className="dashboard-body-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://t.me/+EOIEDBbkFMgwNzE8"
                    >
                      here
                    </a>{" "}
                    and take advantage of our affordable photoshoot packages
                  </div>
                </div>
              </div>

              <div
                className="store-setting-tab-container store-settings-about-business"
                ref={aboutBusinessRef}
              >
                <Tabs
                  defaultActiveKey="1"
                  className="store-setting-tab"
                  activeKey="1"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1">
                    <Form
                      form={form}
                      initialValues={brandDetails}
                      name="store"
                      scrollToFirstError
                      layout="vertical"
                      requiredMark={false}
                      onFinish={onFinish}
                      style={{ padding: "20px" }}
                    >
                      <Col span={12} style={{ margin: "auto" }}>
                        <Form.Item
                          name="storeCountry"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Select
                            className="select"
                            placeholder="Select Country"
                            defaultValue="Nigeria"
                            value={countryValue}
                            onChange={(value) => setCountryValue(value)}
                          >
                            <Option value="Nigeria">Nigeria</Option>
                            <Option value="Ghana">Ghana</Option>
                            <Option value="South Africa">South Africa</Option>
                            <Option value="USA">United States</Option>
                            <Option value="UK">United Kingdom</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <div
                        style={{
                          border: "0.5px solid #ebebeb",
                          margin: "50px 0",
                        }}
                      ></div>

                      <Row className="form-row" gutter={[72, 12]}>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter your first name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter your last name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="brandName"
                            label="Brand Name"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="Enter your business or brand name"
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item name="brandLogo" label="Change Brand Logo">
                            <Input
                              className="input"
                              placeholder="Use a professionally designed logo"
                              onChange={uploadImage}
                              readOnly
                              prefix={
                                <Avatar size={40} src={brandDetails.logoUrl} />
                              }
                              suffix={
                                <>
                                  <Button
                                    className="btn-upload"
                                    onClick={handleClick}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "end",
                                      }}
                                    >
                                      <img
                                        alt="upload"
                                        src="/images/upload.png"
                                      />
                                      <span
                                        style={{
                                          fontSize: `${
                                            !isTabletOrMobile ? "14px" : "10px"
                                          }`,
                                        }}
                                      >
                                        Browse
                                      </span>
                                    </div>
                                  </Button>
                                  <input
                                    accept="image/*"
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="email"
                            label="Email Address"
                            rules={[
                              {
                                type: "email",
                                message: "Use a valid email address",
                              },
                              // () => ({
                              //     validator(rule, value) {
                              //     if (value  !== user.info.email) {
                              //         return Promise.resolve();
                              //     }

                              //     return Promise.reject(
                              //         "Please enter a different email address. This must be different from your login email address."
                              //     );
                              //     },
                              // })
                            ]}
                          >
                            <Input
                              className="input"
                              disabled
                              suffix={
                                <Tooltip title="">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      fontSize: "20px",
                                      marginRight: "20px",
                                    }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="alternateEmail"
                            label="Alternate Email Address"
                            rules={[
                              {
                                type: "email",
                                message: "Use a valid email address",
                              },
                              // () => ({
                              //   validator(rule, value) {
                              //     if (value !== info.email) {
                              //       return Promise.resolve();
                              //     }

                              //     return Promise.reject(
                              //       "Please enter a different email address. This must be different from your login email address."
                              //     );
                              //   },
                              // }),
                            ]}
                          >
                            <Input
                              className="input"
                              suffix={
                                <Tooltip title="To ensure you do not miss any orders, we need to collect an alternative email address  ">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      fontSize: "20px",
                                      marginRight: "20px",
                                    }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="businessRegistered"
                            label="Is your business registered?"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value={true}>Yes</Select.Option>
                              <Select.Option value={false}>No</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {countryValue === "Nigeria" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="regNumber"
                              label="Business Registration Number (Optional) "
                              rules={[]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter your CAC number"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue !== "Nigeria" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="businessID"
                              label="Business registration number (optional) "
                              rules={[]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter your business registration number"
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="description"
                            label="Brand Description"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Input.TextArea
                              autoSize
                              style={{ minHeight: 48 }}
                              className="input"
                              placeholder="Enter your brand description"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="storeAddress"
                            label="Street Address"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                              {
                                max: 60,
                                message:
                                  "Please limit your address to 60 characters or less",
                              },
                            ]}
                          >
                            <Input
                              className="input"
                              placeholder="19 East Drive, Agodi"
                              suffix={
                                <Tooltip title="Enter only your street address here, do not add state or country ">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      fontSize: "20px",
                                      marginRight: "20px",
                                    }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>

                        {countryValue === "Nigeria" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="storeState"
                              label="State"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Select
                                className="select"
                                placeholder="Select State"
                                onChange={(value) =>
                                  setNigeriaStateValue(value)
                                }
                              >
                                {nigeriaData[0]?.states?.map((state, idx) => {
                                  return (
                                    <Option value={state.state_name} key={idx}>
                                      {state.state_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "Ghana" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="storeState"
                              label="State"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Select
                                className="select"
                                placeholder="Select State"
                                onChange={(value) =>
                                  setNigeriaStateValue(value)
                                }
                              >
                                {ghanaData[0]?.states?.map((state) => {
                                  return (
                                    <Option value={state.state_name}>
                                      {state.state_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "South Africa" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="storeState"
                              label="State"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Select
                                className="select"
                                placeholder="Select State"
                                onChange={(value) =>
                                  setNigeriaStateValue(value)
                                }
                              >
                                {sfData[0]?.states?.map((state) => {
                                  return (
                                    <Option value={state.state_name}>
                                      {state.state_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "USA" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="storeState"
                              label="State"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Select
                                className="select"
                                placeholder="Select State"
                              >
                                {USAData[0]?.states?.map((state, idx) => {
                                  return (
                                    <Option value={state.state_name} key={idx}>
                                      {state.state_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "UK" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="storeState"
                              label="City"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Select
                                className="select"
                                placeholder="Select City"
                              >
                                {UKData[0]?.states?.map((state, idx) => {
                                  return (
                                    <Option value={state.state_name} key={idx}>
                                      {state.state_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "Nigeria" &&
                          nigeriaStateValue === "Lagos" && (
                            <Col span={!isTabletOrMobile ? 12 : 24}>
                              <Form.Item
                                label="Area"
                                name="area"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                              >
                                <Select
                                  className="select"
                                  placeholder="Select Area"
                                >
                                  {lagosData[0]?.areas?.map((area, idx) => {
                                    return (
                                      <Option value={area.id} key={idx}>
                                        {area.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {/* {nigeriaStateValue!=='Lagos' && <Input className="input" placeholder="Select area" />} */}
                              </Form.Item>
                            </Col>
                          )}
                        {countryValue === "Nigeria" &&
                          nigeriaStateValue !== "Lagos" && (
                            <Col span={!isTabletOrMobile ? 12 : 24}>
                              <Form.Item
                                label="Area"
                                name="area"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                              >
                                {nigeriaStateValue !== "Lagos" && (
                                  <Input
                                    className="input"
                                    placeholder="Enter Area"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          )}

                        {countryValue === "UK" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              label="County"
                              name="area"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Select County"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "USA" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              label="Area"
                              name="area"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Select County"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue !== "UK" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="city"
                              label="City"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <AutoComplete
                                className="select-city"
                                placeholder="Select City"
                                onSearch={fetchCities}
                                options={options}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "Nigeria" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="zipCode"
                              label="Postal Code (Optional)"
                            >
                              <Input
                                className="input"
                                placeholder="Enter your postal code"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "USA" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="zipCode"
                              label="Zip Code"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter your zip code"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {countryValue === "UK" && (
                          <Col span={!isTabletOrMobile ? 12 : 24}>
                            <Form.Item
                              name="zipCode"
                              label="Post Code"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter your post code"
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value="male">Male</Radio>
                              <Radio value="female">Female</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="brandCategory"
                            label="Tell us what your Store sells"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Select
                              className="select"
                              placeholder="Select all categories that relate to your Store"
                              mode="multiple"
                            >
                              <Option value="Clothing">Clothing</Option>
                              <Option value="Shoes">Shoes</Option>
                              <Option value="Bags">Bags</Option>
                              <Option value="Accessories">Accessories</Option>
                              <Option value="Beauty/Grooming">
                                Beauty | Grooming
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item>
                            <Button
                              className="seller-welcome-btn-submit"
                              htmlType="submit"
                            >
                              Update Settings
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>

                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={passwordRef}>
                <Tabs
                  defaultActiveKey="8"
                  className="store-setting-tab"
                  activeKey="8"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>

                  <TabPane tab="Password" key="8">
                    <div className="store-settings-box store-settings-password">
                      <div>
                        <div className="store-settings-summary-title">
                          Change Your Password
                        </div>
                        <div className="store-settings-summary-subtitle">
                          You can change Store password at any time here.
                          Passwords must meet our security criteria
                        </div>
                        <div className="store-settings-password-container">
                          <Button
                            className="password-btn-submit"
                            onClick={resetPassword}
                          >
                            Reset Seller Password
                          </Button>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={currencyRef}>
                <Tabs
                  defaultActiveKey="2"
                  className="store-setting-tab"
                  activeKey="2"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>

                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2">
                    <div className="store-settings-box store-settings-currency">
                      <div>
                        <div className="store-settings-summary-title">
                          Seller Currency
                        </div>
                        <div className="store-settings-summary-subtitle">
                          Kindly update seller address and country to change
                          currency
                        </div>
                        <div className="store-settings-currency-container">
                          <div className="store-settings-currency-active-container">
                            <div className="store-settings-currency-active-title">
                              Current currency
                            </div>
                            <div className="store-settings-currency-active">
                              {brand?.storeCurrency?.code} (
                              {brand?.storeCurrency?.symbol})
                            </div>
                          </div>
                        </div>
                        <div>
                          Note that you would need to have a bank account that
                          can accept the updated currency{" "}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={storeLinkRef}>
                <Tabs
                  defaultActiveKey="3"
                  className="store-setting-tab"
                  activeKey="3"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3">
                    <div className="store-settings-box store-settings-store-link">
                      <div>
                        <div className="store-settings-summary-title">
                          Share Your Store Link
                        </div>
                        <div className="store-settings-summary-subtitle">
                          Share seller unique Losode Store link with members of
                          the community, simply copy it and share on social
                          media or in an email to increase sales to store
                        </div>
                        <div>
                          <div style={{ marginTop: "30px" }}>
                            Your unique Store link:{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              // href={details.storeLink}
                              href="/"
                            >
                              {brandDetails.storeLink}
                            </a>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "30px",
                              marginTop: "20px",
                            }}
                          >
                            <Button
                              className="store-link-button-copy"
                              onClick={copyLink}
                            >
                              Copy Link
                            </Button>
                            <Button
                              className="store-link-button-preview"
                              onClick={() =>
                                window.open(`/${brand.slug}/preview`, "_blank")
                              }
                            >
                              Preview Store
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={discountRef}>
                <Tabs
                  defaultActiveKey="4"
                  className="store-setting-tab"
                  activeKey="9"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9">
                    <div className="store-settings-summary-title">
                      <strong>Discounts</strong>
                    </div>

                    <Form
                      scrollToFirstError
                      layout="vertical"
                      requiredMark={false}
                    >
                      <Row
                        className="form-row"
                        gutter={[12, 12]}
                        style={{
                          background: "white",
                          padding: !isTabletOrMobile ? "24px 0" : "16px 0",
                        }}
                      >
                        <Col span={24}>
                          <Form.Item
                            name="has_discount"
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  Would like to add a discount to your store?{" "}
                                </div>
                                <Tooltip title="This will overide existing discounts in your store ">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      fontSize: "16px",
                                      marginRight: "20px",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Radio.Group
                              onChange={(e) => setDiscount(e.target.value)}
                              value={isDiscount}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        {isDiscount && (
                          <>
                            <Col span={!isTabletOrMobile ? 6 : 24}>
                              <Form.Item
                                name="discount_percent"
                                label="Enter discount percentage"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "11px",
                                  }}
                                >
                                  <Input
                                    type="number"
                                    className="input"
                                    style={{ width: "40%", height: 48 }}
                                  />
                                  <div style={{ fontSize: "20px" }}>%</div>
                                </div>
                                {/* <div
                              className="product-image-section-extra"
                              style={{ marginTop: "16px" }}
                            >
                              Field should be numeric only
                            </div> */}
                              </Form.Item>
                            </Col>
                            <Col span={!isTabletOrMobile ? 9 : 24}>
                              <Form.Item
                                name="discount_start_date"
                                label="When do you want the discount to start?"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="date"
                                  placeholder="Select start date"
                                />
                              </Form.Item>
                              <Form.Item
                                name="discount_start_type"
                                style={{ marginTop: "-16px" }}
                              >
                                <Checkbox value="immediately">
                                  <span style={{ marginRight: "4px" }}>
                                    Start immediately{" "}
                                  </span>
                                  <Tooltip title="The discount you have applied will start as soon as your listing is live">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "rgba(0,0,0,.45)",
                                        fontSize: "20px",
                                        marginRight: "20px",
                                      }}
                                    />
                                  </Tooltip>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={!isTabletOrMobile ? 9 : 24}>
                              <Form.Item
                                name="discount_end_date"
                                label="When do you want the discount to end?"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="date"
                                  placeholder="Select end date"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  className="store-link-button-preview"
                                  style={{ float: "right" }}
                                >
                                  Submit
                                </Button>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>

                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={deliveryRef}>
                <Tabs
                  defaultActiveKey="4"
                  className="store-setting-tab"
                  activeKey="4"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4">
                    <div className="store-settings-summary-title">
                      <strong>Update Delivery Settings</strong>
                    </div>

                    <div className="store-settings-summary-subtitle">
                      We have set delivery settings in line with the latest
                      delivery settings added by seller when you a new item was
                      listed. You can still change a new listing with a new
                      listing or set generic settings for your store if you have
                      not added a new item to your store
                    </div>
                    <Radio.Group
                      onChange={(e) => onChangeDelivery(e.target.value)}
                      className="delivery-items-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        marginTop: "30px",
                        fontWeight: "bold",
                      }}
                      value={deliveryType}
                    >
                      <Radio value="self">Handle delivery yourself?</Radio>
                      <Radio value="losode">Losode handles delivery?</Radio>
                      <Radio value="pay">
                        You pay for delivery (free to the buyer)?
                      </Radio>
                    </Radio.Group>
                  </TabPane>

                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={sizeGuideRef}>
                <Tabs
                  defaultActiveKey="5"
                  className="store-setting-tab"
                  activeKey="5"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5">
                    <div className="store-settings-box store-settings-size-guide">
                      <div>
                        <div className="store-settings-summary-title">
                          Add Your Size Guide To Your Store
                        </div>
                        <div className="store-settings-summary-subtitle">
                          We have a standard size guide on Losode, however we
                          understand size guides vary for sellers. If you have
                          your own size guide you can upload it here in .pdf
                          format
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            margin: "20px 0",
                          }}
                        >
                          <div>
                            <div style={{ marginBottom: "8px" }}>
                              Upload your size guide
                            </div>
                            <Input
                              className="input"
                              placeholder="upload files in .pdf format only"
                              onChange={uploadSizeGuide}
                              suffix={
                                <>
                                  <Button
                                    className="btn-upload"
                                    onClick={handleSizeClick}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "end",
                                      }}
                                    >
                                      <img
                                        alt="upload"
                                        src="/images/upload.png"
                                      />
                                      <span
                                        style={{
                                          fontSize: `${
                                            !isTabletOrMobile ? "14px" : "10px"
                                          }`,
                                        }}
                                      >
                                        Browse
                                      </span>
                                    </div>
                                  </Button>
                                  <input
                                    accept="application/pdf"
                                    type="file"
                                    ref={hiddenSizeInput}
                                    onChange={handleSizeChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              }
                            />
                          </div>
                          {sizeGuide && (
                            <Popconfirm
                              placement="topLeft"
                              title="Are you sure you want to delete this guide?"
                              onConfirm={() => deleteSizeGuide()}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button className="size-guide-delete-btn">
                                Delete size guide
                                <Tooltip title="The Losode size guide will be used where you do not have your own size guide allocated to your account">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "white",
                                      fontSize: "20px",
                                      marginRight: "20px",
                                    }}
                                  />
                                </Tooltip>
                              </Button>
                            </Popconfirm>
                          )}
                        </div>
                        <div
                          style={{
                            textDecoration: "underline",
                            marginBottom: "20px",
                          }}
                        >
                          {sizeGuide}
                        </div>
                        <div>
                          Change your size guide by uploading a new size guide
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={returnsRef}>
                <Tabs
                  defaultActiveKey="6"
                  className="store-setting-tab"
                  activeKey="6"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6">
                    <div className="store-settings-box store-settings-returns">
                      <div>
                        <div className="store-settings-summary-title">
                          Refunds, Exchanges, And Amendments
                        </div>
                        <div className="store-settings-summary-subtitle">
                          To give our buyers a level of comfort when buying from
                          Losode, we have made it mandatory for sellers to offer
                          buyers refunds, exchanges or amendments
                        </div>
                      </div>
                      <div className="delivery-settings-box">
                        <div>
                          How does your Store treat refunds? Select all that
                          apply
                        </div>
                        <Form
                          form={returnsForm}
                          scrollToFirstError
                          // initialValues={returns}
                          layout="vertical"
                          requiredMark={false}
                          onFinish={onChangeReturns}
                          style={{ padding: "20px 0" }}
                        >
                          <div className="delivery-settings-options">
                            <Form.Item
                              name="returnType"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select at least a returns type!",
                                },
                              ]}
                            >
                              <Checkbox.Group>
                                <Checkbox value="Returns">Refunds</Checkbox>
                                <Checkbox value="Amendments">
                                  Amendments
                                </Checkbox>
                                <Checkbox value="Exchanges">Exchanges</Checkbox>
                              </Checkbox.Group>
                            </Form.Item>
                            <Button
                              className="btn-update-returns"
                              htmlType="submit"
                            >
                              Update Settings
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Vacation" key="7"></TabPane>
                </Tabs>
              </div>

              <div className="store-setting-tab-container" ref={vacationRef}>
                <Tabs
                  defaultActiveKey="7"
                  className="store-setting-tab"
                  activeKey="7"
                  onTabClick={(key) => changeTab(key)}
                >
                  <TabPane tab="About Your Business" key="1"></TabPane>
                  <TabPane tab="Password" key="8"></TabPane>
                  <TabPane tab="Currency" key="2"></TabPane>
                  <TabPane tab="Store Link" key="3"></TabPane>
                  <TabPane tab="Discount" key="9"></TabPane>
                  <TabPane tab="Delivery Settings" key="4"></TabPane>
                  <TabPane tab="Size Guide" key="5"></TabPane>
                  <TabPane tab="Returns" key="6"></TabPane>
                  <TabPane tab="Vacation" key="7">
                    <div className="store-settings-box store-settings-returns">
                      <div>
                        <div className="store-settings-summary-title">
                          Set Store To Vacation
                        </div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{ fontWeight: "bold", marginBottom: "8px" }}
                        >
                          What happens when you go on vacation?
                        </div>
                        <ul style={{ paddingInlineStart: "20px" }}>
                          <li>
                            Your account will be inactive, until you reopen it
                          </li>
                          <li>
                            Your profile, Store and listings will no longer
                            appear on Losode
                          </li>
                          <li>
                            All open orders must be completed before your Store
                            is set to vacation{" "}
                          </li>
                          <li>
                            Your account settings will remain intact, and no one
                            will be able to use your Store ID and Store name
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px", fontWeight: "bold" }}>
                        You can re-activate your Store at any time, simply
                        toggle back from vacation mode
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="store-settings-about-business">
                          <div style={{ marginBottom: "16px" }}>
                            Tell us why you are going on vacation
                          </div>

                          <Select
                            className="vacation-select"
                            placeholder="Select reason for vacation"
                            onChange={(val) => onSelectVacationReason(val)}
                          >
                            <Option value="Travelling">Travelling</Option>
                            <Option value="Change of staff">
                              Change of staff
                            </Option>
                            <Option value="Breaks">Breaks</Option>
                            <Option value="Relocation purposes">
                              Relocation purposes
                            </Option>
                            <Option value="Health Conditions">
                              Health Conditions
                            </Option>
                            <Option value="Out of stock and working on a new collection">
                              Out of stock and working on a new collection
                            </Option>
                          </Select>
                          {showError && (
                            <p className="txt-required">
                              Please select a reason
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          <div>Go on vacation</div>
                          <Switch
                            checked={isVacation}
                            onChange={onVacationModeChange}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>

              {/* <div
                style={{
                  background: "white",
                  margin: "40px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    border: "1px solid",
                    padding: "20px",
                    fontSize: "14px",
                  }}
                >
                  <div className="store-settings-summary-title">
                    Deactivate Seller Account
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="store-settings-about-business">
                      <div style={{ marginBottom: "16px" }}>
                        Tell us why the seller is deactivating the account
                      </div>

                      <Select
                        className="vacation-select"
                        placeholder="Select reason for vacation"
                        onChange={(val) => onSelectVacationReason(val)}
                      >
                        <Option value="Travelling">Travelling</Option>
                        <Option value="Change of staff">Change of staff</Option>
                        <Option value="Breaks">Breaks</Option>
                        <Option value="Relocation purposes">
                          Relocation purposes
                        </Option>
                        <Option value="Health Conditions">
                          Health Conditions
                        </Option>
                        <Option value="Out of stock and working on a new collection">
                          Out of stock and working on a new collection
                        </Option>
                      </Select>
                      {showError && (
                        <p className="txt-required">Please select a reason</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <div>Deactivate Seller</div>
                      <Switch
                        checked={isVacation}
                        onChange={onVacationModeChange}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                style={{
                  background: "white",
                  margin: "40px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    border: "1px solid",
                    padding: "20px",
                    fontSize: "14px",
                  }}
                >
                  <div className="store-settings-summary-title">
                    Block Seller Account
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="store-settings-about-business">
                      <div style={{ marginBottom: "16px" }}>
                        Tell us why the seller is blocked
                      </div>

                      <Select
                        className="vacation-select"
                        placeholder="Select reason for blocking seller"
                        onChange={(val) => onSelectBlockReason(val)}
                      >
                        <Option value="Seller is using other sellers’ pictures">
                          Seller is using other sellers’ pictures
                        </Option>
                        <Option value="Selling defective/low quality items">
                          Selling defective/low quality items
                        </Option>
                        <Option value="Seller has cancelled/rejected more than 5 orders this month">
                          Seller has cancelled/rejected more than 5 orders this
                          month
                        </Option>
                        <Option value="Seller is suspected of being fraudulent">
                          Seller is suspected of being fraudulent
                        </Option>
                        <Option value="Seller is taking business off the Losode platform">
                          Seller is taking business off the Losode platform
                        </Option>
                      </Select>
                      {showBlockError && (
                        <p className="txt-required">Please select a reason</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <div>Block Seller</div>
                      <Switch
                        checked={brand?.status === "blocked"}
                        onChange={onBlockChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <style jsx="true">{`
          .store-settings-container {
            font-family: DomaineSansText-Light;
            color: black;
          }
          .manage-listing-all-content {
            position: relative;
          }
          .comm-link {
            cursor: pointer;
            text-decoration: underline;
          }

          .store-settings-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .store-settings-body-container {
            margin: 0 40px 40px;
            display: flex;
            justify-content: space-between;
          }
          .store-settings-body-content-header-container {
            margin-bottom: 24px;
          }
          .store-settings-body-content-header {
            display: flex;
            justify-content: space-between;
            padding: 24px 40px 0;
            background: white;
            margin: 0 40px;
          }
          .store-settings-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .store-settings-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }

          .user-avatar {
            width: 42px;
            height: 42px;
          }
          .store-settings-summary-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .store-settings-summary-subtitle {
            font-size: 14px;
          }

          .ant-tabs-content-holder {
            border: 1px solid;
            padding: 20px;
            font-size: 14px;
          }
          .ant-tabs-tab-btn {
            font-size: 14px;
          }
          .store-settings-faq-section {
            padding: 16px 60px;
            font-size: 14px;
            margin-bottom: 40px;
          }
          .store-settings-faq-link {
            text-decoration: underline;
          }
          .store-settings-summary-logos-container {
            display: flex;
            gap: 12px;
            margin: 20px 0;
            align-items: center;
          }
          .store-settings-summary-logos-container img {
            height: 25px;
          }
          .store-settings-summary-deposit-section {
            font-size: 14px;
            margin-top: 40px;
          }
          .store-settings-summary-deposit-box {
            border: 1px solid #ececee;
            padding: 10px;
            width: 80%;
            margin-top: 8px;
            color: white;
            background: black;
          }
          .store-settings-summary-confirm-account-section {
            display: flex;
            gap: 16px;
            margin: 16px 0;
            width: 80%;
          }
          .store-settings-summary-confirm-account-input {
            height: 47px;
          }
          .store-settings-summary-confirm-account-button {
            height: 47px;
            background: black;
            color: white;
          }
          .store-settings-summary-confirm-account-cancel {
            height: 47px;
          }
          .store-settings-summary-bank-section {
            font-size: 14px;
          }
          .store-settings-summary-bank-title {
            font-weight: bold;
            margin-top: 20px;
          }
          .store-settings-summary-bank-subtitle {
          }
          .store-settings-summary-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .store-settings-currency-container {
            display: flex;
            gap: 200px;
            margin: 40px 0;
          }
          .store-settings-currency-active-title {
            font-weight: bold;
          }
          .store-settings-currency-active {
            border: 1px solid #d9d9d9;
            margin-top: 8px;
            height: 47px;
            padding: 10px;
            background: #d4d4d4;
          }

          .store-settings-about-business
            .ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            border: 1px solid #707070;
            padding: 10px;
            height: 47px;
            font-size: 14px;
          }

          .store-settings-currency {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          .delivery-items-container .ant-form label {
            font-size: 14px;
          }

          .store-setting-tab-container {
            background: white;
            margin: 40px;
            padding: 10px 0 20px;
          }
          .store-setting-tab {
            padding: 0 30px;
          }

          .store-settings-about-business
            .ant-form-vertical
            .ant-form-item-label
            > label {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .store-settings-about-business
            .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: 8px;
          }
          .store-settings-about-business .form-row {
            margin-top: 26.52px;
          }
          .store-settings-about-business .input {
            height: 48px;
            border: 1px solid #707070;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .store-settings-about-business
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .store-settings-about-business .ant-picker-input > input {
            font-size: 16px;
            font-family: "DomaineSansText-Light";
          }
          .store-settings-about-business .ant-select,
          .store-settings-about-business
            .ant-input-affix-wrapper
            > input.ant-input,
          .store-settings-about-business span.ant-radio + * {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .store-settings-about-business .ant-radio-group {
            height: 48px;
            display: flex;
            align-items: center;
          }
          .store-settings-about-business .ant-radio-inner {
            width: 20px;
            height: 20px;
          }

          .store-settings-about-business .ant-radio-wrapper {
            margin-right: 40px;
          }
          .store-settings-about-business
            .ant-input-affix-wrapper
            > input.ant-input {
            color: #000;
          }
          .store-settings-about-business
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            border: 1px solid #707070;
            height: 48px;
            padding: 0px;
          }

          .store-settings-about-business
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-item,
          .store-settings-about-business
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            display: flex;
            padding: 18px;
          }
          .store-settings-about-business .btn-upload {
            margin-right: 0px !important;
          }
          .store-settings-about-business .btn-upload,
          .store-settings-about-business .btn-upload:hover {
            background: black;
            color: #fff;
            height: 48px;
            width: 130px;
          }

          .store-settings-about-business
            .select-city.ant-select-show-search.ant-select:not(
              .ant-select-customize-input
            )
            .ant-select-selector
            input {
            height: 48px;
            padding: 0px;
          }

          .store-settings-about-business
            .select-city.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            padding: 10px;
          }
          .store-settings-about-business .seller-welcome-btn-submit {
            float: right;
            font-size: 14px;
            width: 195px;
            height: 48px;
            width: 100%;
            margin-top: 30px;
            background: black;
            color: white;
          }
          .store-settings-about-business .seller-welcome-btn-cancel {
            float: right;
            font-size: 14px;
            width: 195px;
            height: 48px;
            width: 100%;
            margin-top: 30px;
          }
          .store-settings-about-business
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-item,
          .store-settings-about-business
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            padding: 13px;
          }
          .store-settings-about-business .ant-tooltip-inner {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
            background: #f4f4f4;
            color: black;
          }
          .store-settings-about-business
            .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background-color: #e5e5e5;
          }

          .store-link-button-preview,
          .store-link-button-copy {
            height: 47px;
            padding: 0 28px;
            background: black;
            color: white;
          }

          .store-settings-size-guide .btn-upload {
            margin-right: 0px !important;
          }
          .store-settings-size-guide .btn-upload,
          .store-settings-size-guide .btn-upload:hover {
            background: black;
            color: #fff;
            height: 47px;
            width: 130px;
          }
          .store-settings-size-guide .ant-input-affix-wrapper {
            padding: 0;
            padding-left: 10px;
            min-width: 400px;
          }
          .store-settings-size-guide .size-guide-delete-btn {
            height: 47px;
            padding: 0 60px;
            background: black;
            color: white;
            display: flex;
            align-items: center;
            gap: 20px;
          }
          .ant-tooltip-inner {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
            background: #f4f4f4;
            color: black;
          }
          .vacation-select .ant-select-selector {
            width: 400px !important;
          }
          .store-settings-password-container {
            width: 45%;
            margin: 50px auto 20px;
            display: flex;
          }

          .store-settings-password-container .password-input {
            height: 48px;
            border: 1px solid #707070;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .store-settings-password-container .ant-form-item-explain,
          .store-settings-password-container .ant-form-item-extra {
            margin-top: 8px;
          }
          .store-settings-password-container .password-btn-submit {
            float: right;
            font-size: 14px;
            width: 195px;
            height: 48px;
            width: 100%;
            margin-top: 30px;
            background: black;
            color: white;
          }
          .store-settings-password-container .password-btn-cancel {
            float: right;
            font-size: 14px;
            width: 195px;
            height: 48px;
            width: 100%;
            margin-top: 30px;
          }
          .delivery-settings-box {
            margin-top: 20px;
          }
          .delivery-settings-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
          }
          .btn-update-returns {
            background: #000;
            color: #fff;
            height: 47px;
            padding: 0 60px;
            flex: 0.3;
          }
          .txt-required {
            color: red;
            font-size: 12px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 25px;
          }

          @media (max-width: 640px) {
            .account-container {
              margin: 12px 24px;
              margin-top: 52px;
            }
            .txt-title,
            .txt-about {
              font-size: 22px !important;
              font-weight: 700;
              text-align: center;
              width: 100%;
              line-height: 15px;
              font-family: DomaineSansText-Regular !important;
            }
            .txt-more,
            .txt-info {
              font-size: 10px;
              text-align: center;
              width: 100%;
              line-height: 15px;
            }
            .form-row {
              row-gap: 7px !important;
              margin-top: 5px !important;
            }
            .btn-upload {
              height: 40px;
              width: 86px;
              margin: 3px;
              padding: 5px;
            }
            .btn-upload span {
              line-height: 1.2;
            }
            .btn-upload img {
              height: 16px;
            }
            .btn-submit {
              width: 100%;
              margin-bottom: 130px;
              margin-top: 16px;
              font-size: 14px;
              font-family: DomaineSansText-Light;
            }

            .store-settings-coverall-container {
              margin-left: 0;
            }
            .store-settings-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .store-settings-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .store-settings-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .store-settings-body-content-header-title {
              font-size: 18px;
            }
            .store-settings-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 169px;
            }

            .store-settings-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .store-settings-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .store-settings-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }
            .store-settings-body-content-header-subtitle {
              width: 100%;
              font-size: 14px;
            }
            .store-settings-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
            }

            .input,
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector,
            .ant-picker,
            .ant-select-selector {
              height: 48px !important;
              margin-top: 0 !important;
              font-size: 14px !important;
            }
            .ant-select,
            .ant-input-affix-wrapper > input.ant-input,
            span.ant-radio + * {
              font-size: 14px !important;
            }
            .ant-form-item-explain-error {
              margin-top: 8px;
              font-size: 12px;
              margin-bottom: 8px;
            }
            .ant-form-vertical .ant-form-item-label > label,
            .ant-form label,
            .ant-input:placeholder-shown,
            .ant-picker-input > input:placeholder-shown {
              font-size: 14px !important;
            }

            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-item {
              line-height: 1.5;
            }

            .store-settings-about-business .btn-upload,
            .store-settings-about-business .btn-upload:hover {
              width: 80px;
              height: 48px;
            }
            .store-settings-about-business
              .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector,
            .ant-picker {
              height: 48px !important;
            }

            .store-settings-about-business .multiselect .ant-select-selector {
              height: max-content !important;
            }
            .store-settings-password-container .password-btn-submit,
            .store-settings-password-container .password-btn-cancel {
              margin-top: 16px;
              height: 48px;
            }
            .store-settings-size-guide .ant-input-affix-wrapper {
              min-width: auto;
            }
            .store-settings-size-guide .size-guide-delete-btn {
              gap: 8px;
              justify-content: center;
              padding: 16px;
              margin-bottom: 16px;
            }
            .vacation-select .ant-select-selector {
              width: 300px !important;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default StoreSettings;
