import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Table, Input, Checkbox, Tag } from "antd";
import {
  SearchOutlined,
  LeftOutlined,
  RightOutlined,
  // MoreOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { brandActionCreators } from "../../redux/seller";
import Dashboard from "../seller-mgmt/Dashboard";

const { Search } = Input;

const ActivateStore = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pendingRef = useRef(null);
  const blockedRef = useRef(null);

  const { info } = useSelector((state) => state.user);
  const { pendingBrands, blockedBrands } = useSelector((state) => state.brands);

  const {
    fetchPendingBrands,
    fetchBlockedBrands,
    fetchNextPendingBrands,
    fetchNextBlockedBrands,
  } = bindActionCreators(brandActionCreators, dispatch);

  useEffect(() => {
    fetchPendingBrands(info.token);
    fetchBlockedBrands(info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTable1 = () =>
    pendingRef.current.scrollIntoView({ behavior: "smooth" });

  const scrollToTable2 = () =>
    blockedRef.current.scrollIntoView({ behavior: "smooth" });

  const activateColumns = [
    {
      align: "center",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => (
        <div
          className="recent-transaction-image"
          style={{ backgroundImage: `url(${logo})` }}
        ></div>
      ),
      width: 80,
    },
    {
      title: "Store Name",
      dataIndex: "store_name",
      key: "storeName",
    },
    {
      align: "center",
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Country",
      dataIndex: "store_country",
      key: "country",
      width: 100,
    },
    {
      align: "center",
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      align: "center",
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 80,
    },
    {
      title: "Reg No.",
      dataIndex: "regNumber",
      key: "regNumber",
      width: 100,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "regNumber",
      width: 120,
      render: (date) => <h4>{date}</h4>,
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: () => (
    //     <Dropdown
    //       overlay={
    //         <Menu className="activate-dropdown-menu">
    //           <Menu.Item>Approve</Menu.Item>
    //           <Menu.Item>Reject</Menu.Item>
    //         </Menu>
    //       }
    //       placement="bottomCenter"
    //     >
    //       <MoreOutlined style={{ display: "flex", justifyContent: "center" }} />
    //     </Dropdown>
    //   ),
    //   width: 80,
    // },
  ];

  const deactivateColumns = [
    {
      align: "center",
      key: "checked",
      render: () => <Checkbox />,
      width: 50,
    },
    {
      align: "center",
      dataIndex: "logo",
      key: "logo",
      render: (image) => (
        <div
          className="recent-transaction-image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ),
      width: 80,
    },
    {
      title: "Store Name",
      dataIndex: "store_name",
      key: "storeName",
    },
    {
      align: "center",
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Country",
      dataIndex: "store_country",
      key: "country",
      width: 100,
    },
    {
      align: "center",
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      align: "center",
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 80,
    },
    {
      align: "center",
      title: "Reg No.",
      dataIndex: "regNumber",
      key: "regNumber",
      width: 100,
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      render: (status) => (
        <Tag color="#800000" style={{ textTransform: "capitalize" }}>
          {status}
        </Tag>
      ),
    },
  ];

  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div
              style={{ width: "50%", height: "48px", margin: "16px auto 24px" }}
            >
              <Search
                className="search"
                allowClear
                enterButton="Search"
                // onSearch={onSearch}
                placeholder="Enter Seller Name"
                prefix={<SearchOutlined />}
              />
            </div>

            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div
                  className="manage-payment-body-content-header-title"
                  ref={pendingRef}
                >
                  Activate Stores
                </div>
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div
                  className="manage-payment-body-content-section"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    borderBottom: "1px solid #d4d4d4",
                    paddingBottom: "16px",
                  }}
                >
                  <div>
                    <div className="manage-payment-body-content-trade-section-title">
                      New Stores
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "11px",
                      gap: "16px",
                    }}
                  >
                    {/* <Button className="approve-top-button">Approve All</Button> */}
                  </div>
                </div>

                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={pendingBrands?.data}
                    columns={activateColumns}
                    pagination={false}
                    rowKey={(record) => record.store_id}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          history.push(
                            `/seller/${record.seller_id}/${record.slug}/info`
                          );
                        },
                      };
                    }}
                  />
                  <div className="rows-box">
                    <p
                      className="txt-next"
                      style={{
                        color:
                          pendingBrands?.meta_data?.current_page !== 1
                            ? "black"
                            : "#d9d9d9",
                        justifyContent: "flex-start",
                      }}
                    >
                      <LeftOutlined
                        onClick={() => {
                          fetchNextPendingBrands(
                            info.token,
                            pendingBrands?.meta_data?.prev_page_url
                          );
                          scrollToTable1();
                        }}
                      />
                      <span
                        onClick={() => {
                          fetchNextPendingBrands(
                            info.token,
                            pendingBrands?.meta_data?.prev_page_url
                          );
                          scrollToTable1();
                        }}
                      >
                        Previous
                      </span>
                    </p>

                    <p className="num-text">
                      Page {pendingBrands?.meta_data?.current_page} of{" "}
                      {Math.ceil(
                        pendingBrands?.meta_data?.total /
                          pendingBrands?.meta_data?.per_page
                      )}
                    </p>

                    <p
                      className="txt-next"
                      style={{
                        color:
                          pendingBrands?.meta_data?.current_page !==
                          Math.ceil(
                            pendingBrands?.meta_data?.total /
                              pendingBrands?.meta_data?.per_page
                          )
                            ? "black"
                            : "#d9d9d9",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        onClick={() => {
                          fetchNextPendingBrands(
                            info.token,
                            pendingBrands?.meta_data?.next_page_url
                          );
                          scrollToTable1();
                        }}
                      >
                        Next
                      </span>
                      <RightOutlined
                        onClick={() => {
                          fetchNextPendingBrands(
                            info.token,
                            pendingBrands?.meta_data?.next_page_url
                          );
                          scrollToTable1();
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="manage-listing-all-content"
          style={{ marginTop: "40px" }}
        >
          <div className="manage-payment-coverall-container">
            {/* {isTabletOrMobile && (
            <div className="hello-store-mobile">Hello {details.storeName}</div>
          )} */}
            {/* <div className="manage-payment-adspace"></div> */}

            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div
                  className="manage-payment-body-content-section"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    marginTop: "0px",
                    borderBottom: "1px solid #d4d4d4",
                    paddingBottom: "16px",
                  }}
                >
                  <div>
                    <div
                      className="manage-payment-body-content-trade-section-title"
                      ref={blockedRef}
                    >
                      Deactivated Stores
                    </div>
                  </div>
                </div>

                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={blockedBrands?.data}
                    columns={deactivateColumns}
                    pagination={false}
                    rowKey={(record) => record.transaction_id}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          history.push(
                            `/seller/${record.seller_id}/${record.slug}`
                          );
                        },
                      };
                    }}
                  />

                  <div className="rows-box">
                    <p
                      className="txt-next"
                      style={{
                        color:
                          blockedBrands?.meta_data?.current_page !== 1
                            ? "black"
                            : "#d9d9d9",
                        justifyContent: "flex-start",
                      }}
                    >
                      <LeftOutlined
                        onClick={() => {
                          fetchNextBlockedBrands(
                            info.token,
                            blockedBrands?.meta_data?.prev_page_url
                          );
                          scrollToTable2();
                        }}
                      />
                      <span
                        onClick={() => {
                          fetchNextBlockedBrands(
                            info.token,
                            blockedBrands?.meta_data?.prev_page_url
                          );
                          scrollToTable2();
                        }}
                      >
                        Previous
                      </span>
                    </p>

                    <p className="num-text">
                      Page {blockedBrands?.meta_data?.current_page} of{" "}
                      {Math.ceil(
                        blockedBrands?.meta_data?.total /
                          blockedBrands?.meta_data?.per_page
                      )}
                    </p>

                    <p
                      className="txt-next"
                      style={{
                        color:
                          blockedBrands?.meta_data?.current_page !==
                          Math.ceil(
                            blockedBrands?.meta_data?.total /
                              blockedBrands?.meta_data?.per_page
                          )
                            ? "black"
                            : "#d9d9d9",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        onClick={() => {
                          fetchNextBlockedBrands(
                            info.token,
                            blockedBrands?.meta_data?.next_page_url
                          );
                          scrollToTable2();
                        }}
                      >
                        Next
                      </span>
                      <RightOutlined
                        onClick={() => {
                          fetchNextBlockedBrands(
                            info.token,
                            blockedBrands?.meta_data?.next_page_url
                          );
                          scrollToTable2();
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: 16px;
            margin-bottom: 40px;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
            background: white;
            padding: 24px;
            margin-top: -32px;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-family: "DomaineSansText-Regular";
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }
          .rows-box {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            width: 100%;
          }
          .num-text {
            color: #000000;
            flex-basis: 33%;
            text-align: center;
          }
          .txt-next {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 24px;
            flex-basis: 33%;
          }
          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-family: "DomaineSansText-Regular";
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 200px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 24px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            background-size: cover;
            border-radius: 50%;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }
          .approve-top-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 40px;
            font-family: "DomaineSansText-Light";
            width: 135px;
          }
          .select-top-button {
            color: #000;
            border: 1px solid #000;
            background: #fff;
            height: 40px;
            font-family: "DomaineSansText-Light";
            width: 135px;
          }
          .activate-dropdown-menu {
            font-family: "DomaineSansText-Light";
          }
          .manage-payment-transaction-table .ant-table {
            font-size: 13px;
          }

          @media (max-width: 640px) {
            .manage-payment-coverall-container {
              margin-left: 0;
            }
            .manage-payment-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .manage-payment-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .manage-payment-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .manage-payment-body-content-header-title {
              font-size: 18px;
            }
            .manage-payment-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 180px;
            }

            .manage-payment-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .manage-payment-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .manage-payment-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }
            .manage-payment-body-content-header-subtitle {
              width: 100%;
              font-size: 14px;
            }
            .manage-payment-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
              margin: 0;
            }
            .manage-payment-body-sidebar {
              width: 100%;
              margin-top: 0;
              margin-bottom: 24px;
              padding: 0 16px;
              border: 0;
              margin-right: 0;
              display: flex;
              justify-content: flex-end;
            }
            .manage-payment-body-sidebar-content {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .manage-payment-body-sidebar-content-title {
              font-weight: normal;
              font-size: 12px;
              margin-bottom: 0px;
            }
            .manage-payment-body-sidebar-content-select {
              width: auto;
            }
            .manage-payment-body-sidebar-content-select .ant-select-selector {
              margin: 0 !important;
              height: auto !important;
              font-size: 12px !important;
              padding: 0 16px !important;
            }
            .manage-payment-body-sidebar-content-select .ant-select-arrow {
              top: 50%;
              height: auto;
              width: auto;
            }
            .manage-payment-body-content {
              width: 100%;
              padding: 0 16px;
            }
            .manage-payment-body-content-top-actions {
              gap: 16px;
              margin-top: 16px;
              display: none;
            }
            .manage-payment-payment-summary-section {
              width: 100%;
              flex-direction: column;
              gap: 16px;
            }
            .payment-summary-grey-box {
              padding: 16px;
              font-size: 12px;
            }
            .payment-summary-pink-box {
              padding: 16px;
              width: auto;
              font-size: 12px;
            }
            .payment-summary-big-number {
              font-size: 14px;
            }
            .payment-summary-pink-box-button {
              font-size: 12px;
              padding: 16px;
              line-height: 1;
            }
            .manage-payment-body-content-section {
              margin-top: 24px;
            }
            .manage-payment-body-content-section-subtitle {
              font-size: 14px;
            }
            .payment-summary-underline-link {
              padding-bottom: 0px;
            }
            .manage-payment-transaction-table .ant-table {
              font-size: 12px;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ActivateStore;
