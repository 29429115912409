import React from "react";
import { Col } from "antd";
import Dashboard from "./Dashboard";

const Details = () => {
  const data = [
    { name: "Number of Sellers", value: 3, icon: "/images/Paper.png" },
    { name: "Number of Users", value: 3456, icon: "/images/Paper.png" },
    { name: "Number of Active", value: 3000, icon: "/images/Paper.png" },
    { name: "Total Products", value: 5000, icon: "/images/Buy.svg" },
    { name: "Total Number of Vendors", value: 3450, icon: "/images/Paper.png" },
    {
      name: "Number of Active vendors",
      value: 3450,
      icon: "/images/Paper.png",
    },
    {
      name: "Number of deactivated vendors",
      value: 3,
      icon: "/images/Paper.png",
    },
    { name: "Number of inactive vendors", value: 3, icon: "/images/Paper.png" },
    { name: "Active products", value: 5000, icon: "/images/Paper.png" },
    { name: "Removed Products", value: 320, icon: "/images/Paper.png" },
    { name: "Sold Products", value: 3500, icon: "/images/Paper.png" },
    { name: "Total Products", value: 55000, icon: "/images/Paper.png" },
  ];
  return (
    <Dashboard>
      <div className="details-container">
        {data.map((info) => (
          <Col span={6} key={info.name}>
            <div className="info-box">
              <img src={info.icon} alt="" className="icon" />
              <div className="details">
                <h4 className="name-txt">{info.name}</h4>
                <h4 className="val-txt">{info.value}</h4>
              </div>
            </div>
          </Col>
        ))}
      </div>
      <style jsx="true">{`
        .details-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .info-box {
          background: #ffffff;
          border: 5.8px solid #fae4e4;
          box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
          display: flex;
          margin: 20px;
          padding: 44px 24px;
          width: 270px;
          height: 150px;
        }
        .icon {
          background: #fff2e9;
          opacity: 0.65;
          border-radius: 16px;
          width: 40px;
          height: 40px;
        }
        .details {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
        }
        .name-txt {
          font: normal normal 14px/19px Roboto;
          color: #92959e;
        }
        .value-txt {
          font: normal normal 20px/23px Roboto;
          color: #15192c;
        }
      `}</style>
    </Dashboard>
  );
};

export default Details;
