import React from "react";
import SupplierLayout from "./supplier/assets/LogisticsLayout";

const Dashboard = () => {
  return (
    <SupplierLayout
      headerTitle="THE logistics PORTAL"
      headerSubtitle="Get inisights on all Finance Metrics on Losode"
      currentPageKey="1"
    >
      <div>Dashboard</div>;
    </SupplierLayout>
  );
};

export default Dashboard;
