import axios from "axios";

export const getAllPrices = async (token, menu_id) => {
  try {
    const result = await axios.get(`admin/logistic/pricing/${menu_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getMenu = async (token) => {
  try {
    const result = await axios.get(`admin/logistic/group`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};