import { useHistory } from "react-router-dom";
import { Button } from "antd";

const Notfound = () => {
  const history = useHistory();

  return (
    <div>
      <div className="logo-container">
        <img src="/images/logo.png" alt="logo" className="logo" />
      </div>
      <div className="content">
        <img src="/images/404.gif" alt="not found" className="gif" />
        <p className="txt-404">
          The page you are looking for is not here or does not exist
        </p>
        <Button
          className="btn-home"
          type="primary"
          onClick={() => history.push("/")}
        >
          <span>Go to the main website</span>
        </Button>
      </div>

      <style jsx="true">{`
        .logo-container {
          margin: 25px 28px;
        }
        .logo {
          width: 148px;
        }
        .content {
          display: grid;
          margin-top: 4em;
          place-items: center;
        }
        .gif {
          height: 320px;
          width: 320px;
        }
        .txt-404 {
          font: normal normal 24px/31px DomaineSansText-Regular;
          text-align: center;
          width: 477px;
        }
        .btn-home {
          background: #800000;
          font: normal normal 18px/23px DomaineSansText-Regular;
          height: 63px;
          margin-top: 53px;
          width: 282px;
        }
        .btn-home span {
          cursor: pointer;
          display: inline-block;
          position: relative;
          transition: 0.5s;
        }

        .btn-home span:after {
          content: "\\00bb";
          position: absolute;
          opacity: 0;
          top: 0;
          right: -20px;
          transition: 0.5s;
        }

        .btn-home:hover span {
          background: #800000;
          padding-right: 25px;
        }
        .btn-home:hover {
          background: #800000;
          opacity: 0.8;
        }

        .btn-home:hover span:after {
          opacity: 1;
          right: 0;
        }
      `}</style>
    </div>
  );
};

export default Notfound;
