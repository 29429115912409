export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAILED";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";
