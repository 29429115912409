import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
// import HelpFeedback from "./HelpFeedback";
// import { useState } from "react";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SellerSideNav = ({ currentPageKey }) => {
  // const navigate = useNavigate();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const isTabletOrMobile = false;
  const history = useHistory();

  // const [sideNavFeedbackVisible, setSideNavFeedbackVisible]=useState(false)

  return (
    <div>
      <Sider
        className="sider seller-sidenav"
        style={{ marginTop: 60 }}
        width={240}
      >
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentPageKey]}>
          <Menu.Item
            className="menu-item"
            key="1"
            onClick={() => history.push("/seller-dashboard")}
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1667 7.80998V3.64831C20.1667 2.35581 19.58 1.83331 18.1225 1.83331H14.4192C12.9617 1.83331 12.375 2.35581 12.375 3.64831V7.80081C12.375 9.10248 12.9617 9.61581 14.4192 9.61581H18.1225C19.58 9.62498 20.1667 9.10248 20.1667 7.80998Z"
                  fill="#800000"
                />
                <path
                  d="M20.1667 18.1225V14.4192C20.1667 12.9617 19.58 12.375 18.1225 12.375H14.4192C12.9617 12.375 12.375 12.9617 12.375 14.4192V18.1225C12.375 19.58 12.9617 20.1667 14.4192 20.1667H18.1225C19.58 20.1667 20.1667 19.58 20.1667 18.1225Z"
                  fill="black"
                />
                <path
                  d="M9.62492 7.80998V3.64831C9.62492 2.35581 9.03825 1.83331 7.58075 1.83331H3.87742C2.41992 1.83331 1.83325 2.35581 1.83325 3.64831V7.80081C1.83325 9.10248 2.41992 9.61581 3.87742 9.61581H7.58075C9.03825 9.62498 9.62492 9.10248 9.62492 7.80998Z"
                  fill="black"
                />
                <path
                  d="M9.62492 18.1225V14.4192C9.62492 12.9617 9.03825 12.375 7.58075 12.375H3.87742C2.41992 12.375 1.83325 12.9617 1.83325 14.4192V18.1225C1.83325 19.58 2.41992 20.1667 3.87742 20.1667H7.58075C9.03825 20.1667 9.62492 19.58 9.62492 18.1225Z"
                  fill="#800000"
                />
              </svg>
            }
          >
            My Dashboard
          </Menu.Item>

          <Menu.Item
            className="menu-item"
            key="10"
            onClick={() => history.push("/brands")}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 20H22V18C22 17.3765 21.8057 16.7686 21.4441 16.2606C21.0826 15.7527 20.5718 15.37 19.9827 15.1658C19.3937 14.9615 18.7556 14.9459 18.1573 15.121C17.5589 15.2962 17.03 15.6534 16.644 16.143M17 20H7M17 20V18C17 17.344 16.874 16.717 16.644 16.143M7 20H2V18C2.00005 17.3765 2.19434 16.7686 2.55586 16.2606C2.91739 15.7527 3.42819 15.37 4.01725 15.1658C4.60632 14.9615 5.24438 14.9459 5.84274 15.121C6.4411 15.2962 6.97003 15.6534 7.356 16.143M7 20V18C7 17.344 7.126 16.717 7.356 16.143M7.356 16.143C7.72736 15.215 8.36825 14.4195 9.196 13.8591C10.0238 13.2988 11.0004 12.9993 12 12.9993C12.9996 12.9993 13.9762 13.2988 14.804 13.8591C15.6318 14.4195 16.2726 15.215 16.644 16.143M15 7C15 7.79565 14.6839 8.55871 14.1213 9.12132C13.5587 9.68393 12.7956 10 12 10C11.2044 10 10.4413 9.68393 9.87868 9.12132C9.31607 8.55871 9 7.79565 9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V7ZM21 10C21 10.5304 20.7893 11.0391 20.4142 11.4142C20.0391 11.7893 19.5304 12 19 12C18.4696 12 17.9609 11.7893 17.5858 11.4142C17.2107 11.0391 17 10.5304 17 10C17 9.46957 17.2107 8.96086 17.5858 8.58579C17.9609 8.21071 18.4696 8 19 8C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10ZM7 10C7 10.5304 6.78929 11.0391 6.41421 11.4142C6.03914 11.7893 5.53043 12 5 12C4.46957 12 3.96086 11.7893 3.58579 11.4142C3.21071 11.0391 3 10.5304 3 10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8C5.53043 8 6.03914 8.21071 6.41421 8.58579C6.78929 8.96086 7 9.46957 7 10Z"
                  stroke="#800000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Brands
          </Menu.Item>

          <SubMenu
            className="menu-item"
            key="16"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M14.5 2H6.5C5.96957 2 5.46086 2.21071 5.08579 2.58579C4.71071 2.96086 4.5 3.46957 4.5 4V20C4.5 20.5304 4.71071 21.0391 5.08579 21.4142C5.46086 21.7893 5.96957 22 6.5 22H18.5C19.0304 22 19.5391 21.7893 19.9142 21.4142C20.2893 21.0391 20.5 20.5304 20.5 20V8L14.5 2Z"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 17H8.5"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 13H8.5"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 9H9.5H8.5"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5 2V8H20.5"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            title="Manage Applications"
          >
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/new-applications")}
              key="40"
            >
              New Applications
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/approved-applications")}
              key="43"
            >
              Pending Stores
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/brand-applications/rejected")}
              key="41"
            >
              Rejected Applications
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/brand-applications/waitlist")}
              key="42"
            >
              Waitlist
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            className="menu-item"
            onClick={() =>
              history.push("/add-product", {
                brand: "",
                sellerId: "",
              })
            }
            key="3"
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3136 22L0.332917 15.9098L0 14.5446L7.43058 2.18792L8.24399 1.7213L13.6668 1.67291L13.6771 2.70983L8.522 2.75477L8.17192 2.95524L1.10172 14.7139L1.23214 15.2462L10.4817 20.8905L11.0103 20.7592L18.0839 9.00049L18.0977 8.59609L15.7364 3.94377L16.6527 3.4737L19.1342 8.36451L19.1033 9.3081L19.0312 9.42217L11.6693 21.6613L10.3136 22Z"
                  fill="#010101"
                />
                <path
                  d="M13.8486 20.6935L20.6683 9.35304L20.7095 8.20897L18.1697 3.2041L22 7.00614L18.6571 21.7995L13.8486 20.6935Z"
                  fill="#800000"
                />
                <path
                  d="M15.3862 3.05892L14.9297 2.76512L14.813 2.29159L16.1309 0.114061L16.6114 0L17.0679 0.279969L17.1846 0.756952L15.8598 2.94485L15.3862 3.05892Z"
                  fill="#010101"
                />
                <path
                  d="M12.9185 7.1686L11.755 6.28721L11.5457 4.83898L12.4174 3.66381L13.8589 3.45642L15.0258 4.33435L15.2318 5.78604L14.36 6.95776L12.9185 7.1686ZM12.4586 5.8759L13.121 6.37362L13.9413 6.25611L14.4355 5.58902L14.3188 4.76294L13.6564 4.26176L12.8361 4.38274L12.3556 5.04291L12.4586 5.8759Z"
                  fill="#010101"
                />
              </svg>
            }
          >
            List A New Item
          </Menu.Item>

          <SubMenu
            className="menu-item"
            key="6"
            icon={
              <svg
                width="22"
                height="19"
                viewBox="0 0 14 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4769_19371)">
                  <path
                    d="M13.6349 19H0.365068L0 18.5306L1.41617 5.15239L1.78124 4.78357H8.01435V5.6218H2.10711L0.779139 18.1618H13.2258L12.0963 5.6218H11.6258V4.78357H12.427L12.7945 5.16077L14 18.539L13.6349 19Z"
                    fill="#010101"
                  />
                  <path
                    d="M10.4203 6.52706H9.19527V2.24368L8.45289 1.39706H5.54704L4.80465 2.24368V4.04029H3.57959V1.66529L5.03741 0H8.96006L10.4203 1.66529V6.52706Z"
                    fill="#6B1214"
                  />
                  <path
                    d="M10.1804 8.1281H9.43309L8.82056 7.42957V6.57457L9.43309 5.87604H10.1804L10.7929 6.57457V7.42957L10.1804 8.1281Z"
                    fill="#6B1214"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4769_19371">
                    <rect width="14" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            title="Manage Listings"
          >
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/manage-listings/all")}
              key="4"
            >
              All Listings
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/manage-listings/new")}
              key="5"
            >
              New Listings
            </Menu.Item>
          </SubMenu>
          <SubMenu
            className="menu-item"
            key="2"
            icon={
              <svg
                width="22"
                height="18"
                viewBox="0 0 25 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.84724 8.18493L2.11877 8.81153L1.72351 8.83601L0.97206 8.02094L0.99504 7.60239L1.55116 6.77509C1.41247 6.50839 1.30445 6.22487 1.22944 5.93065L0.294146 5.77155L0 5.49007V4.35926L0.294146 4.07778L1.23863 3.91624C1.31598 3.62214 1.42713 3.33933 1.56954 3.07425L1.01572 2.25673L0.992742 1.83574L1.74419 1.03535L2.13945 1.05983L2.91618 1.65216C3.17348 1.52197 3.4392 1.41154 3.71129 1.32173L3.85836 0.313299L4.12034 0H5.18432L5.44629 0.313299L5.60256 1.31928C5.8778 1.40211 6.1425 1.52048 6.39077 1.67174L7.15831 1.08186L7.55127 1.05738L8.30502 1.85776L8.27285 2.27876L7.71443 3.10606C7.85585 3.37224 7.96621 3.65582 8.04305 3.9505L8.97604 4.1096L9.27248 4.38863V5.52189L8.98064 5.80092L8.04305 5.96736C7.95078 6.25569 7.84022 6.537 7.71213 6.80936L8.27285 7.62442L8.29583 8.04297L7.54208 8.8458L7.13763 8.81153L6.3609 8.21675C6.12315 8.36397 5.87022 8.48142 5.60715 8.56677L5.41872 9.57275L5.15444 9.8885H4.09276L3.82849 9.57275L3.67682 8.56677C3.39215 8.46058 3.11492 8.33298 2.84724 8.18493Z"
                  fill="#6B1214"
                />
                <path
                  d="M5.31524 6.82158L4.02146 6.84606L3.01493 5.97714L2.76904 4.62359L3.3964 3.4169L4.60516 2.92493L5.83 3.37529L6.49642 4.55751L6.2919 5.9184L5.31524 6.82158Z"
                  fill="white"
                />
                <path
                  d="M14.1832 14.7447L13.5122 14.5758L16.0837 3.00086L15.5988 2.34489H9.35742V1.6106H15.7643L16.0354 1.75011L16.7272 2.68267L16.7938 2.99597L14.1832 14.7447Z"
                  fill="#010101"
                />
                <path
                  d="M2.92073 15.2513L2.57373 14.8401L3.02644 10.8847L3.71125 10.9728L3.3045 14.5097L3.93415 14.4999L3.94564 15.2342L2.92073 15.2513Z"
                  fill="#010101"
                />
                <path
                  d="M15.0996 14.4365L12.6223 14.4893L12.6361 15.2236L15.1134 15.1708L15.0996 14.4365Z"
                  fill="#010101"
                />
                <path
                  d="M23.18 15.0481L23.1708 14.3138H23.3156L24.2923 8.02095L22.4929 5.75932H15.9275V5.02502H22.6515L22.9157 5.15475L24.9219 7.67583L25 7.97199L23.9476 14.7348L23.612 15.0432L23.18 15.0481Z"
                  fill="#010101"
                />
                <path
                  d="M8.07989 18L7.85009 17.9706L6.24148 17.1066L6.08062 16.9255L5.36364 15.1558L5.34985 14.9111L5.8692 13.0656L6.00938 12.8648L7.52148 11.8075L7.75128 11.7487L9.54832 11.9715L9.75974 12.084L10.9984 13.4865L11.0926 13.7166L11.1983 15.638L11.1294 15.8828L10.0516 17.4273L9.85396 17.5668L8.07989 18ZM6.66432 16.5118L8.05462 17.2461L9.5713 16.8619L10.4905 15.5328L10.4009 13.8782L9.33001 12.6813L7.78345 12.483L6.48277 13.396L6.03466 14.9845L6.66432 16.5118Z"
                  fill="#010101"
                />
                <path
                  d="M18.8804 18L18.6506 17.9706L17.042 17.1066L16.8812 16.9255L16.1642 15.1558L16.1504 14.9111L16.6697 13.0656L16.8099 12.8648L18.3197 11.8075L18.5495 11.7487L20.3443 11.9715L20.5557 12.084L21.7966 13.4865L21.8908 13.7166L21.9942 15.638L21.9276 15.8828L20.8475 17.4273L20.6499 17.5668L18.8804 18ZM17.4511 16.5118L18.8437 17.2461L20.3581 16.8619L21.2773 15.5328L21.1853 13.8782L20.1168 12.6715L18.5817 12.483L17.281 13.4009L16.8352 14.9894L17.4511 16.5118Z"
                  fill="#010101"
                />
              </svg>
            }
            title="Manage Orders"
          >
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/manage-orders/all")}
              key="24"
            >
              All Orders
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/manage-orders/new")}
              key="25"
            >
              New Orders
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/manage-orders/flagged")}
              key="26"
            >
              Flagged Orders
            </Menu.Item>
          </SubMenu>

          <SubMenu
            className="menu-item"
            key="77"
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                  stroke="#800000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            title="Photoshoots"
          >
            <Menu.Item
              className="menu-item"
              onClick={() =>
                history.push("/new-photoshoots")
              }
              key="78"
            >
              New Requests
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() =>
                history.push("/scheduled-photoshoots")
              }
              key="79"
            >
              Scheduled Photoshoots
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            className="menu-item"
            onClick={() => history.push("/seller-orders")}
            key="11"
            icon={
              <svg
                width="22"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.24751 10.5285L1.88608 11.1017L1.2522 9.46864L2.88217 8.78339L2.87281 10.0738L3.24751 10.5285Z"
                  fill="#6B1214"
                />
                <path
                  d="M19.1819 10.6406H4.75878L3.94067 9.79846V0.832549L4.75878 0H19.1819L20 0.842155V9.80806L19.1819 10.6406ZM5.1491 9.67998H18.7916L19.0632 9.40139V1.23922L18.7916 0.960633H5.1491L4.87744 1.23922V9.40139L5.1491 9.67998Z"
                  fill="#6B1214"
                />
                <path
                  d="M8.68072 3.67285H6.22639L6.00781 3.4519V2.49127L6.22639 2.27032H8.68072L8.8993 2.49127V3.4519L8.68072 3.67285Z"
                  fill="#6B1214"
                />
                <path
                  d="M4.28727 17.0001L3.21936 16.5293L0 8.20386L0.462139 7.11194L2.49805 6.28259L2.84153 7.17598L1.16784 7.85803L1.01483 8.21987L3.94692 15.8057L4.30289 15.9626L15.4754 11.4156L15.822 12.309L4.28727 17.0001Z"
                  fill="black"
                />
              </svg>
            }
          >
            Seller Orders
          </Menu.Item>

          {/* <Menu.Item
            className="menu-item"
            style={{display:!isTabletOrMobile?"none":"flex"}}
            // onClick={() => navigate("/faqs")}
            key="22"
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.08997 8.99996C9.32507 8.33163 9.78912 7.76807 10.3999 7.40909C11.0107 7.05012 11.7289 6.9189 12.4271 7.03867C13.1254 7.15844 13.7588 7.52148 14.215 8.06349C14.6713 8.60549 14.921 9.29148 14.92 9.99996C14.92 12 11.92 13 11.92 13" stroke="#6B1214" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 17H12.01" stroke="#6B1214" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            }
          >
            Seller FAQs
          </Menu.Item> */}
          <Menu.Item
            className="menu-item"
            style={{ display: !isTabletOrMobile ? "none" : "flex" }}
            // onClick={() => setSideNavFeedbackVisible(true)}
            key="23"
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.08997 8.99996C9.32507 8.33163 9.78912 7.76807 10.3999 7.40909C11.0107 7.05012 11.7289 6.9189 12.4271 7.03867C13.1254 7.15844 13.7588 7.52148 14.215 8.06349C14.6713 8.60549 14.921 9.29148 14.92 9.99996C14.92 12 11.92 13 11.92 13"
                  stroke="#6B1214"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 17H12.01"
                  stroke="#6B1214"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Feedback
          </Menu.Item>
        </Menu>
      </Sider>

      {/* <HelpFeedback sideNavFeedbackVisible={sideNavFeedbackVisible} setSideNavFeedbackVisible={setSideNavFeedbackVisible} /> */}

      <style>
        {`
            .seller-sidenav{
              font-family: 'DomaineSansText-Light';
            }

            .ant-menu-dark.ant-menu-inline, 
            .ant-menu-dark.ant-menu-vertical, 
            .ant-menu-dark.ant-menu-vertical-left, 
            .ant-menu-dark.ant-menu-vertical-right{
                background: white;
            }

            .ant-menu-item .ant-menu-item-icon + span, 
            .ant-menu-submenu-title .ant-menu-item-icon + span, 
            .ant-menu-item .anticon + span, 
            .ant-menu-submenu-title .anticon + span, .ant-menu-dark .ant-menu-item-selected 
            .ant-menu-item-icon + span, .ant-menu-dark
            .ant-menu-item-selected .anticon + span{
                color: black;
            }
            .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: rgba(0, 0, 0, 0.04);
            }
            .ant-layout-sider{
                background:white;
                border-right: 1px solid #e4e4e4;
                height:100%;
                position:fixed;
           

            }

            .ant-menu-dark.ant-menu-inline .ant-menu-item,
            .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
              align-items: center; 
              color:#000;
              display: flex;
              padding: 16px;
              width: 100%;
            }
        
            .ant-menu-dark .ant-menu-sub,
            .ant-menu.ant-menu-dark .ant-menu-sub {
              background: #fff;
              color: #fff;
            }
            .ant-menu.ant-menu-dark .sub-item {
              background: #252525;
              color: #fff;
              margin: 1px;
            }
          
           .ant-menu-submenu-arrow::before,.ant-menu-submenu-arrow::after {
              background: #000 !important;
              margin:20%;
            }
            .ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-vertical-left, .ant-menu-dark.ant-menu-vertical-right{
              margin-top:96px
            }

            @media (max-width: 640px) {
              .ant-layout-sider{
                border-right: 0

              }
            }
            `}
      </style>
    </div>
  );
};

export default SellerSideNav;
