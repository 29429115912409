import axios from "axios";

export const registerSeller = async (token, user) => {
  try {
    const result = await axios.post("admin/create/seller", user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createStore = async (token, values) => {
  try {
    const result = await axios.post("admin/create/brand", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const createProduct = async (values, token) => {
  try {
    const result = await axios.post("admin/create/listing", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllListings = async (token) => {
  try {
    const result = await axios.get("admin/all/listing", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getNewListings = async (token) => {
  try {
    const result = await axios.get("admin/new/listing", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAdminStats = async (token) => {
  try {
    const result = await axios.get("admin/dashboard/figure", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAdminPendingInfo = async (token) => {
  try {
    const result = await axios.get("admin/pending/approval", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getBanks = async (token) => {
  try {
    const result = await axios.get("admin/brand/get/banks/NG", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBrands = async (token) => {
  try {
    const result = await axios.get("admin/all/brands", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getPendingBrands = async (token) => {
  try {
    const result = await axios.get("admin/pending/brands", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getActivatedBrands = async (token) => {
  try {
    const result = await axios.get("admin/unpublished/brands", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBlockedBrands = async (token) => {
  try {
    const result = await axios.get("admin/blocked/brands", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const togglePublishState = async (token, id) => {
  try {
    const result = await axios.post(
      `admin/toggle/publish/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getPage = async (token, url) => {
  try {
    const result = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCategories = async (token) => {
  const result = await axios.get("categories", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data.data;
};
export const getBrandsPaginated = async (token, page) => {
  const result = await axios.get(`admin/brands?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const getBrand = async (token, sellerId) => {
  const result = await axios.get(`admin/brand/${sellerId} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data.data;
};
export const getBrandDetails = async (token, sellerId) => {
  const result = await axios.get(`admin/store/details/${sellerId} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data.data;
};
export const getBrandStats = async (token, sellerId) => {
  const result = await axios.get(`admin/seller/dashboard/count/${sellerId} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data.data;
};
export const getBrandListings = async (token, sellerId, page) => {
  try {
    const result = await axios.get(
      `admin/all/listing/${sellerId}?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getBrandPayment = async (token, sellerId) => {
  const result = await axios.get(`admin/payment/details/${sellerId} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data.data;
};
export const getBrandBankInfo = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/bank/details/${sellerId} `, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getApprovedProducts = async (token, brandId) => {
  const result = await axios.get(`admin/brand/approved/products/${brandId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
export const getBrandProducts = async (token, brandId) => {
  const result = await axios.get(`admin/brand/all/products/${brandId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
export const getNewProducts = async (token, brandId) => {
  const result = await axios.get(`admin/brand/new/products/${brandId} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
export const getProduct = async (token, productId) => {
  try {
    const result = await axios.get(`admin/brand/listing/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBrandDeliveryOption = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/view/brand/delivery/${sellerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBrandReturnsOption = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/view/brand/returns/${sellerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBrandVacationMode = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/view/brand/vacation/${sellerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getBrandSizeGuide = async (token, sellerId) => {
  try {
    const result = await axios.get(`admin/view/brand/sizeguide/${sellerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const resetBrandPassword = async (token, sellerId) => {
  try {
    const result = await axios.post(
      `admin/reset/seller/password/${sellerId} `,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateBrands = async (values, token) => {
  try {
    const result = await axios.post("admin/edit/brand", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateBrandDelivery = async (values, token) => {
  try {
    const result = await axios.post("admin/edit/brand/delivery", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateBrandReturns = async (values, token) => {
  try {
    const result = await axios.post("admin/edit/brand/returns", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const activateVacationMode = async (token, values) => {
  try {
    const result = await axios.post("admin/activate/vacation", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deactivateVacationMode = async (token, sellerId) => {
  try {
    const result = await axios.post(
      `admin/deactivate/vacation/${sellerId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateBrandSecurity = async (id, values, token) => {
  try {
    const result = await axios.post(
      `admin/seller/update/password/${id}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateBrandDetails = async (id, values, token) => {
  const result = await axios.post(`admin/seller/update/store/${id}`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
export const updateBrandAccount = async (id, values, token) => {
  try {
    const result = await axios.post(
      `admin/seller/update/details/${id}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const searchBrand = async (token, value) => {
  const result = await axios.post("admin/brand/search", value, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
export const activateBrand = async (token, { brandId, publish }) => {
  try {
    const result = await axios.post(
      `admin/activate/brand/${brandId}`,
      { publish },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deactivateBrand = async (token, storeId) => {
  try {
    const result = await axios.post(`admin/deactivate/brand/${storeId}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const blockBrand = async (token, values) => {
  try {
    const result = await axios.post(
      `admin/block/seller/${values.sellerId}`,
      { reason: values.reason },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const unblockBrand = async (token, sellerId) => {
  try {
    const result = await axios.post(`admin/activate/seller/${sellerId}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const approveProduct = async (token, id) => {
  try {
    const result = await axios.post(`admin/approve/listing/${id}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const rejectProduct = async (token, id, reason) => {
  try {
    const result = await axios.post(
      `admin/reject/listing/${id}`,
      { reason },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const activateProduct = async (token, id) => {
  try {
    const result = await axios.post(`admin/product/activate/${id}}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deactivateProduct = async (token, id) => {
  try {
    const result = await axios.post(`admin/product/deactivate/${id}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteProduct = async (token, productId) => {
  try {
    const result = await axios.post(
      `admin/product/delete/${productId}`,
      { product_id: productId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchBanks = async (token) => {
  try {
    const result = await axios.get("admin/brand/get/banks/NG", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateProduct = async (token, values) => {
  try {
    const result = await axios.post(`admin/update/product`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const uploadSizeGuide = async (token, values) => {
  try {
    const result = await axios.post(`/admin/edit/brand/sizeguide`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteSizeGuide = async (token, sellerId) => {
  try {
    const result = await axios.post(
      `admin/delete/brand/sizeguide/${sellerId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const addVariant = async (token, values) => {
  try {
    const result = await axios.post(`admin/add/variant`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateVariant = async (token, values) => {
  try {
    const result = await axios.post(`admin/update/variant`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteVariant = async (token, variant_id, product_id) => {
  try {
    const result = await axios.post(
      `admin/delete/variant`,
      { variant_id, product_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateQuantity = async (token, values) => {
  try {
    const result = await axios.post(`admin/update/listing/variety`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const swapImage = async (token, data) => {
  try {
    const result = await axios.post(`admin/product/image/swap`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteImage = async (token, data) => {
  try {
    const result = await axios.post(`admin/product/image/delete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchBrandApplications = async (type, token) => {
  try {
    const result = await axios.get(`application/${type}/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchBrandApplicationDetails = async (token, id) => {
  try {
    const result = await axios.get(`application/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const admimApproveBrand = async (token, data) => {
  try {
    const result = await axios.post(`application/approve`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateNewSellerInfoApi = async (token, values) => {
  try {
    const result = await axios.post(`update/application`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const bookPhotoshoot = async (token, values) => {
  try {
    const result = await axios.post(`admin/photoshoot/book/date`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPhotoshootBookings = async (token) => {
  try {
    const result = await axios.get(`admin/photoshoots`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPhotoshootDetails = async (token, id) => {
  try {
    const result = await axios.get(`/admin/photoshoots/${id} `, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
