import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { brandActionCreators } from "../../redux/seller";
import { Form, Input, Select, Button, Row, Col, message } from "antd";
import axios from "axios";
import Dashboard from "./Dashboard";

const { Option } = Select;

const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [form] = Form.useForm();
  const [imageUrl, setUrl] = useState("");
  const hiddenFileInput = useRef(null);

  const { brandDetails, brand, banks } = useSelector((state) => state.brands);
  const { info } = useSelector((state) => state.user);

  const {
    updateBrandAccount,
    updateBrandDetails,
    updateBrandSecurity,
    fetchBanks,
    fetchBrandDetails,
  } = bindActionCreators(brandActionCreators, dispatch);

  const selectedContent = (id) => {
    const prevBtn = document.getElementById(active);
    prevBtn.classList.remove("selected");
    const activeBtn = document.getElementById(id);
    activeBtn.classList.add("selected");
    setActive(id);
  };

  const onFinish = async (values) => {
    const res = await updateBrandAccount(brand.seller_id, values, info.token);
    if (res.status === "not ok") {
      message.error(res.message);
    }
    if (res.status === "ok") {
      message.success(res.message);
      fetchBrandDetails(info.token, brand.seller_id);
    }
  };
  const updateSecurity = async (values) => {
    const res = updateBrandSecurity(brand.seller_id, values, info.token);
    if (res.status === "not ok") {
      message.error(res.message);
    }
    if (res.status === "ok") {
      message.success(res.message);
    }
  };
  const updateStoreInfo = async (values) => {
    const data = { ...values, logoUrl: imageUrl };
    const res = await updateBrandDetails(brand.seller_id, data, info.token);
    if (res.status === "not ok") {
      message.error(res.message);
    }
    if (res.status === "ok") {
      message.success(res.message);
      fetchBrandDetails(info.token, brand.seller_id);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    uploadImage(fileUploaded);
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };

  const getImgName = (string) => {
    const result = string?.match(/[\w.$]+(?=png|jpg|gif)\w+/g);
    if (result) return result[0];
  };

  useEffect(() => {
    fetchBanks(info.token);
    document.getElementById(active).classList.add("selected");
    form.setFieldsValue({
      logoUrl: getImgName(brandDetails?.logoUrl),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard>
      <div className="div-back" onClick={() => history.goBack()}>
        <img src="/images/arrow-back.svg" alt="back" />
        <span className="txt-back">Back to Sellers Information</span>
      </div>
      <div className="settings-container">
        <h1 className="txt-title">Edit Seller profile</h1>
        <sub className="txt-sub">Seller Account Information</sub>

        <div className="tabs-container">
          <Button
            id={1}
            className="btn-tab"
            type="text"
            onClick={() => selectedContent(1)}
          >
            Sellers Information
          </Button>
          <span className="line" />
          <Button
            id={2}
            className="btn-tab"
            type="text"
            onClick={() => selectedContent(2)}
          >
            Business Information
          </Button>
          <span className="line" />
          <Button
            id={3}
            className="btn-tab"
            type="text"
            onClick={() => selectedContent(3)}
          >
            Security Settings
          </Button>
        </div>

        <div className="content">
          {active === 1 && (
            <div className="seller-info">
              <Form
                form={form}
                name="address"
                initialValues={brandDetails}
                onFinish={onFinish}
                scrollToFirstError
              >
                <Row className="inputs-container" gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Email Address" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Phone Number" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="storeName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your store name!",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Store Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="storeAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please input your store address!",
                        },
                        {
                          max:45,
                          message:"Please limit your address to 45 characters or less"
                        }
                      ]}
                    >
                      <Input className="input" placeholder="Store Address" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="landmark"
                      rules={[
                        {
                          required: true,
                          message: "Please input nearest landmark",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Nearest Landmark" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="storeCountry"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Country"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="Nigeria">Nigeria</Option>
                        <Option value="USA">USA</Option>
                        <Option value="UK">UK</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item>
                      <Button className="btn-save" htmlType="submit">
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          {active === 2 && (
            <div className="seller-info">
              <Form
                form={form}
                name="business"
                initialValues={brandDetails}
                onFinish={updateStoreInfo}
                scrollToFirstError
              >
                <Row className="inputs-container" gutter={20}>
                  <Col span={24}>
                    <Form.Item name="regNumber">
                      <Input
                        className="input"
                        placeholder="Business Registration Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="storeName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your store name!",
                        },
                      ]}
                    >
                      <Input className="input" placeholder="Store Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="logoUrl"
                      rules={[
                        {
                          required: true,
                          message: "Select company logo",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Add your business logo"
                        onChange={uploadImage}
                        prefix={
                          <img
                            className="brand-logo"
                            alt="logo"
                            src={brandDetails.logoUrl}
                          />
                        }
                        suffix={
                          <>
                            <Button
                              className="btn-upload"
                              onClick={handleClick}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "end",
                                }}
                              >
                                <img alt="upload" src="/images/upload.png" />
                                <span style={{ fontSize: 18 }}>Browse</span>
                              </div>
                            </Button>
                            <input
                              accept="image/*"
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: "none" }}
                            />
                          </>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input your store description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 8 }}
                        className="input"
                        placeholder="Store Description"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button className="btn-save" htmlType="submit">
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          {active === 3 && (
            <div className="seller-info">
              <Form
                form={form}
                name="security"
                initialValues={brandDetails}
                onFinish={updateSecurity}
                scrollToFirstError
              >
                <Row className="inputs-container" gutter={20}>
                  <Col span={12}>
                    <Form.Item name="oldPassword">
                      <Input
                        className="input"
                        placeholder="Current Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="newPassword"
                      rules={[
                        {
                          min: 8,
                          message: "Password should have at least 8 characters",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="accountNumber">
                      <Input className="input" placeholder="Account number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="bankName">
                      <Select
                        className="select"
                        placeholder="Select bank name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {banks?.map((bank, idx) => (
                          <Option value={bank.name} key={idx}>
                            {bank.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item>
                      <Button className="btn-save" htmlType="submit">
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>

        <style jsx="true">{`
          .settings-container {
            margin: 10px 0px;
          }
          .div-back {
            cursor: pointer;
          }
          .txt-back {
            font: normal normal 16px/19px DomaineSansText-Regular;
            color: #545f7d;
            margin-left: 14.41px;
          }
          .txt-title {
            font: normal 500 30px/39px DomaineSansText-Regular;
            color: #000000;
            margin: 0;
          }
          .txt-sub {
            font: normal 500 18px/23px DomaineSansText-Regular;
            color: #707070;
          }
          .tabs-container {
            margin-top: 35px;
            background: #f2f2f2;
            border: 1px solid #d4d4d4;
            box-sizing: border-box;
            display: flex;
            height: 48px;
            justify-content: space-between;
            align-items: center;
          }
          .btn-tab {
            width: 238px;
            height: 41px;
          }
          .ant-btn-text:active,
          .ant-btn-text:focus,
          .selected {
            background: #000;
            color: #fff;
          }
          .line {
            width: 0px;
            height: 16.5px;
            opacity: 0.17;
            border: 1px solid #000000;
          }
          .content {
            margin-top: 34px;
          }
          .input {
            height: 62px;
            padding: 4px 0px 4px 11px;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            border-color: #ccc;
            font-size: 14px;
            height: 62px;
            padding: 0px;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            color: #32353c;
            font: normal normal 14px/18px DomaineSansText-Regular;
            display: flex;
            padding: 15px;
          }
          .input:focus,
          .input:hover,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector:hover {
            border-color: #000;
          }
          .brand-logo {
            width: 42px;
            height: 42px;
          }
          .btn-save {
            background: #000000;
            color: #fff;
            float: right;
            height: 63px;
            width: 195px;
          }
          .btn-upload,
          .btn-upload:hover {
            background: #800000;
            color: #fff;
            height: 61px;
            width: 151px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default EditProfile;
