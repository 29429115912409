import {
  GET_APPROVED_ORDERS,
  GET_CANCELED_ORDERS,
  GET_ALL_ORDERS,
  GET_ORDER_FAILED,
  GET_ONE_ORDER,
  GET_SELLER_PENDING_ORDERS,
  GET_ORDER_STATS,
} from "./type";

const initialState = {
  error: false,
  message: "",
  orderStats: {},
  order: {},
  ordersObj: {},
  approved: [],
  pendingOrdersObj: [],
  canceled: [],
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS: {
      return { ...state, error: action.error, ordersObj: action.payload };
    }
    case GET_ORDER_STATS: {
      return { ...state, error: action.error, orderStats: action.payload };
    }
    case GET_SELLER_PENDING_ORDERS: {
      return {
        ...state,
        error: action.error,
        pendingOrdersObj: action.payload,
      };
    }
    case GET_ONE_ORDER: {
      return { ...state, error: action.error, order: action.payload };
    }
    case GET_APPROVED_ORDERS: {
      return { ...state, error: action.error, approved: action.payload };
    }
    case GET_ORDER_FAILED: {
      return {
        ...state,
        error: action.error,
        message: action.payload,
      };
    }

    case GET_CANCELED_ORDERS: {
      return { ...state, error: action.error, canceled: action.payload };
    }
    default:
      return state;
  }
};

export default ordersReducer;
