import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import { Button, Form, Input, message } from "antd";

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const { login, getUser } = bindActionCreators(userActionCreators, dispatch);
  const user = useSelector((state) => state.user);

  const onSubmit = (values) => {
    login(values);
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      history.push("/");
      getUser(user.info.token);
    }
    if (user.error) message.error(user.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="login-container">
      <img
        className="main-login-logo"
        src="/images/logo-white-no-tagline.png"
        alt="logo"
      />
      <div className="left-div">
        <div className="form-container">
          <h1 className="txt-welcome">Losode Power Center</h1>
          <p className="txt-desc">Admin Access Only. Sign In</p>
          <Form
            className="form"
            form={form}
            layout="vertical"
            name="register"
            onFinish={onSubmit}
            requiredMark={false}
            scrollToFirstError
          >
            <Form.Item
              // label="Email address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your email address",
                },
              ]}
            >
              <Input className="input" placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter user password",
                },
              ]}
            >
              <Input.Password className="input" type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button className="btn-add" htmlType="submit">
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* <div className="right-div" /> */}
      <style jsx="true">{`
        .login-container {
          display: flex;
          width: 100%;
          font-family: "DomaineSansText-Light";
        }
        .left-div {
          align-self: center;
          margin-left: 60px;
          width: 40%;
          background: white;
          padding: 40px 50px 30px;
        }
        .login-container {
          background-image: url("/images/main-img.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100vh;
        }
        .main-login-logo {
          width: 165px;
          position: absolute;
          right: 50px;
          top: 40px;
        }
        .form-container {
        }
        .form {
          margin-top: 40px;
        }
        .txt-welcome {
          margin: 0px;
          font-family: "DomaineSansDisplay-Bold";
          font-size: 40px;
        }
        .txt-desc {
          color: #707070;
          font: normal normal 14px/146.16% DomaineSansText-Regular;
          margin: 0px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          font: normal 700 13px/146.16% DomaineSansText-Regular;
          color: #677189;
        }
        .input {
          box-sizing: border-box;
          height: 48px;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid black;
          background: transparent;
          padding: 0;
        }

        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .btn-add {
          height: 48px;
          width: 100%;
          margin-top: 40px;
          background: black;
          color: white;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 4px;
        }
      `}</style>
    </div>
  );
};

export default Login;
