const occasion = [
  "Weddings",
  "Formal Wear",
  "The Beach",
  "Working Out",
  "Sports wear",
  "Party wear",
  "Work wear",
  "Casual Wear",
];

export default occasion;
