import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  Button,
  Radio,
  Select,
  message,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { searchBuyer } from "../../../../api/buyersApi";

const BlockBuyer = ({ blockBuyerModalVisible, setBlockBuyerModalVisible }) => {
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [notFoundModalVisible, setNotFoundModalVisible] = useState(false);

  const [blockReason, setBlockReason] = useState("");
  const [buyer, setBuyer] = useState({});
  const [buyForm] = Form.useForm();

  const { info } = useSelector((state) => state.user);

  const searchUser = async (val) => {
    const res = await searchBuyer(info.token, val);

    if (res.status === "not ok") message.info(res.message);

    if (res.status === "ok") {
      setBuyer(res.data);
      setInfoModalVisible(true);
      setBlockBuyerModalVisible(false);
    }
  };

  return (
    <div>
      <div>
        <Modal
          centered
          visible={blockBuyerModalVisible}
          onCancel={() => setBlockBuyerModalVisible(false)}
          footer={null}
          className="BlockBuyerModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ marginBottom: "40px" }}>
            <div className="shop-buyer-modal-form-title">Block Buyer</div>
            <div>Kindy enter customer email address</div>
          </div>

          <Form
            form={buyForm}
            name="buyForm"
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            onFinish={searchUser}
          >
            <div style={{ marginBottom: "8px" }}>Email Address</div>

            <Row className="form-row" gutter={[24, 12]}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  // label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="Enter your email address"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button className="btn-save" htmlType="submit">
                  Find Customer
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          centered
          visible={infoModalVisible}
          onCancel={() => setInfoModalVisible(false)}
          footer={null}
          className="BlockBuyerModal personal-info-section"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ marginBottom: "24px" }}>
            <div className="shop-buyer-modal-form-title">
              Customer Information
            </div>
          </div>

          <table style={{ marginBottom: "24px" }}>
            <tbody>
              <tr>
                <td className="personal-info-label">Name</td>
                <td>{buyer?.name}</td>
              </tr>
              <tr>
                <td className="personal-info-label">Email Address</td>
                <td>{buyer?.email}</td>
              </tr>
              <tr>
                <td className="personal-info-label">Phone Number</td>
                <td>{buyer?.phone}</td>
              </tr>
              <tr>
                <td className="personal-info-label">Gender</td>
                <td>{buyer?.gender}</td>
              </tr>
              <tr>
                <td className="personal-info-label">Date Of Birth</td>
                <td>{buyer?.dob}</td>
              </tr>
              <tr>
                <td
                  className="personal-info-label"
                  style={{ verticalAlign: "top" }}
                >
                  Address
                </td>
                <td>
                  Joe Blogg Williams <br />
                  1, Northfield drive, <br />
                  Chestire WA165 <br />
                  United Kingdom
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <Button
              className="btn-save"
              style={{ width: "60%" }}
              //   onClick={() => {
              //     setConfirmModalVisible(true);
              //     setInfoModalVisible(false);
              //   }}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal
          centered
          visible={confirmModalVisible}
          onCancel={() => setConfirmModalVisible(false)}
          footer={null}
          className="BlockBuyerModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ padding: "0 20px", fontSize: "14px" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              Why are you blocking this customer?
            </div>
            <div
              style={{
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              It is important that your store is kept up to date, multiple order
              rejections may lead to us closing your store
            </div>
            <div>
              <Radio.Group
                onChange={(e) => setBlockReason(e.target.value)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "8px",
                  margin: "30px auto 16px",
                }}
              >
                <Radio value="out of stock">
                  The item is out of stock and there are no alternatives
                </Radio>
                <Radio value="longer than planned">
                  The item is available but will take longer than planned
                </Radio>
                <Radio value="alternate colors">
                  The item is available but in a different colour
                </Radio>
                <Radio value="reason not here">My reason is not here</Radio>
              </Radio.Group>
              {blockReason === "reason not here" && (
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="no-reason-textbox"
                  placeholder="Please enter your reason for rejecting this order"
                />
              )}
            </div>

            <div>
              <div style={{ margin: "40px 0 8px" }}>
                How long are you blocking this buyer?
              </div>
              <Select
                className="block-reason-select"
                placeholder="Select An Option"
                defaultValue={""}
              >
                <Select.Option value="1M">One Month</Select.Option>
                <Select.Option value="6M">Six Month</Select.Option>
                <Select.Option value="1Y">One Year</Select.Option>
                <Select.Option value="100">Indefinitely</Select.Option>
              </Select>
            </div>

            <div style={{ display: "flex", gap: "40px", marginTop: "40px" }}>
              <Button
                className="btn-cancel"
                onClick={() => {
                  setConfirmModalVisible(false);
                  setBlockBuyerModalVisible(true);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-save"
                // onClick={() => {
                //   setConfirmModalVisible(false);
                // }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          centered
          visible={notFoundModalVisible}
          onCancel={() => setNotFoundModalVisible(false)}
          footer={null}
          className="BlockBuyerModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ fontSize: "18px", margin: "24px 0 40px" }}>
            This Customer Cannot be found.
          </div>

          <div>
            <Button
              className="btn-cancel"
              style={{ width: "60%" }}
              onClick={() => {
                setNotFoundModalVisible(false);
              }}
            >
              Find another Customer
            </Button>
          </div>
        </Modal>
      </div>

      <style>
        {`
                    .BlockBuyerModal{
                        font-family: 'DomaineSansText-Light';
                    }
                    .BlockBuyerModal .ant-modal-body{
                        padding:40px 40px 60px;
                        color:black
                    }
                    .shop-buyer-modal-form-title{
                        font-family: 'DomaineSansText-Regular';
                        font-size: 24px;
                    }
                    .btn-save {
                        background: #000000;
                        border-color: #000;
                        color: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .btn-cancel{
                        color: #000000;
                        border-color: #000;
                        background: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .input{
                        height:48px
                    }
                    .ant-form-item-explain-error{
                        margin-top:4px
                    }

                    .personal-info-section table {
                        border-collapse: unset;
                        height: fit-content;
                      }
                      .personal-info-section td {
                        padding: 0 20px 12px 0;
                        font-family: "DomaineSansText-Regular";
                      }
                      .personal-info-label {
                        color: #707070;
                        width: 175px;
                      }

                    .block-reason-select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
                        height:48px;
                        width:300px
                    }
                    .block-reason-select.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                        line-height: 45px;
                    }
                `}
      </style>
    </div>
  );
};

export default BlockBuyer;
