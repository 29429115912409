import windowSize from "react-window-size";
import router from "./routes/routes";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MobileView from "./components/MobileView";

const App = ({ windowWidth }) => {
  return (
    <div className="App">{windowWidth < 1025 ? <MobileView /> : router}</div>
  );
};

export default windowSize(App);
