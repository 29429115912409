import axios from "axios";

const getLatLng = async (values) => {
  try {
    const api = `https://maps.googleapis.com/maps/api/geocode/json?address=${values.address},${values.state},${values.country}&key=AIzaSyBqGKL75wmtYE2CZ4nT9TqMVqVuzApo_gI`;
    const result = await axios.get(api);
    return `${result.data.results[0].geometry.location.lat},${result.data.results[0].geometry.location.lng}`;
  } catch (e) {
    console.log(e.message);
  }
};

export default getLatLng;
