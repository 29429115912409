import React from "react";

const MobileView = () => {
  return (
    <div>
      <div className="logo-container">
        <hr className="line" />
        <img src="/images/logo.png" alt="logo" className="logo" />
        <hr className="line" />
      </div>
      <div className="content">
        <img src="/images/laptop.gif" alt="laptop table" className="img" />
        <p className="txt-desc">
          Hey, sorry about this but this page cannot be viewed on your mobile
          phone, switch to your PC to continue
        </p>
      </div>
      <style jsx="true">{`
        .logo {
          display: block;
          margin: 0 auto;
          width: 148px;
        }
        .line {
          position: relative;
          border: none;
          height: 0.2px;
          background: #92959e;
          box-sizing: border-box;
        }
        .content {
          margin-top: 10em;
        }
        .img {
          display: block;
          margin: 0 auto;
        }
        .txt-desc {
          font: normal normal 16px/146.16% DomaineSansText-Regular;
          text-align: center;
          color: #000000;
          margin: 31px auto;
          width: 246px;
        }
      `}</style>
    </div>
  );
};

export default MobileView;
