import { Button } from "antd";
import React from "react";
// import { useHistory } from "react-router-dom";
import SupplierLayout from "./assets/SupplierLayout";

const SupplierInfo = () => {
  //   const history = useHistory();

  return (
    <SupplierLayout
      headerTitle="The FINANCE PORTAL"
      headerSubtitle="Get insights on all finance metrics on Losode"
      dashboardBgColor="#fff0"
    >
      <div className="order-details-container">
        <div className="manage-listing-all-content">
          <div className="order-details-coverall-container">
            <div className="order-details-main-content">
              <div style={{ background: "white" }}></div>

              <div style={{ padding: "16px 40px" }}>
                <div
                  className="section-in-order-details delivery-address-section"
                  style={{
                    marginTop: "0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="order-details-section-title">
                    Supllier Profile
                  </div>

                  <Button
                    style={{
                      background: "black",
                      color: "white",
                      height: "48px",
                      padding: "0 50px",
                    }}
                  >
                    Delete
                  </Button>
                </div>

                <div
                  className="section-in-order-details order-info-section"
                  style={{ padding: "20px" }}
                >
                  <div
                    style={{
                      border: " 1px solid #D4D4D4",
                      margin: "16px",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 30,
                      }}
                    >
                      <div className="order-details-section-title">
                        Basic Information
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.332 1.50004C10.5071 1.32494 10.715 1.18605 10.9438 1.09129C11.1725 0.996527 11.4177 0.947754 11.6654 0.947754C11.913 0.947754 12.1582 0.996527 12.387 1.09129C12.6157 1.18605 12.8236 1.32494 12.9987 1.50004C13.1738 1.67513 13.3127 1.883 13.4074 2.11178C13.5022 2.34055 13.551 2.58575 13.551 2.83337C13.551 3.08099 13.5022 3.32619 13.4074 3.55497C13.3127 3.78374 13.1738 3.99161 12.9987 4.16671L3.9987 13.1667L0.332031 14.1667L1.33203 10.5L10.332 1.50004Z"
                            stroke="black"
                            stroke-width="0.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span style={{ marginLeft: "4px" }}>Edit</span>
                      </div>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td className="order-info-label">Company Name</td>
                          {/* <td>{order.sold_on}</td> */}
                          <td>KK Glocal Logistics</td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Contact Person</td>
                          {/* <td>{order.OrderNumber}</td> */}
                          <td>02-0345-0543</td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Supplier Id</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Email</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Phone Number</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Address</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">State</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Country</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">TIN Number</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">RC Number</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Currency</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            1500
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span
                      style={{
                        border: "0.7px solid #D4D4D4",
                        display: "inline-block",
                        margin: "20px",
                        width: "95%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 30,
                      }}
                    >
                      <div className="order-details-section-title">
                        Payment Information
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.332 1.50004C10.5071 1.32494 10.715 1.18605 10.9438 1.09129C11.1725 0.996527 11.4177 0.947754 11.6654 0.947754C11.913 0.947754 12.1582 0.996527 12.387 1.09129C12.6157 1.18605 12.8236 1.32494 12.9987 1.50004C13.1738 1.67513 13.3127 1.883 13.4074 2.11178C13.5022 2.34055 13.551 2.58575 13.551 2.83337C13.551 3.08099 13.5022 3.32619 13.4074 3.55497C13.3127 3.78374 13.1738 3.99161 12.9987 4.16671L3.9987 13.1667L0.332031 14.1667L1.33203 10.5L10.332 1.50004Z"
                            stroke="black"
                            stroke-width="0.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span style={{ marginLeft: "4px" }}>Edit</span>
                      </div>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td className="order-info-label">Bank Name</td>
                          {/* <td>{order.sold_on}</td> */}
                          <td>KK Glocal Logistics</td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Account Name</td>
                          {/* <td>{order.OrderNumber}</td> */}
                          <td>02-0345-0543</td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Account Number</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">
                            Intermediary Bank’s Name
                          </td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">Swift Code</td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                        <tr>
                          <td className="order-info-label">
                            Intermediary Bank’s Swift
                          </td>
                          <td>
                            {/* {formatter(order.totalAmount, rate, code, indicator)} */}
                            02-0345-0543
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .manage-listing-all-content {
          position: relative;
        }
        .order-details-body-content-header-container {
          margin-bottom: 24px;
        }
        .order-details-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px 0;
          background: white;
        }
        .order-details-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .order-details-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }
        .order-details-container {
          font-family: DomaineSansText-Light;
          background: #f7f7f7;
        }
        .order-details-main-content {
        }
        .order-details-adspace {
          background-image: url("/images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          margin: auto;
        }
        .section-in-order-details-row {
          padding: 20px 40px;
          background: white;
          display: flex;
          gap: 40px;
          margin-bottom: 40px;
        }
        .section-in-order-details {
          padding: 20px 40px;
          background: white;
          display: flex;
          gap: 16px;
          flex-direction: column;
          margin-bottom: 40px;
        }
        .order-details-section-title {
          font-weight: bold;
          min-width: 150px;
          font-size: 18px;
        }
        .order-info-section table {
          border-collapse: unset;
        }
        .order-info-section td {
          padding: 0 20px 20px 0;
          font-family: "DomaineSansText-Regular";
        }
        .order-info-label {
          color: #707070;
          width: 400px;
        }
        .ant-steps-label-vertical .ant-steps-item-title {
          font-weight: bold;
          font-size: 14px;
        }
        .ant-steps-item-description {
          font-size: 12px;
        }
        .delivery-address-section {
          margin-top: -40px;
        }

        .order-details-payment-table-container {
          padding: 20px;
          background: rgba(0, 0, 0, 0.04);
          margin-top: 40px;
          margin-left: 40px;
        }
        .payment-info-section table {
          border-collapse: unset;
        }
        .payment-info-section td {
          padding: 0 80px 20px 0;
          width: 245px;
        }
        .order-details-payment-image {
          width: 200px;
          height: 200px;
          margin: 20px 0;
        }
        .order-details-payment-table-divider {
          border-bottom: 0.5px solid black;
          width: 100%;
          margin-bottom: 10px;
        }
        .order-info-number {
          font-family: "DomaineSansText-Black";
        }
        .order-details-sidenav-container {
          padding: 50px 0;
          position: fixed;
          background: white;
          width: 200px;
          border-right: 1px solid #e4e4e4;
        }
        .action-section {
          justify-content: center;
          gap: 20px;
          padding: 40px;
        }
        .action-section-divider {
          border-right: 1px solid black;
        }
        .action-section-link {
          cursor: pointer;
          text-decoration: underline;
        }
        .action-section-link:hover {
          color: #800000;
        }

        @media (max-width: 640px) {
          .order-details-coverall-container {
            margin-left: 0;
          }
          .order-details-adspace {
            background-image: url("/images/seller-stats-banner.png");
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .order-details-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .order-details-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .order-details-body-content-header-title {
            font-size: 18px;
          }
          .order-details-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
          }

          .order-details-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .order-details-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .order-details-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
            padding-bottom: 0;
          }
          .order-details-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .order-details-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }
          .section-in-order-details {
            padding: 16px;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 24px;
          }
          .order-info-section td {
            padding: 0 8px 10px 0;
          }
          .ant-steps-item-title {
            font-size: 14px;
          }
          .order-details-payment-image {
            margin: 16px 0;
            width: 120px;
            height: 120px;
            object-fit: cover;
          }
          .order-details-payment-table-container {
            margin-top: 24px;
            margin-left: 0;
          }
          .no-reason-textbox {
            margin-top: -30px;
            margin-bottom: 30px;
          }
        }
      `}</style>
    </SupplierLayout>
  );
};

export default SupplierInfo;
