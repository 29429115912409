import { Form, Modal, Row, Col, Input, Button } from "antd"
import { useState } from "react"


const ShopForBuyer =({
    shopBuyerModalVisible,
    setShopBuyerModalVisible
})=>{
    const [infoModalVisible, setInfoModalVisible] = useState(false)
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [notFoundModalVisible, setNotFoundModalVisible] = useState(false)

    const [buyForm] = Form.useForm()

    return(
        <div>
            <div>
                <Modal
                centered
                visible={shopBuyerModalVisible}
                onCancel={()=>setShopBuyerModalVisible(false)}
                footer={null}
                className='shopBuyerModal'
                width={"60%"}
                // closeIcon={
                // <CloseCircleFilled
                    // style={{ color: "#000", padding: "40px" }}
                maskClosable={false}
                >

                    <div style={{marginBottom:'40px'}}>
                        <div className="shop-buyer-modal-form-title">Buy A New Item</div>
                        <div>Kindy enter customer email address</div>
                    </div>

                    <Form
                    form={buyForm}
                    name="buyForm"
                    scrollToFirstError
                    layout="vertical"
                    requiredMark={false}
                    >

                    <div style={{marginBottom:'8px'}}>Email Address</div>

                    <Row
                    className="form-row"
                    gutter={[24, 12]}
                    >
                        
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                // label="Email Address"
                                rules={[
                                {
                                    required: true,
                                    message: "This is a required field",
                                },
                                {
                                    type: "email",
                                    message: "The input is not valid E-mail!",
                                },
                                ]}
                            >

                            <Input className="input" placeholder="Enter your email address" />

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Button className="btn-save" onClick={()=>{setInfoModalVisible(true); setShopBuyerModalVisible(false)}}>
                                Find Customer
                            </Button>
                        </Col>

                    </Row>

                    </Form>

                </Modal>

                <Modal
                centered
                visible={infoModalVisible}
                onCancel={()=>setInfoModalVisible(false)}
                footer={null}
                className='shopBuyerModal personal-info-section'
                width={"60%"}
                // closeIcon={
                // <CloseCircleFilled
                    // style={{ color: "#000", padding: "40px" }}
                maskClosable={false}
                >

                    <div style={{marginBottom:'24px'}}>
                        <div className="shop-buyer-modal-form-title">Customer Information</div>
                    </div>

                    <table style={{marginBottom:'24px'}}>
                      <tbody>
                        <tr>
                          <td className="personal-info-label">Name</td>
                          <td>Alexandra Doe</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Email Address</td>
                          <td>alexandradoe@example.com</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Phone Number</td>
                          <td>+2349039090909</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Gender</td>
                          <td>Male</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Date Of Birth</td>
                          <td>22 August, 1987</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label" style={{verticalAlign:'top'}}>Address</td>
                          <td>
                            Joe Blogg Williams <br/>
                            1, Northfield drive, <br/>
                            Chestire WA165 <br/>
                            United Kingdom
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div style={{textAlign:'center', marginTop:'40px'}}>
                        <Button className="btn-save" style={{width:"60%"}} onClick={()=>{setConfirmModalVisible(true); setInfoModalVisible(false)} }>
                            Confirm
                        </Button>   
                    </div>
                    



                    

                </Modal>

                <Modal
                centered
                visible={confirmModalVisible}
                onCancel={()=>setConfirmModalVisible(false)}
                footer={null}
                className='shopBuyerModal'
                width={"60%"}
                // closeIcon={
                // <CloseCircleFilled
                    // style={{ color: "#000", padding: "40px" }}
                maskClosable={false}
                >
                    <div style={{fontSize:'18px', textAlign:'center', margin:'24px 0 40px'}}>
                    You are about to enter the losode store to make a purchase for a customer, please check that you have the right customer
                    </div>

                    <div style={{display:'flex', gap:'40px'}}>
                        <Button className="btn-cancel"  onClick={()=>{setConfirmModalVisible(false); setShopBuyerModalVisible(true)} }>
                            Find Another Customer
                        </Button>   
                        <Button className="btn-save" onClick={()=>{setConfirmModalVisible(false)} }>
                            Go to Losode
                        </Button>   
                    </div>
                </Modal>

                <Modal
                centered
                visible={notFoundModalVisible}
                onCancel={()=>setNotFoundModalVisible(false)}
                footer={null}
                className='shopBuyerModal'
                width={"60%"}
                // closeIcon={
                // <CloseCircleFilled
                    // style={{ color: "#000", padding: "40px" }}
                maskClosable={false}
                >
                    <div style={{fontSize:'18px', margin:'24px 0 40px'}}>
                    This Customer Cannot be found. Kindly Checkout this customer as a guest
                    </div>

                    <div>
                        <Button className="btn-save" style={{width:'60%'}} onClick={()=>{setNotFoundModalVisible(false)} }>
                            Enter Guest Checkout
                        </Button>   
                    </div>
                </Modal>
            </div>

            <style>
                {`
                    .shopBuyerModal{
                        font-family: 'DomaineSansText-Light';
                    }
                    .shopBuyerModal .ant-modal-body{
                        padding:40px 40px 60px;
                        color:black
                    }
                    .shop-buyer-modal-form-title{
                        font-family: 'DomaineSansText-Regular';
                        font-size: 24px;
                    }
                    .btn-save {
                        background: #000000;
                        border-color: #000;
                        color: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .btn-cancel{
                        color: #000000;
                        border-color: #000;
                        background: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .input{
                        height:48px
                    }
                    .ant-form-item-explain-error{
                        margin-top:4px
                    }

                    .personal-info-section table {
                        border-collapse: unset;
                        height: fit-content;
                      }
                      .personal-info-section td {
                        padding: 0 20px 12px 0;
                        font-family: "DomaineSansText-Regular";
                      }
                      .personal-info-label {
                        color: #707070;
                        width: 175px;
                      }
                `}
            </style>
        </div>
    )
}

export default ShopForBuyer