import {
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  GET_USER_DETAILS,
  LOGOUT_USER,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_FAIL,
} from "./type";

const initialState = {
  loading: false,
  error: false,
  message: "",
  info: {},
  isAuthenticated: false,
  settings: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_START: {
      return {
        ...state,
        loading: true,
        error: action.error,
        message: action.payload,
      };
    }
    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.payload,
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        error: action.error,
        info: action.payload,
        isAuthenticated: true,
      };
    }
    case LOGOUT_USER: {
      return (state = initialState);
    }
    case LOGIN_USER_FAIL: {
      return { ...state, error: action.error, message: action.payload };
    }

    case GET_USER_DETAILS: {
      return { ...state, settings: action.payload };
    }
    case UPDATE_USER_DETAILS: {
      return { ...state, error: action.error, message: action.payload };
    }
    case UPDATE_USER_DETAILS_FAIL: {
      return { ...state, error: action.error, message: action.payload };
    }

    default:
      return state;
  }
};

export default userReducer;
