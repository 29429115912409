import { useEffect } from "react";
import { Button } from "antd";

import ListingView from "../seller/ListingView";
import OrderView from "./assets/OrderViewBuyer";
import BuyerLayout from "./assets/BuyerLayout";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { customersActionCreators } from "../../redux/customer";

const BuyerInformation = () => {
  const isTabletOrMobile = false;
  const dispatch = useDispatch();
  const listings = [];

  const id = window.location.pathname.split("/")[2];

  const { info } = useSelector((state) => state.user);
  const { customer } = useSelector((state) => state.customers);

  const { getBuyerDetails } = bindActionCreators(
    customersActionCreators,
    dispatch
  );

  const {
    name,
    phone,
    email,
    gender,
    country,
    dob,
    addresses,
    flagged_orders,
    orders,
  } = customer || {};

  useEffect(() => {
    getBuyerDetails(info?.token, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <BuyerLayout
      headerTitle="The Customer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              <div className="seller-info-section personal-info-section">
                <div className="dashboard-body-content-header-container">
                  <div
                    className="dashboard-body-content-header-title"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #bfbfbf",
                      padding: "16px",
                      fontSize: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    Customer Profile
                  </div>
                </div>

                <div className="personal-info-second-container">
                  <div className="seller-info-payment-title">
                    Personal Information
                  </div>
                  <div className="personal-info-second-container-content">
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        marginBottom: "24px",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td className="personal-info-label">Name:</td>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <td className="personal-info-label">
                              Phone Number:
                            </td>
                            <td>{phone}</td>
                          </tr>
                          <tr>
                            <td className="personal-info-label">
                              Email Address:
                            </td>
                            <td>{email}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          height: "auto",
                          width: "1px",
                          borderRight: "1px solid #bfbfbf",
                        }}
                      ></div>
                      <table>
                        <tbody>
                          <tr>
                            <td className="personal-info-label">Country:</td>
                            <td>{country}</td>
                          </tr>
                          <tr>
                            <td className="personal-info-label">
                              Date Of Birth:
                            </td>
                            <td>{dob}</td>
                          </tr>
                          <tr>
                            <td className="personal-info-label">Gender:</td>
                            <td>{gender}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      style={{
                        lineHeight: "2",
                        fontFamily: "DomaineSansText-Regular",
                        borderTop: "1px solid #bfbfbf",
                        paddingTop: "16px",
                        marginTop: "24px",
                      }}
                    >
                      <div className="seller-info-payment-title">Addresses</div>

                      <div
                        style={{
                          display: "grid",
                          gap: "24px",
                          gridTemplateColumns: "32% 32% 32%",
                        }}
                      >
                        {addresses?.length > 0 &&
                          addresses
                            ?.sort((a, b) => b.isActive - a.isActive)
                            ?.map((address) => (
                              <div
                                style={{
                                  width: "100%",
                                  borderRight: "1px solid #a7a7a7",
                                  paddingRight: "16px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ color: "grey", fontSize: "11px" }}
                                  >
                                    {address?.isActive === 1
                                      ? "Main Address"
                                      : "Additional Address"}
                                  </div>
                                </div>
                                <div>
                                  {address.firstname} {address.lastname} <br />
                                  {address.shippingAddress} <br />
                                  {address.state} <br />
                                  {address.country}
                                </div>
                                {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#800000",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                >
                                  Edit
                                </div>
                                <svg
                                  width="19"
                                  height="21"
                                  viewBox="0 0 19 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.7797 1.29438H12.9331L12.5535 0.539122C12.4731 0.377656 12.3492 0.241834 12.1958 0.146937C12.0424 0.0520392 11.8655 0.00183083 11.6851 0.00196021H7.06877C6.88879 0.00126834 6.71226 0.0512895 6.5594 0.146293C6.40654 0.241296 6.28353 0.377438 6.20446 0.539122L5.82482 1.29438H0.978241C0.806856 1.29438 0.64249 1.36246 0.521302 1.48365C0.400114 1.60484 0.332031 1.7692 0.332031 1.94059L0.332031 3.23301C0.332031 3.4044 0.400114 3.56876 0.521302 3.68995C0.64249 3.81114 0.806856 3.87922 0.978241 3.87922H17.7797C17.9511 3.87922 18.1155 3.81114 18.2366 3.68995C18.3578 3.56876 18.4259 3.4044 18.4259 3.23301V1.94059C18.4259 1.7692 18.3578 1.60484 18.2366 1.48365C18.1155 1.36246 17.9511 1.29438 17.7797 1.29438ZM2.48068 18.8632C2.5115 19.3554 2.72873 19.8173 3.08814 20.155C3.44755 20.4926 3.92213 20.6806 4.41527 20.6807H14.3427C14.8358 20.6806 15.3104 20.4926 15.6698 20.155C16.0292 19.8173 16.2464 19.3554 16.2773 18.8632L17.1335 5.17164H1.62445L2.48068 18.8632Z"
                                    fill="black"
                                  />
                                </svg>
                              </div> */}
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div className="seller-info-section">
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {/* {formatter(
                        settlementInfo?.last_90_days,
                        rate,
                        code,
                        indicator
                      )} */}
                          3,490
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          TOTAL ORDERS ON LOSODE
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {/* {totalProducts} */}3
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          ORDERS IN 30 DAYS
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          2
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          FLAGGED ORDERS
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-body-quick-action-container seller-info-section">
                    <div className="dashboard-body-quick-action-title">
                      Quick Actions
                    </div>
                    <div className="dashboard-body-quick-action-button-container">
                      <Button className="dashboard-body-quick-action-button">
                        Buy For Customer
                      </Button>
                      <Button className="dashboard-body-quick-action-button">
                        Find Orders
                      </Button>
                      <Button className="dashboard-body-quick-action-button">
                        Ban Customer
                      </Button>
                      <Button
                        className="dashboard-body-quick-action-button"
                        // onClick={() => navigate("/list-item")}
                      >
                        Flagged Orders
                      </Button>
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section seller-info-section">
                    <div
                      className="dashboard-body-content-header-title"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #bfbfbf",
                        padding: "16px",
                        fontSize: "24px",
                        marginBottom: "24px",
                      }}
                    >
                      Order Information
                    </div>

                    <div
                      style={{
                        marginBottom: "24px",
                        paddingBottom: "24px",
                        borderBottom: "1px solid #bfbfbf",
                      }}
                    >
                      <div
                        className="dashboard-body-orders-title"
                        style={{ position: "relative" }}
                      >
                        <div>New Orders in 30 Days</div>
                        <div
                          style={{
                            color: "#800000",
                            textDecoration: "underline",
                            fontSize: "12px",
                            position: "absolute",
                            right: 0,
                            top: 0,
                          }}
                        >
                          view more
                        </div>
                      </div>
                      <div className="dashboard-body-content-listings-container">
                        {orders?.length > 0 ? (
                          orders
                            .slice(0, 4)
                            .map((order) => (
                              <OrderView
                                key={order.sale_id}
                                product={order}
                                type="manage orders"
                                productTag="New"
                              />
                            ))
                        ) : (
                          <div className="no-listings-to-show">
                            <div>
                              <div>Buyer currently has 0 new orders</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        marginBottom: "24px",
                        paddingBottom: "24px",
                        borderBottom: "1px solid #bfbfbf",
                      }}
                    >
                      <div
                        className="dashboard-body-orders-title"
                        style={{ position: "relative" }}
                      >
                        <div>Saved Items</div>
                      </div>

                      <div className="dashboard-body-content-listings-container">
                        {isTabletOrMobile ? (
                          flagged_orders?.slice(0, 4)?.map((order) => (
                            <OrderView
                              // key={order.id}
                              // product={order}
                              type="manage orders"
                              flagged
                            />
                          ))
                        ) : (
                          <div className="no-listings-to-show">
                            <div>
                              <div>Buyer currently has 0 flagged orders</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ paddingBottom: "24px" }}>
                      <div
                        className="dashboard-body-orders-title"
                        style={{ position: "relative" }}
                      >
                        <div>Flagged Orders 🚩 </div>
                      </div>

                      <div className="dashboard-body-content-listings-container">
                        {listings?.length > 0 ? (
                          listings
                            .slice(0, 4)
                            .map((listing) => (
                              <ListingView
                                key={listing.id}
                                product={listing}
                                productTag="New"
                              />
                            ))
                        ) : (
                          <div className="no-listings-to-show">
                            <div>
                              <div>Buyer currently has 0 saved items</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}

        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
            padding-bottom: 40px;
            margin-top: 16px;
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 30px;
            width: 100%;
            margin: 16px 0;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 10px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            // padding-left: 5px;
            margin-top: 8px;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 240px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            margin-top: 8px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: 48px !important;
            font-size: 14px !important;
            padding: 0 16px !important;
            width: 150px !important;
            border-color: black !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-sidebar-content-select.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 3;
          }
          .seller-info-section {
            padding: 24px;
            background: white;
            margin: 24px 0;
          }
          .seller-info-payment-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            margin-top: 8px;
          }
          .seller-info-payment-box-container {
            display: flex;
            gap: 40px;
            margin-bottom: 60px;
          }
          .seller-info-payment-box {
            background: #f7f7f7;
            padding: 16px;
            width: 300px;
            position: relative;
          }
          .seller-info-payment-box-label {
            font-size: 12px;
            margin-bottom: 8px;
          }
          .seller-info-payment-box-number {
            font-size: 24px;
            font-family: DomaineSansText-Black;
          }
          .seller-info-payment-box-extra {
            font-size: 12px;
            margin-top: 8px;
          }
          .seller-info-payment-extra {
            position: absolute;
            bottom: -40px;
            left: 0;
            text-decoration: underline;
          }
          .store-header-banner-text-header-flex-section {
            display: flex;
            gap: 8px;
            padding: 8px 0;
            align-items: center;
          }
          .store-header-banner-text-header-flex-section img {
            width: 20px;
            height: 20px;
          }
          .personal-info-status-tag {
            text-transform: capitalize;
            width: fit-content;
            padding: 14px 80px;
            font-size: 12px;
            height: fit-content;
          }
          .personal-info-profile-image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .personal-info-first-container {
            display: flex;
            justify-content: space-between;
            padding: 24px 0 40px;
            border-bottom: 1px solid #bfbfbf;
            margin-bottom: 40px;
          }
          .personal-info-section table {
            border-collapse: unset;
            height: fit-content;
          }
          .personal-info-section td {
            padding: 0 20px 8px 0;
            font-family: "DomaineSansText-Regular";
          }
          .personal-info-label {
            color: #707070;
            width: 175px;
          }
          // .personal-info-second-container-content {
          //   display: flex;
          //   gap: 100px;
          // }

          @media (max-width: 640px) {
            .dashboard-coverall-container {
              margin-left: 0;
            }
            .dashboard-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }

            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }
            .dashboard-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .dashboard-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .dashboard-body-content-header-title {
              font-size: 18px;
            }
            .dashboard-body-quick-action-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              margin-top: auto;
            }
            .dashboard-body-bold-numbers-item {
              width: auto;
              justify-content: center;
              padding: 16px;
              text-align: center;
            }
            .dashboard-body-bold-numbers-container {
              gap: 8px;
              margin-bottom: 24px;
              margin-top: 0px;
              flex-wrap: wrap;
            }
            .dashboard-body-bold-numbers-item-number {
              font-size: 16px;
            }
            .dashboard-body-bold-numbers-item-text {
              font-size: 10px;
            }
            .dashboard-body-alert-box {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-community-container {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-events-subtitle {
              margin-bottom: 16px;
              width: 100%;
              font-size: 14px;
            }
            .dashboard-body-orders-section {
              margin-bottom: 24px;
            }
            .dashboard-partners-image {
              width: auto;
              height: 120px;
            }
            .dashboard-body-partners-images-container {
              gap: 8px;
            }
            .dashboard-store-link-section {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-events-image {
              width: auto;
              height: 300px;
            }
            .process-icon-container {
              padding: 8px;
              border-radius: 50%;
              border: 1px solid #800000;
              width: 32px;
              height: 32px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .process-icon-div-mobile {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 24px;
              margin: 16px 0;
            }
            .process-icon {
              color: #800000;
            }
            .dashboard-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .dashboard-body-bold-numbers-item-earned {
              width: 100%;
            }
            .dashboard-body-bold-numbers-item-listed,
            .dashboard-body-bold-numbers-item-sold {
              flex: 2;
            }
          }
        `}</style>
      </div>
    </BuyerLayout>
  );
};

export default BuyerInformation;
