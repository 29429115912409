const colors = [
  { id: 1, name: "Almond", hex: "#efdecd" },
  { id: 2, name: "Black", hex: "#000000" },
  { id: 3, name: "Beige", hex: "#F5F5DC" },
  { id: 4, name: "Blue", hex: "#0000FF" },
  { id: 5, name: "Brown", hex: "#A52A2A" },
  { id: 6, name: "Burgundy", hex: "#800000" },
  { id: 7, name: "Orange", hex: "#FFA500" },
  { id: 8, name: "Coffee", hex: "#6F4E37" },
  { id: 9, name: "Caramel", hex: "#C68E17" },
  { id: 10, name: "Cream", hex: "#FFFDD0" },
  { id: 11, name: "Green", hex: "#00FF00" },
  { id: 12, name: "Grey", hex: "#808080" },
  { id: 13, name: "Gold", hex: "#FFD700" },
  { id: 14, name: "Lilac", hex: "#C8A2C8" },
  { id: 15, name: "Lime", hex: "#CEFA05" },
  { id: 16, name: "Mint", hex: "#7CD2B3" },
  { id: 17, name: "Mustard", hex: "#E1AD01" },
  { id: 18, name: "Navy Blue", hex: "#000080" },
  { id: 19, name: "Olive", hex: "#808000" },
  { id: 20, name: "Pink", hex: "#FFC0CB" },
  { id: 21, name: "Purple", hex: "#800080" },
  { id: 22, name: "Red", hex: "#FF0000" },
  { id: 23, name: "Rose Gold", hex: "#B76E79" },
  { id: 24, name: "Silver", hex: "#C0C0C0" },
  { id: 25, name: "Turquoise", hex: "#30D5C8" },
  { id: 26, name: "White", hex: "#ffffff" },
  { id: 27, name: "Wine", hex: "#B11226" },
  { id: 28, name: "Yellow", hex: "#FFFF00" },
  {
    id: 29,
    name: "Multicolour",
    hex: "linear-gradient(to right, #1e9600, #fff200, #ff0000)",
  },
  {
    id: 30,
    name: "Eggshell",
    hex: "#F0EAD6",
  },
];

export default colors;
