import axios from "axios";

export const getBuyerStats = async (token) => {
  try {
    const result = await axios.get("admin/customer/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchAllBuyers = async (token) => {
  try {
    const result = await axios.get("admin/feature/get/buyers/list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const searchBuyer = async (token, data) => {
  try {
    const result = await axios.post("admin/checkout/get/buyer/detail", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchBuyerDetails = async (token, id) => {
  try {
    const result = await axios.get(`admin/buyer/details/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
