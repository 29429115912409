import { Modal, Button, Form, Collapse } from "antd";
// import { useMediaQuery } from "react-responsive";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  CloseCircleFilled,
  // RightOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  // ShareAltOutlined,
} from "@ant-design/icons";
import Slider from "react-slick";
import formatter from "../../utils/formatter";

const PreviewLisitingModal = ({ modalVisible, setModalVisible, product }) => {
  const [currentBigImageKey, setCurrentBigImageKey] = useState(1);

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data;

  const isTabletOrMobile = false;

  const sliderRef = useRef();

  const changeDivBg = (index) => {
    try {
      sliderRef?.current.slickGoTo(index);
      setCurrentBigImageKey(index + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const images = product?.images || [
    product?.image1,
    product?.image2,
    product?.image3,
    product?.image4,
  ];

  const { Panel } = Collapse;

  const settingsBigScreen = {
    dots: false,
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    beforeChange: (current, next) => setCurrentBigImageKey(next + 1),
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <RightCircleFilled style={{ fontSize: 32 }} />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <LeftCircleFilled style={{ fontSize: 32 }} />
        </div>
      </div>
    ),
  };
  const [form] = Form.useForm();

  return (
    <>
      <Modal
        centered
        visible={modalVisible}
        footer={null}
        width={!isTabletOrMobile ? "80%" : 400}
        onCancel={() => setModalVisible(false)}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#000",
              padding: "15px 10px 0 0",
              fontSize: "30px",
            }}
          />
        }
      >
        <div className="product-container">
          <div className="product-image-more-text-contain">
            <div className="product-images-supercontainer">
              <div className="other-img-container">
                {images?.map((image, index) => (
                  <div
                    key={index}
                    alt="product"
                    className="img"
                    onClick={() => changeDivBg(index)}
                    style={{
                      backgroundImage: `url(${image})`,
                      cursor: "pointer",
                      border: `${
                        index + 1 === currentBigImageKey
                          ? "3px solid #4d4d4d"
                          : ""
                      }`,
                    }}
                  />
                ))}
              </div>

              <div
                style={{
                  gridColumn: "2 / 6",
                  marginLeft: !isTabletOrMobile ? "40px" : 0,
                }}
              >
                <Slider
                  {...settingsBigScreen}
                  ref={sliderRef}
                >
                  {images?.map((image) => (
                    <div>
                      <div
                        key={image?.imageURL}
                        style={{
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "top",
                          backgroundSize: "cover",
                          // height: !isTabletOrMobile
                          //   ? "clamp(200px, 48vw, 1000px)"
                          //   : "65vh",
                          height: "490px",
                        }}
                      ></div>
                      {/* {isTabletOrMobile && ( */}
                      <div className="mobile-item-code-product">
                        Item Code: 38063312418681103
                      </div>
                      {/* )} */}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            {/* {!isTabletOrMobile && (
              <div className="product-more-text-contain">
                <div className="product-more-text-contain-title">
                  OTHER CATEGORIES FROM{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {product?.store_info?.store_name}
                  </span>
                </div>
                <div className="product-more-text-contain-details">
                  <div>Dresses</div>
                  <div>Accessories</div>
                  <div>Shoes</div>
                </div>
              </div>
            )} */}
          </div>

          <div className="details-container">
            <div style={{ width: !isTabletOrMobile ? "70%" : "100%" }}>
              {/* <div className="low-stock-text">LOW STOCK</div> */}
              <div
                className="product-store-name"
                style={{ textDecoration: "underline" }}
              >
                {product?.brandName}
              </div>
              <h2 className="product-title">{product?.name}</h2>
              <div className="price-container">
                {product?.has_discount &&
                  (product?.discount_percent || product?.discounts_percent) && (
                    <div className="product-old-price">
                      <span
                        style={{
                          textDecoration: "line-through",
                          marginRight: "8px",
                          color: "#800000",
                        }}
                      >
                        {formatter(
                          product?.price * (31.6 / 100) + product?.price,
                          rate,
                          code,
                          indicator
                        )}
                      </span>
                      <span style={{ color: "#000", marginLeft: "5px" }}>
                        {product.discount_percent}% OFF
                      </span>
                    </div>
                  )}
                {product?.has_discount &&
                  (product?.discount_percent || product?.discounts_percent) && (
                    <div className="product-price">
                      {formatter(
                        Math.round(
                          (product?.price * (31.6 / 100) +
                            product?.price -
                            product?.price *
                              ((product?.discount_percent ||
                                product?.discounts_percent) /
                                100)) *
                            100
                        ) / 100,
                        rate,
                        code,
                        indicator
                      )}
                    </div>
                  )}

                {!(
                  product?.has_discount &&
                  (product?.discount_percent || product?.discounts_percent)
                ) && (
                  <div className="product-price">
                    {formatter(product?.price * (31.6 / 100) + product?.price, rate, code, indicator )}
                  </div>
                )}
              </div>

              <Form
                className="add-to-cart-form"
                form={form}
                name="add-to-cart"
                // onFinish={addProductToCart}
                scrollToFirstError
              >
                <div>
                  <div>
                    Color:
                    {product?.colors?.map((color, idx) => {
                      const { name } = color;
                      return (
                        <span
                          key={idx}
                          style={{ marginRight: 10 }}
                        >
                          {" "}
                          {name}
                        </span>
                      );
                    })}
                  </div>
                </div>

                {product?.colors && product?.colors?.length > 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        width: "fit-content",
                      }}
                    >
                      {product?.colors?.map((color) => {
                        const { hex, id } = color;
                        return (
                          <div
                            key={id}
                            className="color-box"
                            style={{
                              background: hex,
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}

                <div style={{ marginTop: 20 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Size:</div>
                    <div style={{ textDecoration: "underline" }}>
                      view size guide
                    </div>
                  </div>
                  <div>
                    <div
                      className="sizes-radiogroup"
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        marginTop: "4px",
                      }}
                    >
                      {product?.sizes?.map((size) => (
                        <div
                          key={size}
                          style={{
                            border: "1px solid #E6E6E6",
                            display: "grid",
                            placeContent: "center",
                            height: 40,
                            width: "31%",
                          }}
                        >
                          <div className="size-box">{size}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 40 }}>
                  <Button
                    className="btn-product bag-button"
                    style={{
                      color: "white",
                      background: "black",
                      width: "100%",
                      height: 48,
                      marginBottom: "8px",
                    }}
                    icon={
                      <svg
                        style={{
                          position: "absolute",
                          top: "12px",
                          left: "20px",
                        }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.4785 19.9999H0.521526L0 19.5057L2.0231 5.42339L2.54463 5.03516H11.4491V5.91751H3.01015L1.11306 19.1175H18.8939L17.2804 5.91751H16.6083V5.03516H17.7529L18.2779 5.43221L20 19.5146L19.4785 19.9999Z"
                          fill="white"
                        />
                        <path
                          d="M14.8863 6.87059H13.1362V2.36176L12.0756 1.47059H7.92441L6.86386 2.36176V4.25294H5.11377V1.75294L7.19637 0H12.8002L14.8863 1.75294V6.87059Z"
                          fill="white"
                        />
                        <path
                          d="M14.5432 8.55613H13.4756L12.6006 7.82084V6.92084L13.4756 6.18555H14.5432L15.4182 6.92084V7.82084L14.5432 8.55613Z"
                          fill="white"
                        />
                      </svg>
                    }
                    htmlType="submit"
                  >
                    Add to Bag
                  </Button>
                  <Button
                    className="btn-product"
                    style={{
                      color: "black",
                      background: "white",
                      width: "100%",
                      height: 48,
                    }}
                    icon={
                      <img
                        alt="save"
                        src="/images/icon-wishlist.svg"
                        style={{
                          position: "absolute",
                          top: "14px",
                          left: "20px",
                        }}
                      />
                    }
                    // onClick={() => saveItem(product.product_id)}
                  >
                    Save Item
                  </Button>
                </div>
                {/* <ShareAltOutlined className="btn-share" onClick={shareURL} /> */}
              </Form>
              <div className="product-details-collapsible">
                <Collapse
                  accordion
                  expandIconPosition="right"
                  defaultActiveKey={["0"]}
                  ghost
                >
                  <Panel
                    header="Item Description"
                    className="panel-heading"
                  >
                    <p className="txt-product-desc">{`${product?.description}`}</p>
                  </Panel>
                  <Panel
                    header="Size And Fit"
                    className="panel-heading"
                  >
                    <p className="txt-product-desc">{`${product?.size_and_fit}`}</p>
                  </Panel>
                  <Panel
                    header="Occasion"
                    className="panel-heading"
                  >
                    {product?.occassion_type?.map((oc) => (
                      <span
                        className="txt-product-desc"
                        style={{ marginRight: 10 }}
                      >
                        {oc}
                      </span>
                    ))}
                  </Panel>
                  <Panel
                    header="Material And Care Info"
                    className="panel-heading"
                  >
                    <p className="txt-material">{product?.material_info}</p>
                  </Panel>
                  <Panel
                    header="Returns Policy"
                    className="panel-heading"
                  >
                    {product?.product_return_type?.map((oc) => (
                      <span
                        className="txt-product-desc"
                        style={{ marginRight: 10 }}
                      >
                        {oc}
                      </span>
                    ))}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>

          {isTabletOrMobile && (
            <div className="product-more-text-contain">
              <div className="product-more-text-contain-title">
                OTHER CATEGORIES FROM{" "}
                <span style={{ textDecoration: "underline" }}>
                  {product?.store_info?.store_name}
                </span>
              </div>
              <div className="product-more-text-contain-details">
                <div>Dresses</div>
                <div>Accessories</div>
                <div>Shoes</div>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <style jsx="true">
        {`
          .product-container {
            margin: 40px 20px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .product-images-supercontainer {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
          }
          .other-img-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 40px;
          }
          .img {
            background-size: cover;
            background-repeat: no-repeat;
            width: 75px;
            height: 100px;
          }
          // .img-container {
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   flex: 3;
          //   height: 800px;
          // }
          .img-big-screen {
            object-fit: contain;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          .details-container {
            flex: 2;
            display: flex;
            justify-content: center;
            font-family: "DomaineSansText-Light";
          }
          .product-store-name {
            text-transform: uppercase;
            font-size: 28px;
            line-height: 40px;
            font-family: "DomaineSansText-Regular";
            margin-bottom: 8px;
          }
          .product-title {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .price-container {
            margin-bottom: 24px;
          }
          .single-price {
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .old-price {
            text-decoration: line-through;
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .discount-price {
            font: normal normal normal 16px DomaineSansText-Regular;
            color: #800000;
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
          }

          .colors-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
            border-radius: 50%;
          }
          .sizes-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
          }
          .color-box {
            border: 1px solid #cccc;
            height: 26px;
            margin: 6px auto;
            width: 26px;
            border-radius: 50%;
          }
          .size-box {
            color: black;
          }
          .btn-product {
            width: 130px;
            height: 40px;
            margin-right: 15px;
            // transition: 0.5s;
            // box-shadow: inset 0px 1px 0 rgb(0 0 0 / 5%)
          }
          // .btn-product.bag-button:hover{
          //   box-shadow: inset 20em 0 0 0 black;
          //   color: white;
          //   border: 6px solid black
          //   // transition:1s
          // }
          .btn-share {
            font-size: 30px;
            color: #b7b7b7;
            cursor: pointer;
            margin-top: 20px;
          }
          .btn-share:hover {
            color: #000;
          }
          .product-details-collapsible {
            margin-top: 40px;
          }
          .list-item {
            font: italic normal normal 12px DomaineSansText-Regular;
          }
          .brand-details-container {
          }
          .txt-brand-title {
            font: normal bold 16px/18px DomaineSansText-Regular;
          }
          .txt-brand-desc,
          .txt-material {
            font: normal normal 16px/18px DomaineSansText-Light;
          }
          .panel-heading {
            font: normal 600 16px/18px DomaineSansText-Regular;
          }
          .txt-product-desc {
            font: normal normal 14px DomaineSansText-Light;
            line-height: 22px;
            white-space: pre-line;
          }
          .txt-title {
            font: normal normal bold 30px DomaineSansText-Regular;
            color: #000000;
            text-transform: uppercase;
            padding-bottom: 1em;
          }
          .style-container {
            background: #eeeeee;
            padding-top: 4em;
            padding-left: 5em;
            padding-right: 5em;
            padding-bottom: 5em;
          }
          .reviews {
            margin-top: 3em;
          }
          .returns-status {
            font: normal normal bold 20px DomaineSansText-Regular;
          }
          .returns-title,
          .returns-terms {
            font: normal normal 11px DomaineSansText-Regular;
            color: #b7b7b7;
          }
          .returns-terms {
            font-size: 10px;
          }
          .arrow-image-change {
            background: #fff;
            float: right;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-top: 70%;
            margin-right: 30px;
            text-align: center;
          }
          .arrow-image-change:hover {
            background: maroon;
            // border-radius: 50%;
            // padding: 10px;
            color: white;
            transition: 0.5s;
          }
          .slick-slider div {
            height: 100%;
          }
          .slick-arrow {
            height: auto !important;
          }
          .low-stock-text {
            color: #d18f8f;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 4px;
          }
          .ant-radio-button-wrapper-checked:not(
              .ant-radio-button-wrapper-disabled
            )::before {
            background-color: transparent;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            padding: 12px 0 !important;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding-top: 0px;
            padding-bottom: 0px;
          }
          .product-more-text-contain {
            font-family: "DomaineSansText-Light";
            font-size: 16px;
            margin-top: 40px;
          }
          .product-more-text-contain-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
          }
          .product-more-text-contain-details {
            display: flex;
            gap: 16px;
            margin-top: 8px;
          }
          .other-products-container {
            margin: 80px 60px 120px;
            max-width: 92%;
            margin-left: auto;
            margin-right: auto;
          }
          .other-products-header-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
            font-size: 16px;
            margin-bottom: 16px;
          }
          .slick-next:before,
          .slick-prev:before {
            font-size: 2.5vw;
            display: none;
          }
          .slick-arrow {
            z-index: 2;
            width: auto;
            text-align: center;
          }
          .slick-next {
            right: 3%;
          }
          .slick-prev {
            left: 3%;
          }
          .other-products-container .slick-arrow {
            top: -34px;
          }
          .other-products-container svg {
            color: black;
          }
          .other-products-container .slick-prev {
            right: -80%;
          }
          .other-products-container .slick-next {
            right: 20px;
          }
          .other-products-card {
            width: 230px;
          }
          .other-products-image {
            object-fit: cover;
            object-position: top;
            width: 230px;
            height: 345px;
          }
          .other-products-item-container {
            padding-right: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
            font-family: "DomaineSansText-Light";
          }
          .other-products-store-name {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            font-family: "DomaineSansText-Regular";
          }
          .other-products-header-title {
            font-size: 14px;
          }
          .mobile-item-code-product {
            color: #b5b5b5;
            margin-top: 4px;
            font-family: "DomaineSansText-Light";
            font-size: 12px;
          }

          @media (max-width: 640px) {
            .product-container {
              margin: 16px;
              display: block;
            }
            .other-img-container {
              display: none;
            }
            .product-images-supercontainer {
              display: block;
            }

            .slick-slider div {
              width: 100%;
            }
            .slick-arrow {
              width: auto !important;
            }
            .details-container {
              justify-content: flex-start;
              margin-top: 30px;
            }
            .product-store-name {
              font-size: 24px;
              text-decoration: underline;
              line-height: normal;
            }
            .product-title {
              font-size: 14px;
            }
            .old-price,
            .single-price,
            .discount-price {
              font-size: 14px;
            }
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-item,
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-placeholder {
              line-height: 48px;
            }
            .product-page-mobile-size-select
              .ant-select-item-option-selected:not(
                .ant-select-item-option-disabled
              ) {
              background-color: #f5f5f5;
            }
            .panel-heading {
              font-size: 14px;
            }
            .txt-brand-desc,
            .txt-material {
              font-size: 14px;
            }
            .product-more-text-contain {
              font-size: 12px;
            }
            .other-products-image {
              height: 248px;
              width: 100%;
              margin: auto;
              padding-right: 8px;
            }
            .other-products-container {
              padding: 0 16px;
              margin-top: 40px;
            }
            .other-products-container .slick-arrow {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default PreviewLisitingModal;
