import { Form, Modal, Col, Input, Button, message } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchBuyer } from "../../../../api/buyersApi";

const FindBuyer = ({ findBuyerModalVisible, setFindBuyerModalVisible }) => {
  const [form] = Form.useForm();

  const history = useHistory();
  const { info } = useSelector((state) => state.user);

  const searchUser = async (val) => {
    const res = await searchBuyer(info.token, val);

    if (res.status === "not ok") message.info(res.message);

    if (res.status === "ok") {
      history.push(`/customer-info/${res.data.id}`);
      setFindBuyerModalVisible(false);
    }
  };

  return (
    <div>
      <div className="find-buyer-modal">
        <Modal
          centered
          open={findBuyerModalVisible}
          onCancel={() => setFindBuyerModalVisible(false)}
          footer={null}
          className="FindBuyerModal"
          width={"60%"}
          maskClosable={false}
          bodyStyle={{ padding: "60px 180px 80px" }}
        >
          <div style={{ marginBottom: "40px", textAlign: "center" }}>
            <div className="shop-buyer-modal-form-title">Find Customer</div>
            <div>Enter customer details in the search box to find customer</div>
          </div>

          <Form
            form={form}
            name="form"
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            onFinish={searchUser}
          >
            <Col span={24}>
              <Form.Item
                name="keyword"
                // label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter phone number or email address"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button className="btn-save" htmlType="submit">
                Find Customer
              </Button>
            </Col>
          </Form>
        </Modal>
      </div>

      <style>
        {" "}
        {`
                    .find-buyer-modal{
                        font-family: 'DomaineSansText-Light';
                    }
                    .find-buyer-modal .ant-modal-body{
                        
                        color:black;
                    }
                    .shop-buyer-modal-form-title{
                        font-family: 'DomaineSansText-Regular';
                        font-size: 24px;
                    }
                    .btn-save {
                        background: #000000;
                        border-color: #000;
                        color: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .btn-cancel{
                        color: #000000;
                        border-color: #000;
                        background: #fff;
                        height: 48px;
                        width: 100%;
                    }
                    .input{
                        height:48px
                    }
                    .ant-form-item-explain-error{
                        margin-top:4px
                    }

                 
                `}
      </style>
    </div>
  );
};

export default FindBuyer;
