import { Table, Input, Modal, Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SupplierLayout from "./supplier/assets/SupplierLayout";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { currencyActionCreators } from "../../redux/currency";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const { Search } = Input;

const RateConversion = () => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();

  const { fetchRates } = bindActionCreators(
    currencyActionCreators,
    dispatch
  );

  const [rateModalVisible, setRateModalVisible] = useState(false)
  const [modalIndex, setModalIndex] = useState([])

  const rates = useSelector((state) => state.currency?.rates);
  const { info } = useSelector((state) => state.user);

  const [editedCurrency, setEditedCurrency]= useState({})

  const updateCurrency= async ()=>{
    try {
      const result = await axios.post("admin/currency/rate/update",  editedCurrency, {
        headers: {
          Authorization: `Bearer ${info?.token}`,
        },
      });
      // console.log(result.data)
      message.success(result?.data?.message)
      setRateModalVisible(false)
      fetchRates()
      
    } catch (error) {
      console.log(error)
      message.error(error)
      // return error.response.data;
    }
  }

  useEffect(() => {
    fetchRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Country ID",
      key: "id",
      render: (text, record, index) => (
        <div>{index+1}</div>
      ),
    },
    {
      title: "Local Currency",
      dataIndex: "local_currency",
      key: "local_currency",
    },
    {
      title: "Symbol",
      key: "symbol",
      render: (text, record) => (
        <div>{record?.rates?.currency_symbol}</div>
      ),
    },
    {
      title: "NGN",
      key: "ngn",
      render: (text, record) => (
        <div>{record?.rates?.ngn}</div>
      ),
    },
    {
      title: "USD",
      key: "usd",
      render: (text, record) => (
        <div>{record?.rates?.usd}</div>
      ),
    },
    {
      title: "GBP",
      key: "gbp",
      render: (text, record) => (
        <div>{record?.rates?.gbp}</div>
      ),
    },
    {
      title: "EUR",
      key: "eur",
      render: (text, record) => (
        <div>{record?.rates?.eur}</div>
      ),
      
    },
    {
      render: (text, record, index) => (
        <div style={{cursor:'pointer'}} onClick={()=>{setModalIndex([record]); setEditedCurrency(record?.rates); setRateModalVisible(true)}}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 14.999H15.75" stroke="#101010" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.125 2.46599C13.4234 2.16762 13.828 2 14.25 2C14.4589 2 14.6658 2.04115 14.8588 2.12111C15.0519 2.20106 15.2273 2.31825 15.375 2.46599C15.5227 2.61373 15.6399 2.78912 15.7199 2.98214C15.7998 3.17517 15.841 3.38206 15.841 3.59099C15.841 3.79992 15.7998 4.00681 15.7199 4.19984C15.6399 4.39286 15.5227 4.56825 15.375 4.71599L6 14.091L3 14.841L3.75 11.841L13.125 2.46599Z" stroke="#3C3C3C" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      ),
      
    },
  ];

  const columnsModal = [
    {
      title: "Country ID",
      key: "id",
      render: (text, record, index) => (
        <div>{index+1}</div>
      ),
    },
    {
      title: "Local Currency",
      dataIndex: "local_currency",
      key: "local_currency",
    },
    {
      title: "Symbol",
      key: "symbol",
      render: (text, record) => (
        <div>{record?.rates?.currency_symbol}</div>
      ),
    },
    {
      title: "NGN",
      key: "ngn",
      render: (text, record) => (
        <Input defaultValue={record?.rates?.ngn} 
        onChange={(e)=>{
          const currencyObj= {...editedCurrency}
          currencyObj.ngn=e.target.value
          setEditedCurrency(currencyObj)
        }} 
        />
      ),
    },
    {
      title: "USD",
      key: "usd",
      render: (text, record) => (
        <Input defaultValue={record?.rates?.usd}
        onChange={(e)=>{
          const currencyObj= {...editedCurrency}
          currencyObj.usd=e.target.value
          setEditedCurrency(currencyObj)
        }} 
        />
      ),
    },
    {
      title: "GBP",
      key: "gbp",
      render: (text, record) => (
        <Input defaultValue={record?.rates?.gbp}
        onChange={(e)=>{
          const currencyObj= {...editedCurrency}
          currencyObj.gbp=e.target.value
          setEditedCurrency(currencyObj)
        }} 
        />
      ),
    },
    {
      title: "EUR",
      key: "eur",
      render: (text, record) => (
        <Input defaultValue={record?.rates?.eur}
        onChange={(e)=>{
          const currencyObj= {...editedCurrency}
          currencyObj.eur=e.target.value
          setEditedCurrency(currencyObj)
        }} 
        />
      ),
      
    },
  ];

  return (
    <SupplierLayout
      headerTitle="The FINANCE PORTAL"
      headerSubtitle="Get insights on all finance metrics on Losode"
      dashboardBgColor="#fff0"
    >
      <div
        className="manage-payment-container"
        style={{ marginBottom: "32px" }}
      >
        <div>
          <div
            style={{
              width: "64%",
              height: "48px",
              margin: "16px auto 24px",
            }}
          >
            <Search
              className="search"
              allowClear
              enterButton="Search"
              // onSearch={onSearch}
              placeholder="enter contact person, company name or supplier id "
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
      </div>
      <div className="manage-payment-container" style={{marginBottom:'16px'}}>
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div style={{fontSize:'18px', fontFamily:'DomaineSansText-Regular'}}> RATE CONVERSION TABLE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="manage-payment-container">
        <div className="manage-payment-container-header">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16V12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 8H12.01" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h4>
            Note- Exchange rates are converted from Losode Trading Currency to Local Currency or converted from Horizontal to Vertical
          </h4>
        </div>
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div className="manage-payment-body-content-section">
                {rates && rates?.length && <Table
                    className="manage-payment-transaction-table"
                    dataSource={rates}
                    columns={columns}
                    rowKey={(record) => record.id}
                    pagination={false}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          centered
          visible={rateModalVisible}
          onCancel={() => {setRateModalVisible(false); setEditedCurrency({})}}
          footer={null}
          className="edit-rate-modal"
          width={"fit-content"}
          destroyOnClose={true}
          closeIcon={
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0013 29.3334C23.3651 29.3334 29.3346 23.3639 29.3346 16.0001C29.3346 8.63628 23.3651 2.66675 16.0013 2.66675C8.63751 2.66675 2.66797 8.63628 2.66797 16.0001C2.66797 23.3639 8.63751 29.3334 16.0013 29.3334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 12L12 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 12L20 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          }
        >
         <div>
         {modalIndex && modalIndex?.length && <Table
              className="manage-payment-transaction-table manage-payment-transaction-table-modal"
              dataSource={modalIndex}
              columns={columnsModal}
              rowKey={(record) => record.id}
              pagination={false}
              style={{fontFamily:'DomaineSansText-Light'}}
            />}
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'24px'}}>
            <Button style={{background:'black', color:'white', height:'48px', padding:"0 24px", textTransform:'capitalize', fontFamily:'DomaineSansText-Light'}}
            onClick={()=>updateCurrency()}
            >
              Update Currency
            </Button>
            </div>
          </div> 
        </Modal>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            padding: 24px 40px;
            background: white;
          }
          .manage-payment-container-header {
            display: flex;
          }
          .manage-payment-container-header h4 {
            font-size: 16px;
            font-weight: 600;
            margin-left: 15px;
          }
          .manage-listing-all-content {
            position: relative;
          }

          .ant-table-thead .ant-table-cell {
            background-color: #000;
            color: #fff;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .txt-title {
            line-height: normal;
            margin-bottom: 32px;
            font-size: 20px;
            font-family: "DomaineSansText-Regular";
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 18px;
            font-weight: bold;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 14px;
            width: 100%;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 200px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 20px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }

          .manage-payment-transaction-table-modal .ant-input{
            max-width:100px !important
          }
          .manage-payment-transaction-table-modal .ant-table-thead > tr > th{
            padding-top:40px
          }
          .ant-modal-close-x{
            position: relative;
            top: 30px;
            right: 20px;
          }

          @media (max-width: 640px) {
            .manage-payment-coverall-container {
              margin-left: 0;
            }
            .manage-payment-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .manage-payment-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .manage-payment-body-content-header-container {
              display: flex;
              align-items: center;
            }

            .manage-payment-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 180px;
            }

            .manage-payment-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .manage-payment-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .manage-payment-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }

            .manage-payment-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
              margin: 0;
            }
            .manage-payment-body-sidebar {
              width: 100%;
              margin-top: 0;
              margin-bottom: 24px;
              padding: 0 16px;
              border: 0;
              margin-right: 0;
              display: flex;
              justify-content: flex-end;
            }
            .manage-payment-body-sidebar-content {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .manage-payment-body-sidebar-content-title {
              font-weight: normal;
              font-size: 12px;
              margin-bottom: 0px;
            }
            .manage-payment-body-sidebar-content-select {
              width: auto;
            }
            .manage-payment-body-sidebar-content-select .ant-select-selector {
              margin: 0 !important;
              height: auto !important;
              font-size: 12px !important;
              padding: 0 16px !important;
            }
            .manage-payment-body-sidebar-content-select .ant-select-arrow {
              top: 50%;
              height: auto;
              width: auto;
            }
            .manage-payment-body-content {
              width: 100%;
              padding: 0 16px;
            }
            .manage-payment-body-content-top-actions {
              gap: 16px;
              margin-top: 16px;
              display: none;
            }
            .manage-payment-payment-summary-section {
              width: 100%;
              flex-direction: column;
              gap: 16px;
            }
            .payment-summary-grey-box {
              padding: 16px;
              font-size: 12px;
            }
            .payment-summary-pink-box {
              padding: 16px;
              width: auto;
              font-size: 12px;
            }
            .payment-summary-big-number {
              font-size: 14px;
            }
            .payment-summary-pink-box-button {
              font-size: 12px;
              padding: 16px;
              line-height: 1;
            }
            .manage-payment-body-content-section {
              margin-top: 24px;
            }
            .manage-payment-body-content-section-subtitle {
              font-size: 14px;
            }
            .payment-summary-underline-link {
              padding-bottom: 0px;
            }
            .manage-payment-transaction-table .ant-table {
              font-size: 12px;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
          }
        `}</style>
      </div>
    </SupplierLayout>
  );
};

export default RateConversion;
