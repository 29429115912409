export const REGISTER_SELLER_SUCCESS = "REGISTER_SELLER_SUCCESS";
export const REGISTER_SELLER_FAIL = "REGISTER_SELLER_FAIL";
export const REGISTER_STORE_START = "REGISTER_STORE_START";
export const REGISTER_STORE_SUCCESS = "REGISTER_STORE_SUCCESS";
export const REGISTER_STORE_FAIL = "REGISTER_STORE_FAIL";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const GET_BRANDS = "GET_BRANDS";
export const GET_PHOTOSHOOTS = "GET_PHOTOSHOOTS";
export const GET_PHOTOSHOOT_DETAILS = "GET_PHOTOSHOOT_DETAILS";
export const GET_PENDING_BRANDS = "GET_PENDING_BRANDS";
export const GET_ACTIVATED_BRANDS = "GET_ACTIVATED_BRANDS";
export const GET_BLOCKED_BRANDS = "GET_BLOCKED_BRANDS";
export const GET_BRAND = "GET_BRAND";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_BANKS = "GET_BANKS";
export const GET_BRAND_DETAILS = "GET_BRAND_DETAILS";
export const UPDATE_BRAND_SECURITY = "UPDATE_BRAND_SECURITY";
export const UPDATE_BRAND_ACCOUNT = "UPDATE_BRAND_ACCOUNT";
export const UPDATE_BRAND_DETAILS = "UPDATE_BRAND_DETAILS";
export const SEARCH_BRAND = "SEARCH_BRAND";
export const SEARCH_BRAND_DONE = "SEARCH_BRAND_DONE";
export const GET_BRAND_PRODUCTS = "GET_BRAND_PRODUCTS";
export const GET_APPROVED_PRODUCTS = "GET_APPROVED_PRODUCTS";
export const GET_NEW_PRODUCTS = "GET_NEW_PRODUCTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_BRAND_PRODUCTS_FAILED = "GET_BRAND_PRODUCTS_FAILED";
export const GET_APPROVED_PRODUCTS_FAILED = "GET_APPROVED_PRODUCTS_FAILED";
export const GET_NEW_PRODUCTS_FAILED = "GET_NEW_PRODUCTS_FAILED";
export const GET_BRANDS_FAILED = "GET_BRANDS_FAILED";
export const GET_BRAND_FAILED = "GET_BRAND_FAILED";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";
export const UPDATE_STORE_DETAILS = "UPDATE_STORE_DETAILS";
export const UPDATE_STORE_FAILED = "UPDATE_STORE_FAILED";
export const ACTIVATE_BRAND = "ACTIVATE_BRAND";
export const ACTIVATE_BRAND_FAILED = "ACTIVATE_BRAND_FAILED";
export const DEACTIVATE_BRAND = "DEACTIVATE_BRAND";
export const DEACTIVATE_BRAND_FAILED = "DEACTIVATE_BRAND_FAILED";
export const ACTIVATE_PRODUCT = "ACTIVATE_PRODUCT";
export const ACTIVATE_PRODUCT_FAILED = "ACTIVATE_PRODUCT_FAILED";
export const DEACTIVATE_PRODUCT = "DEACTIVATE_PRODUCT";
export const DEACTIVATE_PRODUCT_FAILED = "DEACTIVATE_PRODUCT_FAILED";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";
export const FETCH_BRAND_APPLICATIONS = "FETCH_BRAND_APPLICATIONS";
export const FETCH_BRAND_APPLICATIONS_FAILED = "FETCH_BRAND_APPLICATIONS_FAILED";
export const FETCH_BRAND_APPLICATION_DETAILS = "FETCH_BRAND_APPLICATION_DETAILS";
export const FETCH_BRAND_APPLICATIONS_DETAILS_FAILED = "FETCH_BRAND_APPLICATIONS_DETAILS_FAILED";
export const UPDATE_NEW_SELLER_INFO_FAILED = "UPDATE_NEW_SELLER_INFO_FAILED";
