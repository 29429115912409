import axios from "axios";

export const registerAdmin = async (token, user) => {
  try {
    const result = await axios.post("admin/register", user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const loginUser = async (user) => {
  try {
    const result = await axios.post("super/admin/login", user);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getUser = async (token) => {
  try {
    const result = await axios.get("admin/profile/detail", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateUser = async (token, values) => {
  try {
    const result = await axios.post("admin/profile/update", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updatePassword = async (token, values) => {
  try {
    const result = await axios.post("admin/profile/update/password", values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
