import { fetchCurrency as fetchCurrencyApi , fetchRates as fetchRatesApi} from "../../api/currencyApi";

import { FETCH_CURRENCY_SUCEESS, FETCH_CURRENCY_FAIL, FETCH_RATES_SUCEESS, FETCH_RATES_FAIL } from "./types";

export const fetchCurrency = () => {
  return async (dispatch) => {
    try {
      const currency = await fetchCurrencyApi();
      dispatch({
        type: FETCH_CURRENCY_SUCEESS,
        payload: currency,
      });
    } catch (err) {
      dispatch({
        type: FETCH_CURRENCY_FAIL,
        payload: err,
      });
    }
  };
};

export const fetchRates = () => {
  return async (dispatch) => {
    try {
      const rates = await fetchRatesApi();
      dispatch({
        type: FETCH_RATES_SUCEESS,
        payload: rates,
      });
    } catch (err) {
      dispatch({
        type: FETCH_RATES_FAIL,
        payload: err,
      });
    }
  };
};
