// import { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SupplierLayout from "./assets/SupplierLayout";

const { Search } = Input;

const FindSupplier = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const columns = [
    {
      title: "Supplier ID",
      dataIndex: "supplier_id",
      key: "supplier_id",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      key: "contact_person",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "RC Number",
      dataIndex: "rc_number",
      key: "rc_number",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  return (
    <SupplierLayout
      headerTitle="The FINANCE PORTAL"
      headerSubtitle="Get insights on all finance metrics on Losode"
      dashboardBgColor="#fff0"
    >
      <div
        className="manage-payment-container"
        style={{ marginBottom: "32px" }}
      >
        <div>
          <h2 className="txt-title">Find Supplier</h2>
          <div
            style={{
              width: "64%",
              height: "48px",
              margin: "16px auto 24px",
            }}
          >
            <Search
              className="search"
              allowClear
              enterButton="Search"
              // onSearch={onSearch}
              placeholder="enter contact person, company name or supplier id "
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
      </div>
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            {/* {isTabletOrMobile && (
            <div className="hello-store-mobile">Hello {details.storeName}</div>
          )} */}
            {/* <div className="manage-payment-adspace"></div> */}
            {/* <div>
            <div
                style={{
                  width: "50%",
                  height: "48px",
                  margin: "16px auto 24px",
                }}
              >
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="enter contact person, company name or supplier id "
                  prefix={<SearchOutlined />}
                />
              </div>
            </div> */}
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div className="manage-payment-body-content-header-title">
                  Search Result
                </div>
                <div className="manage-payment-body-content-header-subtitle">
                  <strong>28,890</strong> Buyers Found with Order ID 234567896
                </div>
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    // dataSource={transactions?.data}
                    columns={columns}
                    rowKey={(record) => record.transaction_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            padding: 24px 40px;
            background: white;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .ant-table-thead .ant-table-cell {
            background-color: #000;
            color: #fff;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .txt-title {
            line-height: normal;
            margin-bottom: 32px;
            font-size: 20px;
            font-family: "DomaineSansText-Regular";
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 18px;
            font-weight: bold;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 14px;
            width: 100%;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 200px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 40px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }

          @media (max-width: 640px) {
            .manage-payment-coverall-container {
              margin-left: 0;
            }
            .manage-payment-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .manage-payment-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .manage-payment-body-content-header-container {
              display: flex;
              align-items: center;
            }

            .manage-payment-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 180px;
            }

            .manage-payment-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .manage-payment-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .manage-payment-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }

            .manage-payment-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
              margin: 0;
            }
            .manage-payment-body-sidebar {
              width: 100%;
              margin-top: 0;
              margin-bottom: 24px;
              padding: 0 16px;
              border: 0;
              margin-right: 0;
              display: flex;
              justify-content: flex-end;
            }
            .manage-payment-body-sidebar-content {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .manage-payment-body-sidebar-content-title {
              font-weight: normal;
              font-size: 12px;
              margin-bottom: 0px;
            }
            .manage-payment-body-sidebar-content-select {
              width: auto;
            }
            .manage-payment-body-sidebar-content-select .ant-select-selector {
              margin: 0 !important;
              height: auto !important;
              font-size: 12px !important;
              padding: 0 16px !important;
            }
            .manage-payment-body-sidebar-content-select .ant-select-arrow {
              top: 50%;
              height: auto;
              width: auto;
            }
            .manage-payment-body-content {
              width: 100%;
              padding: 0 16px;
            }
            .manage-payment-body-content-top-actions {
              gap: 16px;
              margin-top: 16px;
              display: none;
            }
            .manage-payment-payment-summary-section {
              width: 100%;
              flex-direction: column;
              gap: 16px;
            }
            .payment-summary-grey-box {
              padding: 16px;
              font-size: 12px;
            }
            .payment-summary-pink-box {
              padding: 16px;
              width: auto;
              font-size: 12px;
            }
            .payment-summary-big-number {
              font-size: 14px;
            }
            .payment-summary-pink-box-button {
              font-size: 12px;
              padding: 16px;
              line-height: 1;
            }
            .manage-payment-body-content-section {
              margin-top: 24px;
            }
            .manage-payment-body-content-section-subtitle {
              font-size: 14px;
            }
            .payment-summary-underline-link {
              padding-bottom: 0px;
            }
            .manage-payment-transaction-table .ant-table {
              font-size: 12px;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
          }
        `}</style>
      </div>
    </SupplierLayout>
  );
};

export default FindSupplier;
