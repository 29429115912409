import {
  registerAdmin as registerAdminApi,
  loginUser as loginUserApi,
  getUser as getUserApi,
  updateUser as updateUserApi,
  updatePassword as updatePasswordApi,
} from "../../api/userApi";

import {
  REGISTER_USER_FAIL,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_FAIL,
} from "./type";

export const registerAdmin = (token, values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_START,
        payload: "",
        error: false,
      });
      const res = await registerAdminApi(token, values);
      if (res.status === "ok") {
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: res.message,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: REGISTER_USER_FAIL,
          payload: res.message,
          error: true,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const login = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_USER_START,
      });
      const res = await loginUserApi(values);
      if (res.status === "ok") {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: res,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: LOGIN_USER_FAIL,
          payload: res.message,
          error: true,
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const getUser = (token) => {
  return async (dispatch) => {
    try {
      const res = await getUserApi(token);
      dispatch({
        type: GET_USER_DETAILS,
        payload: res,
        error: false,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_DETAILS_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const updateUser = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateUserApi(token, values);
      if (res.status === "ok") {
        dispatch({
          type: UPDATE_USER_DETAILS,
          payload: res,
          error: false,
        });
        getUserApi(token);
      }
      if (res.status === "not ok") {
        dispatch({
          type: UPDATE_USER_DETAILS_FAIL,
          payload: res.message,
          error: true,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const updatePassword = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updatePasswordApi(token, values);
      if (res.status === "ok") {
        dispatch({
          type: UPDATE_USER_DETAILS,
          payload: res,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: UPDATE_USER_DETAILS_FAIL,
          payload: res.message,
          error: true,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGOUT_USER,
      });
    } catch (err) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
