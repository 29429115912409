import { useState, useEffect } from "react";
import Dashboard from "../seller-mgmt/Dashboard";
import {
  // Checkbox,
  Form,
  Input,
  // DatePicker,
  Col,
  Row,
  Select,
  Button,
  Space,
  message,
} from "antd";
import { countries } from "../../assets/constants";
import {  fetchStates } from "../../utils/getStates";
import getLatLng from "../../utils/getLatLng";
// import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
const { Option } = Select;

// const categoryOptions = [
//   "Clothing",
//   "Shoes",
//   "Accessories",
//   "Beauty",
//   "Grooming",
// ];

const EditSellerInfo = () => {
  const { brandApplicationDetails } = useSelector((state) => state.brands);
  const { info } = useSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();

  const { updateNewSellerInfo } = bindActionCreators(
    brandActionCreators,
    dispatch
  );

  const [sellerForm] = Form.useForm();

  const [countryValue, setCountryValue] = useState("Nigeria");
  const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  const [imageUrl, setUrl] = useState("");
  // const [fileName, setFileName] = useState("");
  // const hiddenFileInput = useRef(null);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const onSelectCountry = async () => {
    if (countryValue && states.length === 0) {
      const res = await fetchStates(countryValue);
      setStates(res);
    }
  };

  // const getCities = async () => {
  //   setCities(await fetchCities("nigeria"));
  // };

  // const handleClick = () => {
  //   hiddenFileInput.current.click();
  // };

  // const handleChange = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   setFileName(fileUploaded.name);
  //   uploadImage(fileUploaded);
  // };

  // const uploadImage = async (file) => {
  //   const data = new FormData();
  //   data.append("file", file);
  //   data.append("api_key", "693411225724272");
  //   data.append("upload_preset", "barners");
  //   data.append("cloud_name", "losode-ng");
  //   const result = await axios.post(
  //     `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
  //     data
  //   );
  //   setUrl(result.data.secure_url);
  //   return result.data.secure_url;
  // };

  // useEffect(() => {
  //   sellerForm.setFieldsValue({
  //     brand_logo_url: fileName,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fileName]);

  useEffect(() => {
    onSelectCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  // useEffect(() => {
  //   getCities();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const onSubmit = async (values) => {
    const {
      brand_address: address,
      brand_state: state,
      brand_country: country,
    } = values;
    const resp = await getLatLng({ address, state, country });
    values.brand_latitude = resp?.split(",")[0];
    values.brand_longitude = resp?.split(",")[1];
    values.brand_phone_dial = "+234";
    values.brand_logo_url = imageUrl;
    values.brand_what_i_sell = selectedCategories;
    values.id = brandApplicationDetails.id;

    const res = await updateNewSellerInfo(info?.token, values);
    if (res.status === "ok") {
      message.success(res.message);
      history.goBack();
    }
    if (res.status === "not ok") message.success(res.message);
  };

  // const handleCategoryChange = (selected) => {
  //   setSelectedCategories(selected);
  // };

  useEffect(() => {
    if (brandApplicationDetails.brand_website_url) {
      setSelectedOption("yes");
    }
    if (brandApplicationDetails.brand_what_i_sell?.includes(",")) {
      setSelectedCategories(
        brandApplicationDetails.brand_what_i_sell.split(",")
      );
    } else {
      setSelectedCategories([brandApplicationDetails?.brand_what_i_sell]);
    }

    if (brandApplicationDetails.brand_logo_url) {
      setUrl(brandApplicationDetails.brand_logo_url);
    }
    // eslint-disable-next-line
    sellerForm.setFieldsValue({
      ...brandApplicationDetails,
      brand_established_date: moment(
        brandApplicationDetails.brand_established_date,
        "YYYY-MM-DD"
      ),
      phone: brandApplicationDetails?.brand_phone,
      brand_has_website: brandApplicationDetails?.brand_website_url
        ? "yes"
        : "no",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      dashboardBgColor="#f7f7f7"
    >
      <div className="manage-payment-container">
        <div style={{ padding: "36px 0px 8px" }}>
          <div
            style={{
              marginBottom: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => history.goBack()}
          >
            <svg
              width="28"
              height="10"
              viewBox="0 0 28 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                fill="black"
              />
            </svg>

            <span
              style={{
                fontFamily: "DomaineSansText-Light",
                marginLeft: "8px",
              }}
            >
              Back
            </span>
          </div>
        </div>
        <section className="edit-seller-info-section">
          <div className="edit-seller-info-form">
            <Form
              form={sellerForm}
              scrollToFirstError
              layout="vertical"
              onFinish={onSubmit}
              initialValues={{
                brand_country: "Nigeria",
                brand_phone_dial: "+234",
              }}
            >
              <Row
                className="form-row"
                gutter={[30, 0]}
                style={{
                  background: "white",
                  padding: "50px 60px",
                  marginBottom: 40,
                }}
              >
                <div className="apply-to-join-section-content-title">
                  <h3>Join Losode</h3>
                  <p>
                    Please enter your business details in the form and a member
                    of our team will be in touch shortly.
                  </p>
                </div>
                <Col span={12} style={{ margin: "auto" }}>
                  <Form.Item
                    name="brand_country"
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      defaultValue={countryValue}
                      value={countryValue}
                      onChange={(value) => setCountryValue(value)}
                      disabled
                    >
                      {countries.map(({ country, icon }) => (
                        <Option key={country} value={country}>
                          <img className="icon-flag" src={icon} alt={country} />
                          {country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <h2 className="seller-welcome-txt-about">About You</h2>
                  <hr className="seller-welcome-line" />
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/g,
                        message: "Only letters are allowed",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your first name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/g,
                        message: "Only letters are allowed",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your last name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Gender is required",
                      },
                    ]}
                  >
                    <Select placeholder="Select your gender" className="select">
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="brand_phone"
                    label="Phone Number"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Space.Compact style={{ width: "100%" }}>
                      <Select
                        defaultValue={"+234"}
                        style={{
                          width: "40%",
                        }}
                        onChange={(e) =>
                          sellerForm.setFieldsValue({
                            brand_phone_dial: e.target.value,
                          })
                        }
                        value={sellerForm.getFieldValue("brand_phone_dial")}
                      >
                        <Option
                          key={countries[0].dial}
                          value={countries[0].dial}
                        >
                          <img
                            className="icon-flag"
                            src={countries[0].icon}
                            alt={countries[0].country}
                          />
                          {countries[0].dial}
                        </Option>
                      </Select>
                      <Input
                        className="input"
                        type="number"
                        placeholder="Enter phone number"
                        onChange={(e) =>
                          sellerForm.setFieldsValue({ phone: e.target.value })
                        }
                        value={sellerForm.getFieldValue("phone")}
                      />
                    </Space.Compact>
                  </Form.Item>
                </Col>
              </Row>

              <Row
                className="form-row"
                gutter={[30, 0]}
                style={{
                  background: "white",
                  padding: "50px 60px",
                }}
              >
                <Col span={24} style={{ marginBottom: 20 }}>
                  <h2 className="seller-welcome-txt-about">About Your Brand</h2>
                  <hr className="seller-welcome-line" />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="brand_name"
                    label="Brand Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your brand name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="brand_email"
                    label="Brand Email Address"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        type: "email",
                        message: "Use a valid email address",
                      },
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your brand email address"
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={12}>
                  <Form.Item
                    name="brand_logo_url"
                    label="Upload Brand Logo"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Use a professionally designed logo"
                      onChange={uploadImage}
                      suffix={
                        <>
                          <Button className="btn-upload" onClick={handleClick}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "end",
                              }}
                            >
                              <img alt="upload" src="/images/upload.png" />
                              <span
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Browse
                              </span>
                            </div>
                          </Button>
                          <input
                            accept="image/*"
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </>
                      }
                    />
                  </Form.Item>
                </Col> */}

                {/* <Col span={12}>
                  <Form.Item
                    name="brand_description"
                    label="Tell us more about your brand"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="input"
                      style={{
                        padding: "12px",
                        minHeight: "80px",
                        border: "1px solid #0000007a",
                      }}
                      placeholder="Give a detailed description of your brand"
                    />
                  </Form.Item>
                </Col> */}

                {/* <Col span={12}>
                  <Form.Item
                    name="brand_address"
                    label="Brand Address"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your brand address"
                    />
                  </Form.Item>
                </Col> */}
                <Col span={12}>
                  <Form.Item
                    name="brand_state"
                    label="State"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select your state"
                      showSearch
                    >
                      {states?.map(({ state_name: state }) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    name="brand_city"
                    label="City"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select your city"
                      showSearch
                    >
                      {cities?.map((city, index) => (
                        <Option key={index} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                {/* <Col span={12}>
                  <Form.Item
                    name="brand_area"
                    label="Area"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your area"
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col span={12}>
                  <Form.Item
                    name="brand_postal_code"
                    label="Post Code (Optional)"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter post code"
                    />
                  </Form.Item>
                </Col> */}

                <Col span={12}>
                  <Form.Item
                    name="brand_has_website"
                    label="Does Your Brand Have A Website"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      className="select"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <Select.Option value="yes">Yes</Select.Option>
                      <Select.Option value="no">No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {selectedOption === "yes" && (
                  <Col span={12}>
                    <Form.Item
                      name="brand_website_url"
                      label="Could you please provide the URL to your website?"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        placeholder="www.example.com"
                        className="reg-input"
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col span={12}>
                  <Form.Item
                    name="brand_social_platform"
                    label="Brand Social Media Platform"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your media platform"
                      className="select"
                    >
                      <Select.Option value="twitter(x)">
                        Twitter(x)
                      </Select.Option>
                      <Select.Option value="instagram">Instagram</Select.Option>
                      <Select.Option value="facebook">Facebook</Select.Option>
                      <Select.Option value="threads">Threads</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="brand_social_media_profile"
                    label="Social Media Profile Link"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your brand social media profile link"
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    name="brand_established_date"
                    label="When was your brand Established"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker className="reg-date" />
                  </Form.Item>
                </Col> */}
                {/* <Col span={12}>
                  <Form.Item
                    name="brand_what_i_sell"
                    label="What Does Your Brand Sell?"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select categories"
                      value={selectedCategories}
                      onChange={handleCategoryChange}
                      optionLabelProp="label"
                      className="select-multiple"
                    >
                      {categoryOptions.map((category) => (
                        <Select.Option
                          key={category}
                          value={category}
                          label={category}
                        >
                          <Checkbox
                            checked={selectedCategories?.includes(category)}
                          >
                            {category}
                          </Checkbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col span={12}>
                  <Form.Item
                    name="how_you_heard_about_us"
                    label="Tell Us How You Heard About Losode"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select className="select">
                      <Select.Option value="search engine">
                        Search Engine
                      </Select.Option>
                      <Select.Option value="referred by a friend">
                        Referred by a friend
                      </Select.Option>
                      <Select.Option value="advert">Advert</Select.Option>
                      <Select.Option value="social media">
                        Facebook / Intagram / Twitter / LinkedIn
                      </Select.Option>
                      <Select.Option value="losode event">
                        A Losode Event
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="reg-btn-register"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </section>

        <style>
          {`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: -32px;
          }
        .apply-to-join {
          font-family: "DomaineSansText-Light";
          background: #f9f9f9
        }

        .apply-to-join-navbar-content {
          display: flex;
          justify-content: center;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
          box-shadow: 0 2px 10px 0 #05050526;
          z-index: 1000;
          position: fixed;
          top: 0;
        }

        .apply-to-join-navbar-div {
          width:80%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .apply-to-join-navbar-title {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          height: 100%;
          gap: 8px;
        }

        .apply-to-join-section {
          width: 100%;
          padding-top: 100px;
          padding-bottom: 30px;
        }

         .btn-upload {
          margin-right: -10px !important;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #000000;
          color: #fff;
          height: 48px;
          width: 130px;
        }
        .apply-to-join-section-content {
          width: 80%;
          margin: 0px auto;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
          .apply-to-join-section-content-title {
        background-color:#fff;
          width: 100%;
          margin: 0px auto 20px;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title h3 {
          font-size: 24px;
          font-family: 'DomaineSansText-Black';
          font-weight: normal;
          margin-bottom: 4px;
        }
        .apply-to-join-section-content-title p {
          text-align: center;
          width: 500px;
          font-weight: 500;
          font-size: 16px;
        }
        .apply-to-join-section-form {
          width: 100%;
          padding-top: 20px;
        }

      .apply-to-join-section-form .ant-input-affix-wrapper{
        padding:0px 0px 0px 10px;
      }

        // img {
        //   width: 20px;
        //   height: 20px;
        //   margin-right:10px;
        // }
        .reg-input,
        .reg-date {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: 48px;
          width: 100%;
        }
        .reg-input-phone {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: fit;
          width: 100%;
          padding: 0px; /* Adjust padding as needed */
        }
        .reg-input-phone .ant-input {
          height: 48px;
          border: none;
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
          content: ' ';
        }  
    
        .reg-input-phone:focus,
        .reg-input-phone:hover {
          border: 1px solid #000;
        }
        .reg-input:focus,
        .reg-input:hover {
          border: 1px solid #000;
        }

        .reg-btn-register {
          font: normal 16px DomaineSansText-Regular;
          width: 300px;
          height: 48px;
          margin-top: 10px;
        }

        .select-multiple{
          width: 90px;
          height: fit;
        }
        .ant-form-item {
          margin-bottom: 20px;
        }
        .ant-form-item-extra,
        .ant-form-item-explain {
          padding-top: 4px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 14px;
          fon-weight: bold;
        }
        .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 16px;
          fon-weight: bold;
          font: 700;
        }
        .ant-picker-input > input {
            font-size: 16px;
            font-weight: 300;
          }
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
            border: 1px solid #ccc;
            font-size: 16px;
            height: 48px;
            padding: 0px;
          }
          .prefix-select {
            width: 100px;
            height: 48px;
            border: none;
            outline: none;
          }
          .prefix-select .ant-select {
            width: 90px;
            height: 48px;
            border: 0;
            outline: 0;
          }
          
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
            display: flex;
            padding: 7px 10px;
        }
        .ant-picker:hover,
        .ant-picker-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: black;
          border-right-width: 1px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 48px;
          border: 1px solid #ccc;
        }
        .ant-input-group-addon:first-child {
          border: 0;
          background: transparent;
          border-right: 1px solid #ccc;
          
        }

        .apply-to-join-navbar-title-text {
          margin: auto;
          text-transform: capitalize;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          margin-top: 60px;
          padding: 30px 50px;
        }
        .apply-to-join-success-div h3{
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px DomaineSansText-Light;
          color: white;
          text-align: center;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-search{
          top:10px;
        }
      `}
        </style>
      </div>
    </Dashboard>
  );
};

export default EditSellerInfo;
