import { fetchBuyerDetails } from "../../api/buyersApi";

import { FETCH_BUYER_DETAILS, FETCH_BUYER_DETAILS_FAILED } from "./types";

export const getBuyerDetails = (token, id) => {
  return async (dispatch) => {
    try {
      const res = await fetchBuyerDetails(token, id);
      dispatch({
        type: FETCH_BUYER_DETAILS,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: FETCH_BUYER_DETAILS_FAILED,
        payload: err,
      });
    }
  };
};
