import { useEffect, useState } from "react";
import { Col, Form, Button, Input, Row, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import Header from "../components/Header";
import axios from "axios";

const Settings = () => {
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const info = location.state;

  const { getUser, updateUser, updatePassword } = bindActionCreators(
    userActionCreators,
    dispatch
  );
  const { settings: details } = useSelector((state) => state.user);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadImg = async ({ file, onSuccess, onError }) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "admin-avatar");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    if (result.status === 200) {
      setFileName(file.name);
      setUrl(result.data.secure_url);
      file.status = "success";
      onSuccess("ok");
    } else onError("error");
  };

  const onSubmit = async (values) => {
    const res = await updateUser(info.token, { ...values, imageUrl });
    if (res.status === "ok") message.success(res.message);
    if (res.status === "not ok") message.error(res.message);
  };

  const changePassword = async (values) => {
    const res = await updatePassword(info.token, values);
    if (res.status === "ok") message.success(res.message);
    if (res.status === "not ok") message.error(res.message);
  };

  useEffect(() => {
    getUser(info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="logo-container">
        <img src="/images/logo.png" alt="logo" className="logo" />
        <Header search={false} top={0} />
      </div>

      <div className="form-container">
        <h6 className="txt-title">Account settings</h6>
        <sub className="txt-desc">see your personal information here</sub>
        <Form
          className="form"
          form={form}
          layout="vertical"
          name="settings"
          onFinish={onSubmit}
          initialValues={details}
          requiredMark={false}
          scrollToFirstError
        >
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first Name!",
                  },
                ]}
              >
                <Input className="input" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last Name!",
                  },
                ]}
              >
                <Input className="input" placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input className="input" placeholder="Email Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="imageUrl">
                <Upload
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  className="upload"
                  customRequest={uploadImg}
                  showUploadList={false}
                >
                  <Input
                    className="img-input"
                    placeholder="Upload profile picture (not more than 2mb)"
                    value={fileName}
                    readOnly
                    prefix={
                      <img
                        className="user-avatar"
                        alt="avatar"
                        src={details.imageUrl}
                      />
                    }
                  />
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button className="btn-save" type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="divider">
          <h6 className="txt-title">Security</h6>
          <hr className="line" />
        </div>
        <Form
          className="form"
          form={passwordForm}
          layout="vertical"
          name="password"
          onFinish={changePassword}
          requiredMark={false}
          scrollToFirstError
        >
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter current password",
                  },
                ]}
              >
                <Input.Password
                  className="input"
                  type="password"
                  placeholder="Current Password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
              >
                <Input.Password
                  className="input"
                  type="password"
                  placeholder="Create Password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="input"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button className="btn-save" type="primary" htmlType="submit">
                  Change Password
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <style jsx={true}>{`
        .logo-container {
          display: flex;
          margin: 30px;
        }
        .logo {
          width: 148px;
        }
        .form-container {
          margin: 85px auto;
          width: 60%;
        }
        .form {
          margin-top: 30px;
        }
        .txt-title {
          font: normal normal 30px/39px DomaineSansText-Light;
          color: #000000;
          margin: 0;
        }
        .txt-desc {
          font: normal normal 18px/23px DomaineSansText-Light;
          color: #707070;
        }
        .input {
          border: 1px solid #707070;
          height: 63px;
        }
        .input:focus,
        .input:hover,
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector:hover {
          border-color: #000;
        }
        .ant-upload {
          width: 100%;
        }
        .user-avatar {
          width: 42px;
          height: 42px;
        }
        .img-input {
          border: 0.5px dashed #d18f8f;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;
          height: 63px;
          text-align: center;
        }
        .btn-save {
          background: #000000;
          border-color: #000;
          color: #fff;
          float: right;
          font: normal normal 18px/23px DomaineSansText-Regular;
          height: 63px;
          width: 222px;
        }
        .btn-save:hover {
          background: #000000;
          opacity: 0.8;
          transition: 0.5s;
        }
        .divider {
          align-items: center;
          display: flex;
          margin-top: 50px;
        }
        .line {
          color: #b7b7b7;
          flex: 1;
          margin-left: 10px;
        }
      `}</style>
    </div>
  );
};

export default Settings;
