import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Home from "../pages/superAdmin/Home";

const account = ({ user }) => {
  const Account = () => {
    switch (user.info.role) {
      // case 1:
      //   return <Home />;
      // case 5:
      //   return <Redirect to="/seller-dashboard" />;
      // case 6:
      //   return <Redirect to="/stats" />;
      // case 7:
      //   return <Redirect to="/logistics" />;
      // case 8:
      //   return <Redirect to="/finance-dashboard" />;

      default:
        return <Home />;
    }
  };
  return user.isAuthenticated ? Account() : <Redirect to="/login" />;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(account);
