import axios from "axios";

export const fetchStates = async (country) => {
  const result = await axios.get(`utility/countries`);

  if (result.data.state === "ok") {
    const countryData = result.data.data.filter(
      (data) => data.name === country
    );
    return countryData[0]?.states;
  }
};

export const fetchCities = async (country) => {
  const res = await axios.get(`https://www.losode.dev/api/v2/cities`);
  if (res.status === 200) {
   return res.data[country];
  }
};

// export const fetchCities = async (state, countryValue) => {
//   let country_code;

//   if (countryValue === "Nigeria") country_code = "NG";
//   if (countryValue === "Ghana") country_code = "GH";
//   if (countryValue === "South Africa") country_code = "ZA";
//   if (countryValue === "United States of America") country_code = "US";
//   if (countryValue === "United Kingdom") country_code = "GB";

//   if (country_code && state) {
//     const res = await axios.get(
//       `https://www.losode.dev/api/v2/cities?state=${state}&country=${country_code}`
//     );
//     if (res.data.status === "ok") {
//       const data = res.data.data
//         .map((city) => city.name)
//         .sort((a, b) => a.localeCompare(b));
//       const citySet = new Set([...data]);

//       return Array.from(citySet);
//     }
//   }
// };
