// import { useEffect, useState, createRef } from "react";
import { Button, Checkbox, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons"
// import { useNavigate } from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { useSelector, useDispatch } from "react-redux";
import OrderView from "../seller/OrderView";
// import HelpFeedback from "./HelpFeedback";
// import { storeActionCreators } from "../../redux/stores";
// import formatter from "../../utils/formatter";
// import PhoneVerificationModal from "./PhoneVerificationModal";
// import { useMediaQuery } from "react-responsive";
import Dashboard from "./Dashboard";

const {Search} = Input

const SearchedOrders = () => {
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });


  return (
    <Dashboard
    headerTitle="The Seller Management Portal"
    //headerSubtitle="Get inisights on all users using your app"
    >
    <div className="dashboard-container">
      <div className="manage-listing-all-content">
        <div className="dashboard-coverall-container">
          {/* <div className="dashboard-adspace"></div> */}

          <div className="dashboard-body-main">
            <div className="dashboard-body-content-header">
              <div className="dashboard-body-content-header-container">
                <div
                  className="dashboard-body-content-header-title"
                  // onClick={() => {
                  //   setPhoneVerifyModalVisible(true);
                  // }}
                >
                  Manage Orders
                </div>
                <div className="dashboard-body-content-header-subtitle">
                  View and edit your listed items here
                </div>
                
              </div>

              <div>
                <Select
                placeholder="Sort"
                className="manage-orders-body-sidebar-content-select"
                >
                  <Select.Option value="latest">Recent</Select.Option>
                  <Select.Option value="price">Price</Select.Option>
                  <Select.Option value="alphabetical">A-Z</Select.Option>
                </Select>
              </div>

             
              
            </div>

            <div className="dashboard-body-container">
              <div className="dashboard-body-content">
                <div className="dashboard-body-bold-numbers-container">
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {/* {formatter(
                        settlementInfo?.last_90_days,
                        rate,
                        code,
                        indicator
                      )} */}
                      23,490
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      NEW ORDERS IN 24 HOURS
                    </div>
                  </div>
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {/* {totalProducts} */}
                      2,000,000
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      APPROVED ORDERS
                    </div>
                  </div>
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {/* {sales} */}
                      4
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      REJECTED ORDERS
                    </div>
                  </div>
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {/* {sales} */}
                      11,490
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      SENT ITEMS
                    </div>
                  </div>
                </div>

                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:40}}>
                <div style={{ width: "50%", height: "48px" }}>
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="Enter Order ID Or Brand Name"
                  prefix={<SearchOutlined/>}
                />
                </div>
                

                </div>
              

                <div className="dashboard-body-orders-section">
                  <div className="dashboard-body-orders-title">24 orders found for Kaliki By Ete</div>
                  {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                  <div className="manage-orders-body-content-top-actions">
                    <div className="top-actions-checkbox-container">
                      <Checkbox
                      >
                        Select All
                      </Checkbox>
                    </div>
                    <div className="top-actions-buttons-container">
                      <Button>Reject Order</Button>
                      <Button>Report Buyer</Button>
                      <Button>Confirm Item Sent</Button>
                    </div>
                  </div>

                  <div className="dashboard-body-content-listings-container">
                    {/* {orders?.data?.length > 0 ? (
                      orders.data
                        .slice(0, 4)
                        .map((order) => ( */}
                          <OrderView
                            // key={order.id}
                            // product={order}
                            type="Dashboard"
                          />
                        {/* ))
                    ) : (
                      <div className="no-listings-to-show">
                        <div>
                          <div>You currently have 0 new orders</div>
                        </div>
                      </div>
                    )} */}
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    <OrderView
                      // key={order.id}
                      // product={order}
                      type="Dashboard"
                    />
                    
                  </div>
                </div>

                
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}

      <style jsx="true">{`
        .dashboard-container {
          font-family: DomaineSansText-Light;
        }

        .manage-listing-all-content {
          position: relative;
        }
        .dashboard-coverall-container {
        }
        .dashboard-body-main {
          padding-bottom: 40px;
          margin-top: 16px;
        }
        .dashboard-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }
        .dashboard-body-content-header-container {
        }
        .dashboard-body-content-header {
          display: flex;
          justify-content: space-between;
          align-items:flex-end
        }
        .dashboard-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .dashboard-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }
        .dashboard-body-bold-numbers-container {
          display: flex;
          gap: 30px;
          width: 100%;
          margin-bottom: 50px;
          margin-top: 24px;
        }
        .dashboard-body-bold-numbers-item {
          width: 32%;
          background: black;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px 10px;
        }
        .dashboard-body-bold-numbers-item-number {
          font-family: "DomaineSansText-Black";
          font-size: 24px;
        }
        .dashboard-body-bold-numbers-item-text {
          font-size: 12px;
        }
        .dashboard-body-content-listings-container {
          display: flex;
          flex-wrap:wrap
        }
        .dashboard-body-alert-box-container{
          margin-bottom:50px
        }
        .dashboard-body-alert-box {
          margin: 16px 0;
          background: #800000;
          color:white;
          padding: 16px 10px;
          width: 100%;
        }
        .dashboard-body-alert-box-title {
          font-size: 18px;
          text-decoration:underline;
          margin-bottom: 8px;
        }
        .alert-box-dispute{
          font-size:14px
        }
        .dashboard-body-orders-section {
          margin-bottom: 50px;
        }
        .dashboard-body-orders-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom:24px;
          padding-left: 5px;
        }
        .dashboard-body-community-container {
          background: #FFECB3;
          padding: 16px 10px;
          width: 100%;
          margin-bottom: 50px;
        }
        .dashboard-body-community-title {
          font-size: 20px;
          font-weight: bold;
        }
        .dashboard-body-events-section {
          margin-bottom: 50px;
        }
        .dashboard-body-events-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .dashboard-body-events-subtitle {
          margin-bottom: 24px;
          width: 90%;
        }
        .dashboard-body-partners-section {
          margin-bottom: 50px;
        }
        .dashboard-body-quick-action-button {
          height: 48px;
          background: black;
          color: white;
          font-size: 16px;
          width: 240px;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }
        .dashboard-body-link {
          text-decoration: underline;
          color: black;
        }
        .dashboard-store-link-section {
          margin-top: 24px;
          background: rgba(0, 0, 0, 0.04);
          padding: 25px 10px;
          width: 100%;
        }
        .dashboard-store-link-title {
          font-size: 18px;
          margin-bottom: 8px;
          font-weight: bold;
        }
        .dashboard-store-link-subtitle {
          width: 90%;
        }
        .dashboard-events-image {
          width: auto;
          height: 200px;
        }
        .dashboard-partners-image {
          width: auto;
          height: 200px;
        }
        .dashboard-body-partners-images-container {
          display: flex;
          gap: 30px;
        }
        .dashboard-body-quick-action-button-container{
          display: flex;
          gap: 16px;
          flex-wrap: wrap;
          margin-bottom: 50px;
        }
        .dashboard-body-quick-action-title{
          font-size: 18px;
          font-weight: bold;
          margin-bottom:8px
        }
        .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
          background: #000;
          border: 1px solid #000;
          color: #fff;
          height: 48px;
          font-family: "DomaineSansText-Light";
          padding:0 24px
        }
        .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
          height: 48px;
          border-color: black;
        }
        .manage-orders-body-sidebar-content-select .ant-select-selector {
          margin: 0 !important;
          height: auto !important;
          font-size: 14px !important;
          padding: 0 16px !important;
          width:100px !important;
          border-color: black !important;
        }
        .manage-orders-body-sidebar-content-select .ant-select-arrow {
          top: 50%;
          height: auto;
          width: auto;
        }
        .manage-orders-body-content-top-actions{
          display:flex;
          justify-content:space-between;
          margin-bottom:24px;
        }
        .top-actions-checkbox-container{
          padding: 6px;
          border: 1px solid black;
          width: fit-content;
          height: fit-content;
        }
        .top-actions-checkbox-container .ant-checkbox-wrapper{
          font-size:12px
        }
        .top-actions-buttons-container button{
          width: 140px;
          background: black;
          color: white;
          padding: 6px 0px;
          font-size: 12px;
          height: 38px;
        }
        .top-actions-buttons-container{
          display:flex;
          gap:8px
        }


        @media (max-width: 640px) {
          .dashboard-coverall-container {
            margin-left: 0;
          }
          .dashboard-adspace {
            background-image: url(images/seller-stats-banner.png);
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }

          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }
          .dashboard-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .dashboard-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .dashboard-body-content-header-title {
            font-size: 18px;
          }
          .dashboard-body-quick-action-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            margin-top: auto;
          }
          .dashboard-body-bold-numbers-item {
            width: auto;
            justify-content: center;
            padding: 16px;
            text-align:center
          }
          .dashboard-body-bold-numbers-container {
            gap: 8px;
            margin-bottom: 24px;
            margin-top: 0px;
            flex-wrap:wrap
          }
          .dashboard-body-bold-numbers-item-number {
            font-size: 16px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 10px;
          }
          .dashboard-body-alert-box {
            padding: 16px 10px;
            margin-bottom: 24px;
          }
          .dashboard-body-community-container {
            padding: 16px 10px;
            margin-bottom: 24px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 16px;
            width: 100%;
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 24px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 120px;
          }
          .dashboard-body-partners-images-container {
            gap: 8px;
          }
          .dashboard-store-link-section {
            padding: 16px 10px;
            margin-bottom: 24px;
          }
          .dashboard-events-image {
            width: auto;
            height: 300px;
          }
          .process-icon-container {
            padding: 8px;
            border-radius: 50%;
            border: 1px solid #800000;
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .process-icon-div-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            margin: 16px 0;
          }
          .process-icon {
            color: #800000;
          }
          .dashboard-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .dashboard-body-bold-numbers-item-earned{
            width: 100%;
          }
          .dashboard-body-bold-numbers-item-listed, .dashboard-body-bold-numbers-item-sold {
            flex:2
          }
        }
      `}</style>
    </div>
    </Dashboard>
  );
};

export default SearchedOrders;
