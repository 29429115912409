import { useEffect } from "react";
import { Table, Input, DatePicker } from "antd";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
// import { useNavigate } from "react-router-dom";
// import formatter from "../../utils/formatter";
import { SearchOutlined } from "@ant-design/icons";
import Dashboard from "../seller-mgmt/Dashboard";
import { brandActionCreators } from "../../redux/seller";
import capitalizeStr from "../../utils/capitalizeStr";

const { Search } = Input;

const BrandApplications = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const type = window.location.pathname.split("/")[2];

  const disabledDate = (current) => {
    // Can not select days before today
    const date = new Date(Date.now());
    const prevTime = new Date(date.getTime());
    prevTime.setDate(date.getDate() - 1);
    return current && current < prevTime;
  };

  const { fetchBrandApplications } = bindActionCreators(
    brandActionCreators,
    dispatch
  );
  const { info } = useSelector((state) => state.user);
  const { brandApplications } = useSelector((state) => state.brands);

  useEffect(() => {
    fetchBrandApplications(type, info?.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, type]);

  const columns = [
    {
      align: "center",
      title: "Name",
      dataIndex: "firstname",
      key: "name",
      render: (item, record) => (
        <p style={{ textTransform: "capitalize", textAlign: "left" }}>
          {capitalizeStr(`${record.firstname} ${record.lastname}`)}
        </p>
      ),
      width: 200,
    },
    {
      align: "center",
      title: "Email Address",
      dataIndex: "brand_email",
      key: "emailAddress",
    },
    {
      align: "center",
      title: "Phone No.",
      dataIndex: "brand_phone",
      key: "phoneNumber",
      render: (text, record) => (
        <p>
          {record.brand_phone_dial}
          {record.brand_phone}
        </p>
      ),
    },
    {
      align: "center",
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brandName",
    },
    { align: "center", title: "State", dataIndex: "brand_state", key: "state" },
    // { align: "center", title: "City", dataIndex: "brand_city", key: "city" },
    // { align: "center", title: "Area", dataIndex: "brand_area", key: "area" },
    {
      align: "center",
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (item) => <p>{new Date(item).toLocaleString()}</p>,
    },
  ];

  const waitListcolumns = [
    {
      align: "center",
      title: "First Name",
      dataIndex: "firstname",
      key: "firstName",
    },
    {
      align: "center",
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastName",
    },
    {
      align: "center",
      title: "Email Address",
      dataIndex: "brand_email",
      key: "emailAddress",
    },
    {
      align: "center",
      title: "Phone No.",
      dataIndex: "brand_phone",
      key: "phoneNumber",
      render: (text, record) => (
        <p>
          {record.brand_phone_dial}
          {record.brand_phone}
        </p>
      ),
    },
    {
      align: "center",
      title: "Country",
      dataIndex: "brand_country",
      key: "brandName",
    },
    {
      align: "center",
      title: "Brand Categories",
      dataIndex: "brand_what_i_sell",
      key: "brandName",
    },
  ];

  return (
    <Dashboard
      headerTitle="The Seller Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            {/* {isTabletOrMobile && (
            <div className="hello-store-mobile">Hello {details.storeName}</div>
          )} */}
            {/* <div className="manage-payment-adspace"></div> */}
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div className="manage-payment-body-content-header-title">
                  {capitalizeStr(type)} Applications
                </div>
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div
                  className="manage-payment-body-content-section"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ height: "48px", width: "40%" }}>
                    <Search
                      className="search"
                      allowClear
                      enterButton="Search"
                      // onSearch={onSearch}
                      placeholder="Enter seller name"
                      prefix={<SearchOutlined />}
                    />
                  </div>

                  <div></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "11px",
                      gap: 30,
                    }}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Start date"
                      disabledDate={disabledDate}
                    />
                    <DatePicker
                      className="date"
                      placeholder="End date"
                      disabledDate={disabledDate}
                    />
                  </div>
                </div>

                <h1 className="txt-total-brands">
                  {brandApplications?.length} Applications
                </h1>

                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={brandApplications}
                    columns={type === "rejected" ? columns : waitListcolumns}
                    rowKey={(record) => record.id}
                    rowClassName="seller-row"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          type === "rejected" &&
                            history.push(`/${type}-seller-info/${record.id}/`);
                        },
                      };
                    }}
                    pagination={{
                      pageSize: 20,
                      total: brandApplications?.length,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: 16px;
          }
          .ant-table-thead > tr > th {
            background-color: #000;
            border: 0px;
            color: #fff;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
            margin-bottom: 24px;
            width: 100%;
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            // background-color: black !important;
            // color: white;
            height: 40px !important;
            display: flex !important;
            align-items: center !important;
            width: 120px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            // color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 40px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }
          .seller-row {
            cursor: pointer;
          }
          .date {
            width: 122px;
            height: 48px;
          }
          .txt-total-brands {
            font-size: 16px;
            line-height: 24px;
            margin-top: 35px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default BrandApplications;
