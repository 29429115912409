import React from "react";
import { Form, Input, Button, DatePicker, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import Dashboard from "./Dashboard";

const Voucher = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "YYYY/MM/DD";

  const onFinish = (fieldsValue) => {
    console.log("values", fieldsValue);
  };

  return (
    <Dashboard>
      <div className="voucher-container">
        <div className="div-back" onClick={() => history.goBack()}>
          <img src="/images/arrow-back.svg" alt="back" />
          <span className="txt-back">Back to Sellers Information</span>
        </div>
        <h2 className="page-title">Add Voucher</h2>
        <description className="txt-details">
          Accept coupons and discounts on products
        </description>
        <Form
          className="form"
          form={form}
          name="Product"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Col span={24}>
            <Form.Item
              name="percentage"
              rules={[
                {
                  required: true,
                  message: "Please input discount percentage!",
                },
              ]}
            >
              <Input
                className="input"
                placeholder="Discount Percentage(eg. 10%)"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input coupon code!",
                },
              ]}
            >
              <Input
                className="input"
                placeholder="Coupon Code(eg. LOSODENEW)"
              />
            </Form.Item>
          </Col>
          <Row>
            <Col className="date-div" span={10}>
              <Form.Item
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please add coupon start date",
                  },
                ]}
              >
                <DatePicker
                  className="date"
                  placeholder="Start Date"
                  format={dateFormat}
                />
              </Form.Item>

              <Form.Item
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: "Please add coupon expiry date",
                  },
                ]}
              >
                <DatePicker
                  className="date"
                  placeholder="End Date"
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button className="btn-submit" type="primary" htmlType="submit">
                Add Voucher
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <style jsx="true">{`
        .div-back {
          cursor: pointer;
        }
        .txt-back {
          font: normal normal 16px/19px Roboto;
          color: #545f7d;
          margin-left: 14.41px;
        }
        .page-title {
          font: normal bold 24px/28px Roboto;
          color: #800000;
          margin-top: 26px;
          margin-bottom: 0;
        }
        .txt-details {
          font: normal bold 14px/16px Roboto;
          color: #727376;
          margin-top: 6px;
        }
        .form {
          margin-top: 40px;
        }
        .input {
          height: 62px;
          padding: 4px 0px 4px 11px;
        }
        .date-div {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
        }
        .date {
          background: #000;
          height: 63px;
          width: 193px;
        }
        .ant-picker-input > input {
          text-align: center;
        }
        .ant-picker-suffix {
          color: #fff;
          width: 14.95px;
          height: 12.88px;
        }
        .btn-submit {
          width: 195px;
          height: 63px;
          margin-top: 40px;
          font: normal 500 18px/21px Roboto;
        }
      `}</style>
    </Dashboard>
  );
};

export default Voucher;
