import React from "react";
import SupplierLayout from "./supplier/assets/SupplierLayout";

const Dashboard = () => {
  return (
    <SupplierLayout
      headerTitle="The Finance Portal"
      headerSubtitle="Get inisights on all Finance Metrics on Losode"
      currentPageKey="1"
    >
      <div>Dashboard</div>;
    </SupplierLayout>
  );
};

export default Dashboard;
