import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "../pages/Login";
import Add from "../pages/superAdmin/AddAdmin";
import Brands from "../pages/seller-mgmt/NewBrands";
import Management from "../pages/management/Details";
import Logistics from "../pages/logistics/Dashboard";
// import SellerInfo from "../pages/customer-exp/Seller-Info";
import Voucher from "../pages/seller-mgmt/Voucher";
import Products from "../pages/seller-mgmt/Products";
import Approved from "../pages/seller-mgmt/Approved";
import Added from "../pages/seller-mgmt/Added";
import Product from "../pages/seller-mgmt/Product";
import PrivateRoute from "../components/PrivateRoute";
import Account from "../components/Account";
import Unauthorized from "../components/Unauthorized";

import ScrollToTop from "../utils/scrollToTop";
import Notfound from "../components/Notfound";
// import AddSeller from "../pages/superAdmin/AddSeller";
import AddBrand from "../pages/superAdmin/AddBrand";
// import AddProduct from "../pages/customer-exp/AddProduct";
import Settings from "../pages/Settings";
import EditProfile from "../pages/seller-mgmt/EditProfile";
import NewOrders from "../pages/seller-mgmt/orders/NewOrders";
import CanceledOrders from "../pages/seller-mgmt/orders/CanceledOrders";
import ApprovedOrders from "../pages/seller-mgmt/orders/ApprovedOrders";
import SellerDashboard from "../pages/seller/SellerDashboard";
import ListAnItem from "../pages/seller/ListAnItem";
import CreateSeller from "../pages/seller-mgmt/AddSeller";
import StoreSettings from "../pages/seller/StoreSettings";
import OrderDetails from "../pages/seller/OrderDetails";
import SellerOrders from "../pages/seller/SellerOrders";
import ManageOrders from "../pages/seller-mgmt/ManageOrders";
import SearchedOrders from "../pages/seller-mgmt/SearchedOrders";
import FlaggedOrders from "../pages/seller-mgmt/FlaggedOrders";
import ManageListings from "../pages/seller-mgmt/ManageListings";
import SellerInformation from "../pages/seller/SellerInfo";
import SellerManageListings from "../pages/seller/SellerManageListings";
import FindOrders from "../pages/seller/FindOrders";
import ActivateStore from "../pages/seller/ActivateStore";
import ListingPreview from "../pages/seller/ListingPreview";
import EditListing from "../pages/seller/EditListing";
import StorePreview from "../pages/seller/StorePreview";
import BuyerDashboard from "../pages/buyer/BuyerDashboard";
import BuyerInformation from "../pages/buyer/BuyerInfo";
import FindBuyer from "../pages/buyer/FindBuyer";
import AllSuppliers from "../pages/finance/supplier/AllSuppliers";
import FindSupplier from "../pages/finance/supplier/FindSupplier";
import FinanceDashboard from "../pages/finance/Dashboard";
import AddSupplier from "../pages/finance/supplier/AddSupplier";
import SupplierInfo from "../pages/finance/supplier/SupplierInfo";
import CurrencyMap from "../pages/finance/CurrencyMap";
import AllSuppliersLogistics from "../pages/logistics/supplier/AllSuppliers";
import LogisticInfo from "../pages/logistics/LogisticInfo";
import RateConversion from "../pages/finance/RateConversion";
import PublishStores from "../pages/seller/PublishStores";
import BrandApplications from "../pages/seller/BrandApplications";
import NewSellerInfo from "../pages/seller/NewSellerInfo";
import EditSellerInfo from "../pages/seller/EditSellerInfo";
import NewBrandApplications from "../pages/seller/NewBrandApplications";
import WaitlistSellerInfo from "../pages/seller/WaitlistSellerInfo";
import Discounts from "../pages/seller/Discounts";
import ApproveSellerListings from "../pages/seller/ApproveSellerListings";
import Photoshoots from "../pages/seller/Photoshoots";
import PhotoshootInfo from "../pages/seller/PhotoshootInfo";
import ScheduledPhotoshoots from "../pages/seller/ScheduledPhotoshoots";
import ScheduledPhotoshootInfo from "../pages/seller/ScheduledPhotoshootInfo";
import AllOrders from "../pages/buyer/AllOrders";
import FindCustomerOrders from "../pages/buyer/FindOrder";

export default (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Account} />
        <PrivateRoute exact path="/settings" component={Settings} type="ALL" />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/new" component={Add} type="SUPER-ADMIN" />
        <PrivateRoute
          exact
          path="/add-seller"
          component={CreateSeller}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/seller-dashboard"
          component={SellerDashboard}
          type="SELLER-TEAM"
        />

        <PrivateRoute
          exact
          path="/activate-stores"
          component={ActivateStore}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/publish-stores"
          component={PublishStores}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/listing/:slug"
          component={ListingPreview}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/new-seller-info/:id"
          component={NewSellerInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/approved-seller-info/:id"
          component={NewSellerInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/rejected-seller-info/:id"
          component={NewSellerInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/new-seller-info/:id/edit"
          component={EditSellerInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/:slug-seller-info/:id"
          component={WaitlistSellerInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/:slug/preview"
          component={StorePreview}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/order/:saleId"
          component={OrderDetails}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/manage-orders/:type"
          component={ManageOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/manage-listings/:type"
          component={ManageListings}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/search-orders"
          component={SearchedOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/flagged-orders"
          component={FlaggedOrders}
          type="SELLER-TEAM"
        />

        <PrivateRoute
          exact
          path="/seller/:slug/settings"
          component={StoreSettings}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/add-brand"
          component={AddBrand}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/seller-orders"
          component={SellerOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/find-orders"
          component={FindOrders}
          type="SELLER-TEAM"
        />

        <PrivateRoute
          exact
          path="/brands"
          component={Brands}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/manage-new-orders"
          component={NewOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/manage-approved-orders"
          component={ApprovedOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/manage-canceled-orders"
          component={CanceledOrders}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/orders/:slug"
          component={OrderDetails}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/add-product"
          component={ListAnItem}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/new-photoshoots"
          component={Photoshoots}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/new-photoshoot/:id"
          component={PhotoshootInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/scheduled-photoshoots"
          component={ScheduledPhotoshoots}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/scheduled-photoshoot/:id"
          component={ScheduledPhotoshootInfo}
          type="SELLER-TEAM"
        />
        <PrivateRoute
          exact
          path="/stats"
          component={Management}
          type="MANAGEMENT"
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/seller/:slug/:slug/info"
          component={SellerInformation}
        />

        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:slug/manage-listings"
          component={SellerManageListings}
        />

        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/seller/:slug/edit"
          component={EditProfile}
        />

        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/seller/:slug/discounts"
          component={Discounts}
        />

        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/seller/:slug/approve-listings"
          component={ApproveSellerListings}
        />

        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/edit-listing"
          component={EditListing}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:slug/voucher"
          component={Voucher}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:slug/products"
          component={Products}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/products/:slug"
          component={Product}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:slug/recently-approved"
          component={Approved}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:type-applications"
          component={NewBrandApplications}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/brand-applications/:slug"
          component={BrandApplications}
        />
        <PrivateRoute
          type="SELLER-TEAM"
          exact
          path="/:slug/recently-added"
          component={Added}
        />

        {/* Customer dashboard */}
        <PrivateRoute
          type="CUSTOMER-TEAM"
          exact
          path="/customer-dashboard"
          component={BuyerDashboard}
        />
        <PrivateRoute
          type="CUSTOMER-TEAM"
          exact
          path="/customer-info/:id"
          component={BuyerInformation}
        />
        <PrivateRoute
          exact
          path="/find-buyer"
          component={FindBuyer}
          type="CUSTOMER-TEAM"
        />
        <PrivateRoute
          exact
          path="/customer/find-order"
          component={FindCustomerOrders}
          type="CUSTOMER-TEAM"
        />
        <PrivateRoute
          type="CUSTOMER-TEAM"
          exact
          path="/customer-all-orders"
          component={AllOrders}
        />

        {/*Finance dashboard */}
        <PrivateRoute
          type="FINANCE-TEAM"
          exact
          path="/finance-dashboard"
          component={FinanceDashboard}
        />
        <PrivateRoute
          type="FINANCE-TEAM"
          exact
          path="/all-suppliers"
          component={AllSuppliers}
        />
        <PrivateRoute
          exact
          path="/add-supplier"
          component={AddSupplier}
          type="FINANCE-TEAM"
        />
        <PrivateRoute
          exact
          path="/find-supplier"
          component={FindSupplier}
          type="FINANCE-TEAM"
        />
        <PrivateRoute
          exact
          path="/currency-map/:id"
          component={CurrencyMap}
          type="FINANCE-TEAM"
        />
        <PrivateRoute
          exact
          path="/rate-conversion"
          component={RateConversion}
          type="FINANCE-TEAM"
        />
        <PrivateRoute
          exact
          path="/supplier/:slug/:slug"
          component={SupplierInfo}
          type="FINANCE-TEAM"
        />
        <PrivateRoute
          exact
          path="/logistics"
          component={Logistics}
          type="LOGISTICS"
        />
        <PrivateRoute
          type="LOGISTICS"
          exact
          path="/all-suppliers-logistics"
          component={AllSuppliersLogistics}
        />
        <PrivateRoute
          type="LOGISTICS"
          exact
          path="/logistic-info/:slug"
          component={LogisticInfo}
        />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route path="*" component={Notfound} />
      </Switch>
    </ScrollToTop>
  </Router>
);
