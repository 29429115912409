import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { Button } from "antd";

const Home = () => {
  const history = useHistory();

  const { info } = useSelector((state) => state.user);

  const { role } = info;
  return (
    <>
      <Header search={false} mainDashboard />
      <div className="dashboard-welcome-container">
        <div className="dashboard-welcome-title">Hello {info.fname}</div>
        <div className="dashboard-welcome-subtitle">
          Welcome To Your Admin Account
        </div>
      </div>

      <div className="links-container">
        {(role === 1 || role === 5) && (
          <div
            className="link-container"
            onClick={() => history.push("/seller-dashboard")}
          >
            <img src="/images/base.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Designer Management Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}

        {(role === 1 || role === 5) && (
          <div
            className="link-container"
            onClick={() => history.push("/customer-dashboard")}
          >
            <img src="/images/base-1.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Customer Management Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}

        {(role === 1 || role === 6) && (
          <div
            className="link-container"
            onClick={() => history.push("/stats")}
          >
            <img src="/images/base-2.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Metrics Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}

        {(role === 1 || role === 8) && (
          <div
            className="link-container"
            onClick={() => history.push("/finance-dashboard")}
          >
            <img src="/images/base-3.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Finance Management Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}

        {(role === 1 || role === 7) && (
          <div
            className="link-container"
            onClick={() => history.push("/logistics")}
          >
            <img src="/images/base-4.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Logistics Management Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}

        {role === 1 && (
          <div
            className="link-container"
            onClick={() => history.push("/stats")}
          >
            <img src="/images/base-5.png" alt="" />
            <div className="links-text-container">
              <h1 className="title">The Marketing Portal</h1>
              <Button>Access Portal</Button>
            </div>
          </div>
        )}
      </div>

      <style jsx="true">{`
        .dashboard-welcome-container{
          padding: 40px 10%;
          font-family: 'DomaineSansText-Regular';
          background: url("/images/acct-bg.jpeg");       
          background-repeat: no-repeat;
          background-size: cover;
          margin-top: 80px;

        }
        .dashboard-welcome-title{
          font-size:24px;
          font-weight: bold;
        }
        .dashboard-welcome-subtitle{
          font-size:16px
        }
        .links-container {
          display: grid;
          grid-template-columns: auto auto auto;
          padding: 100px 10%;
          gap: 40px;
          max-width: 1540px;
          margin: auto;
        }
        .link-container {
        }
        .link-container img{
          width: 100%;
        }
        .links-text-container{
          padding: 24px;
          text-align: center;
          background: black;
          color: white;
        }
        .links-text-container button{
          height: 48px;
          font-family: 'DomaineSansText-Regular';
          width: 100%;
          border-radius: 0;
          margin-top: 40px;
        }
        .title {
          text-transform: uppercase;
          color: white;
          font-family: 'DomaineSansText-Light';
          font-size: 14px;
          height: 60px;
          border-bottom: 1px solid white;
          padding-bottom: 16px;
      }
        }
        .description {
          color: #545f7d;
          font: normal normal 14px/16px DomaineSansText-Light;
          margin-top: 16px;
        }
        .link-container:hover {
          cursor: pointer;
        }
      `}</style>
    </>
  );
};

export default Home;
