import React, { useEffect } from "react";
import {} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ordersActionCreators } from "../../../redux/orders";
import { Table, Tag } from "antd";
import Dashboard from "../Dashboard";


const ApprovedOrders = () => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { approved } = useSelector((state) => state.orders);
//   const currency = useSelector((state) => state.currency[0]);

  const { fetchApprovedOrders } = bindActionCreators(
    ordersActionCreators,
    dispatch
  );

  const columns = [
    {
      title: "Product Image",
      dataIndex: "productImage",
      key: "img1",
      render: (img1) => <img className="item-img" alt="product" src={img1} />,
      width: 200,
    },
    {
      align: "center",
      title: "Product Name",
      dataIndex: "productName",
      key: "name",
      width: 200,
    },
    {
      align: "center",
      title: "Brand Name",
      dataIndex: "brandName",
      key: "name",
      width: 200,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Size",
      dataIndex: "productSize",
      key: "size",
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "productColor",
      key: "color",
      render: (color) => (
        <div
          style={{
            padding: 5,
            border: "1px solid #ccc",
            width: "33px",
            display: "inline-block",
          }}
        >
          <div className="color-box" style={{ backgroundColor: `${color}` }} />
        </div>
      ),
    },
    {
      align: "center",
      title: "Price",
      dataIndex: "totalAmount",
      key: "price",
    //   render: (price) => (
    //     <p className="price">
    //       {formatter(price, currency.rate, currency.code, currency.indicator)}
    //     </p>
    //   ),
    },
    {
      align: "center",
      title: "Quantity",
      dataIndex: "totalQty",
      key: "quantityWanted",
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "sellerStatus",
      render: (status) => <Tag color="#87d068">{status}</Tag>,
    },
  ];


  useEffect(() => {
    fetchApprovedOrders(user.info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
      
    <div>
        <Dashboard>
            <h1 className="txt-title">
                Approved Orders
            </h1>
             <Table
                className="table"
                dataSource={approved}
                columns={columns}
                pagination={false}
            />
            {/* <Button className="btn-more">Load More</Button> */}
        </Dashboard>
      

         

      

      <style jsx="true">{`
        .txt-title {
          font: normal 500 30px/35px DomaineSansText-Light;
          color: #000000;
        }
        .mobile-txt-title {
          font-size: 20px;
          color: #000000;
          font-family: DomaineSansText-Regular;
          text-align: center;
          margin: 20px 0;
        }
        .table {
          margin-top: 100px;
        }
        .ant-table-thead > tr > th {
          background: #fff;
          font: normal normal 14px/16px DomaineSansText-Light;
          color: #92959e;
        }
        .item-img {
          height: 150px;
        }
        .color-box {
          width: 20px;
          height: 20px;
          border: 1px solid #ccc;
        }
        .price {
          margin: 0;
        }
        .btn-decline {
          background: #be002b;
          color: #fff;
          width: 100px;
          height: 35px;
        }
        .btn-more {
          background: #000000;
          color: #fff;
          width: 195px;
          height: 63px;
          left: 40%;
          right: 50%;
          margin-top: 45px;
        }
      `}</style>
    </div>
  );
};

export default ApprovedOrders;
